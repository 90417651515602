import { Component, createRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
import { Password } from 'primereact/password';
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import Constante from '../../Util/constante';
import Util from "../../Util/util";
import LogoPreta from '../../assets/images/logoPreta.png';
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";


export default class LoginPg extends Component {

    mensagens = createRef();
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    state = {
        email: '',
        senha: '',
        inputError: {
            email: false,
            senha: false,
        },
        carregando: false,
    }

    async validarAcesso() {
        this.setState({ carregando: true });
        this.mensagens.current.clear();

        let listaPesquisa = [];
        let pessoa = new Pessoa();
        let inputError = this.state.inputError;
        let inputsOK = true;


        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (!this.state.email) {
            inputError.email = true;
            this.setState({ carregando: false })

        }

        if (!this.state.senha) {
            inputError.senha = true;
            this.setState({ carregando: false })

        }

        if (!this.util.validarEmail(this.state.email)) {
            inputError.email = true;
            this.setState({ carregando: false })
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                inputsOK = false;
            }
        });

        this.setState({ inputError: inputError });

        if (!inputsOK) {
            return;
        }

        let pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.seq_usuario';
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = '';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = 'pessoa.email_plataforma_lirida';
        pesquisa.conteudo = this.state.email;
        pesquisa.operacao = 'AND';
        pesquisa.tipo = 'TEXTO';
        listaPesquisa.push(pesquisa);

        // pesquisa = new Pesquisa();
        // pesquisa.campo = "pessoa.seq_pessoa_perfil";
        // pesquisa.conteudo = this.constante.seqPessoaPerfilEmpresa;
        // pesquisa.operacao = 'AND';
        // pesquisa.tipo = 'TEXTO';
        // listaPesquisa.push(pesquisa);

        // pesquisa = new Pesquisa();
        // pesquisa.campo = "AND ( pessoa.seq_pessoa_perfil ='" + this.constante.seqPessoaPerfilEmpresa + "'";
        // pesquisa.conteudo = this.constante.seqPessoaPerfilEmpresa;
        // pesquisa.operacao = 'AND';
        // pesquisa.tipo = 'TEXTO';
        // listaPesquisa.push(pesquisa);

        // pesquisa = new Pesquisa();
        // pesquisa.campo = "OR pessoa.seq_pessoa_perfil ='" + this.constante.seqPessoaPerfilAdministrador + "')";
        // pesquisa.conteudo = this.constante.seqPessoaPerfilAdministrador;
        // pesquisa.operacao = 'OR';
        // pesquisa.tipo = 'TEXTO';
        // listaPesquisa.push(pesquisa);    

        let retorno = await this.pessoaService.listarComFiltro(listaPesquisa);
        pessoa = retorno.objeto[0];


        if (!pessoa) {
            this.setState({ inputError: inputError, carregando: false, });
            this.mensagens.current?.show([
                { severity: 'error', summary: 'Credênciais de acesso inválidas!', life: 3000 },
            ]);
            return
        }

        if (this.state.email !== pessoa?.emailPlataformaLirida) {
            inputError.email = true
            this.setState({ inputError: inputError, carregando: false, })
            return;
        }

        if (this.state.senha !== pessoa?.senha) {
            inputError.senha = true
            this.setState({ inputError: inputError, carregando: false, })
            return;
        }

        this.setState({ inputError: inputError, carregando: false });

        if (pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilEmpresa) {
            this.util.salvarLocalStorage("PESSOA_USUARIO", pessoa);
            window.location.href = '/empresa/projeto';

        } else if (pessoa.seqPessoaPerfil === this.constante.seqPessoaPerfilAdministrador) {
            this.util.salvarLocalStorage("PESSOA_USUARIO", pessoa);
            window.location.href = '/administrativo/empresa';
        }
    }

    render() {
        return <div>

            {/* TELA */}

            <main style={{
                width: '100%',
                height: '100%',
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
            }}>

                <section style={{
                    maxWidth: '1280px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '10px',
                }}>
                    <div style={{ width: '40%', height: '80%' }}>
                        <img
                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                            src={LogoPreta}
                            alt="Logo Onax na cor escura." />
                    </div>

                    <form onSubmit={e => { e.preventDefault(); this.validarAcesso(e.target) }} style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: 20 }}>
                        <Messages ref={this.mensagens} />

                        <h1>Login</h1>

                        <div>
                            <label>E-mail</label><br />
                            <InputText
                                onChange={pEvento => this.setState({ email: pEvento.target.value })}
                                type='email'
                                style={{ width: '100%', borderColor: this.state.inputError.email && 'var(--red-600)' }} />
                            {this.state.inputError.email && <small style={{ color: 'var(--red-600)' }}>E-mail inválido</small>}
                        </div>

                        <div>
                            <label>Senha</label><br />
                            <Password className="p-password"
                                onChange={pEvento => this.setState({ senha: pEvento.target.value })}
                                feedback={false}
                                toggleMask
                                style={{ width: '100%', borderColor: this.state.inputError.senha && 'var(--red-600)' }} />
                            {this.state.inputError.senha && <small style={{ color: 'var(--red-600)' }}>Senha inválida</small>}
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button label="Recuperar senha" type="button" className="p-button-link" style={{ padding: 0 }}
                                onClick={() => window.location.href = '/recuperar-senha'} />
                            < Button label="Entrar" type="submit" loading={this.state.carregando} />
                        </div>

                    </form>
                </section>
            </main>
        </div>
    }
}

