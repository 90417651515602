import { Button } from "primereact/button";
import { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";

import FinanceiroService from "lirida-back-service/Servicos/Financeiro/FinanceiroService";
import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";

import Constante from "../../Util/constante";
import Util from "../../Util/util";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import { InputText } from "primereact/inputtext";
import HeaderEmpresa from "../Empresa/headerEmpresa";
import Cobranca from "lirida-back-service/Servicos/Cobranca/Cobranca"
import HeaderAdm from "./HeaderAdm";

export default class FinanceiroAdm extends Component {

    constante = new Constante()
    util = new Util()
    financeiroService = new FinanceiroService(this.constante.token, this.constante.url_base)
    pessoaservice = new PessoaService(this.constante.token, this.constante.url_base)
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base)



    state = {
        pessoaUsuario: new Pessoa(),
        listaMovimentoFinanceiro: [],
        listaContaFinanceira: [],
        listaFormaPagamento: [],
        listaContaBancaria: [],
        listaLoteFinanceiro: [],
        ListaSelecionados: [],
        quantidade: 0,
        valor: 0,
        salvarCarregando: false,

        //FILTRO
        filtro: {
            status: null,
            listaStatus: [{ nome: 'Pago', valor: 1 }, { nome: 'Não pago', valor: 0 }],
            seqContaBancaria: null,
            formaPagamento: null,
            seqContaFinanceira: '',
            dataCadastro_inicial: '',
            dataCadastro_final: '',
            dataProgramada_inicial: '',
            dataProgramada_final: '',
            dataBaixa_inicial: '',
            dataBaixa_final: '',
            dataCredito_inicial: '',
            dataCredito_final: '',
            nome: ''
        }
    }


    componentDidMount() {
        this.iniciar()
    }

    async iniciar() {

        this.pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");

        if (!this.pessoaUsuario?._seqPessoa || this.pessoaUsuario?.seqPessoaPerfil !== this.constante.seqPessoaPerfilAdministrador) {
            window.history.back();
            return
        }

        this.retornoListaContaFinanceira = await this.util.listarLista(this.constante.seqUsuario, this.constante.idListaContaFinanceira);
        this.retornoListaFormaPagamento = await this.util.listarLista(this.constante.seqUsuario, this.constante.idListaFormaPagamento);
        this.retornoListaContaBancaria = await this.util.listarLista(this.constante.seqUsuario, this.constante.idListaContaBancaria);

        this.listaContaFinanceira = this.retornoListaContaFinanceira.objeto;
        console.log("CONTA FINANCEIRA", this.listaContaFinanceira);

        this.listaFormaPagamento = this.retornoListaFormaPagamento.objeto;
        console.log("CONTA Forma PAGAMENTO", this.listaFormaPagamento);

        this.listaContaBancaria = this.retornoListaContaBancaria.objeto;
        console.log("CONTA BANCARIA", this.listaContaBancaria);

        this.listar(this.pessoaUsuario);

        this.setState({
            listaContaFinanceira: this.listaContaFinanceira,
            listaFormaPagamento: this.listaFormaPagamento,
            listaContaBancaria: this.listaContaBancaria,
            pessoaUsuario: this.pessoaUsuario,
        });

    }

    async listar(pPessoa) {

        this.pessoaUsuario = pPessoa

        if (!pPessoa) {
            this.pessoaUsuario = this.state.pessoaUsuario
        }


        this.listaPesquisa = [
            this.util.criarObjetoPesquisa(
                "movimento_financeiro.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            ),

            this.util.criarObjetoPesquisa(
                "movimento_financeiro.seq_pessoa_pagador",
                this.pessoaUsuario._seqPessoa,
                "AND",
                "TEXTO"
            ),

            this.util.criarObjetoPesquisa(
                "movimento_financeiro.seq_conta_financeira",
                this.constante.seqContaFinanceiroPagamentoFornecedor,
                "AND",
                "TEXTO"
            )
        ]


        if (this.state.filtro.nome) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    "pessoa.nome",
                    this.state.filtro.nome,
                    "AND",
                    "TEXTO"
                )
            )
        }

        if (this.state.filtro.formaPagamento?._seq) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro.seq_forma_pagamento",
                    this.state.filtro.formaPagamento?._seq,
                    "AND",
                    "TEXTO"
                )
            )
        }


        if (this.state.filtro.status?.valor) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'movimento_financeiro.qtde_baixa',
                    this.state.filtro.status?.valor,
                    'AND',
                    'NUMERO'
                )
            )
        }


        if (this.state.filtro.dataCadastro_inicial) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'movimento_financeiro.data_cadastro',
                    this.state.filtro.dataCadastro_inicial,
                    'AND',
                    'DATA_INICIAL'
                )
            )
        }

        if (this.state.filtro.dataCadastro_final) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'movimento_financeiro.data_cadastro',
                    this.state.filtro.dataCadastro_final,
                    'AND',
                    'DATA_FINAL'
                )
            )
        }



        if (this.state.filtro.dataProgramada_inicial) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'movimento_financeiro.data_programado',
                    this.state.filtro.dataProgramada_inicial,
                    'AND',
                    'DATA_INICIAL'
                )
            )
        }


        if (this.state.filtro.dataProgramada_final) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'movimento_financeiro.data_programado',
                    this.state.filtro.dataProgramada_final,
                    'AND',
                    'DATA_FINAL'
                )
            )
        }



        if (this.state.filtro.dataCredito_inicial) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro_baixa.data_credito",
                    this.state.filtro.dataCredito_inicial,
                    "AND",
                    "DATA_INICIAL"
                )
            );
        }


        if (this.state.filtro.dataCredito_final) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    "movimento_financeiro_baixa.data_credito",
                    this.state.filtro.dataCredito_final,
                    "AND",
                    "DATA_FINAL"
                )
            );
        }

        console.log("listapesquisa", this.listaPesquisa);

        this.listaMovimentoFinanceiro = await this.financeiroService.listarComFiltro(this.listaPesquisa);
        console.log("LISTA MOVIMENTO FINANCEIRO", this.listaMovimentoFinanceiro.objeto);

        this.setState({ listaMovimentoFinanceiro: this.listaMovimentoFinanceiro.objeto })
    }

    selecionar(item) {
        console.log(item);
        this.ListaSelecionados = item
        this.setState({ ListaSelecionados: this.ListaSelecionados })
    }

    // async gerarCobranca() {
    //     this.setState({ salvarCarregando: true })
    //     let cobranca = new Cobranca()
    //     let listaLoteFinanceiro = this.state.ListaSelecionados
    //     let retorno = await this.financeiroService.gerarLoteCobranca(listaLoteFinanceiro)
    //     cobranca = retorno.objeto

    //     this.setState({
    //         cobranca: cobranca,
    //         salvarCarregando: false
    //     })

    //     window.open('/empresa/cobranca?id=' + cobranca._seqCobranca)


    // }


    render() {
        return <div>
            <HeaderAdm />
            <main style={{ width: '95%', margin: '20px auto' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                        <div>
                            <h1 style={{ fontSize: 22, }}>Financeiro</h1>
                            <h2 style={{ fontSize: 20, color: 'gray' }}>Financeiro</h2>
                        </div>
                    </div>
                </div>

                <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20 }}>

                        {/* FILTROS */}
                        <div>
                            <label>Empresa </label><br />
                            <InputText
                                onChange={pTexto => this.setState({ filtro: { ...this.state.filtro, nome: pTexto.target.value } })}
                                value={this.state.filtro.nome || ''}
                                placeholder="Profissional"
                                style={{ width: '100%', height: 40 }}
                            />
                        </div>


                        <div>
                            <label>Status</label><br />
                            <Dropdown
                                onChange={e => this.setState({ filtro: { ...this.state.filtro, status: e.value } })}
                                value={this.state.filtro.status}
                                options={this.state.filtro.listaStatus}
                                optionLabel={'nome'}
                                style={{ width: '100%', height: 40 }}
                                placeholder="Todos" />
                        </div>
                        <div>
                            <label>Data cadastro inicial</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, dataCadastro_inicial: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                value={this.state.filtro.dataCadastro_inicial || ''}
                                style={{ width: '100%', height: 40 }}
                                showButtonBar
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data cadastro final</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, dataCadastro_final: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                value={this.state.filtro.dataCadastro_final || ''}
                                style={{ width: '100%', height: 40 }}
                                showButtonBar
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data programada inicial</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, dataProgramada_inicial: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                value={this.state.filtro.dataProgramada_inicial || ''}
                                style={{ width: '100%', height: 40 }}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data programada final</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, dataProgramada_final: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                value={this.state.filtro.dataProgramada_final || ''}
                                style={{ width: '100%', height: 40 }}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data credito inicial</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, dataCredito_inicial: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                value={this.state.filtro.dataCredito_inicial || ''}
                                style={{ width: '100%', height: 40 }}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Data credito final</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    filtro: {
                                        ...this.state.filtro, dataCredito_final: this.util.formatarDataBanco(pTexto.target.value)
                                    }
                                })}
                                value={this.state.filtro.dataCredito_final || ''}
                                style={{ width: '100%', height: 40 }}
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy" />
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: 40 }}>


                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', height: 40, marginTop: 15 }}>
                            <Button label="Buscar" className="p-button-raised" onClick={() => this.listar()} />
                        </div>
                    </div>
                </div>
                <DataTable
                    style={{ marginTop: 20, width: "100%" }}
                    showGridlines
                    header={
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Button type="button" icon="pi pi-file-excel" onClick={() => this.exportExcel()} className="p-button-success mr-2" data-pr-tooltip="XLS" />

                            <div style={{ display: "flex", gap: 10 }}>
                                <Button type="button" loading={this.state.salvarCarregando} label="Pagar" onClick={() => this.gerarCobranca()} />
                                <Button type="button" label="Solicitar Nota fiscal" onClick={() => this.setState({ vModalNotaFiscal: true })} />
                            </div>
                        </div>
                    }
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                    responsiveLayout='scroll'
                    removableSort
                    value={this.state.listaMovimentoFinanceiro}
                    selection={this.state.ListaSelecionados}
                    onSelectionChange={item => this.selecionar(item.value)}>

                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />

                    <Column
                        header="Ordem de serviço"
                        field="_codigo"
                        sortable />

                    <Column
                        header="Empresa"
                        field="_pesNome"
                        sortable />

                    <Column
                        field="valorProgramado"
                        header="Valor programado"
                        sortable
                        body={item => <p>{this.util.formatarValor(item.valorProgramado)}</p>} />

                    <Column
                        field="_cfOperacao"
                        header="Operação"
                        sortable />

                    <Column
                        field="_fpNome"
                        header="Forma de pagamento" sortable />

                    <Column
                        header="Nota fiscal"
                        sortable
                        align="center"
                        body={() => <>
                            <label
                                htmlFor="file-upload"
                                style={{ padding: 10, backgroundColor: 'var(--primary-color)', borderRadius: 5, color: 'white', cursor: 'pointer' }}>
                                <i className="pi pi-upload"></i>
                            </label>
                            <input type="file" id="file-upload" style={{ display: 'none' }} />
                        </>} />

                    <Column
                        field="qtdeBaixa"
                        header="Status"
                        sortable body={item => <p>{
                            item.qtdeBaixa === 0 ? 'Não pago' : 'Pago'
                        }</p>} />

                    {/* <Column
                        field="_dataBaixa)"
                        header="Data baixa"
                        sortable body={item => <p>{this.util.formatarData(item._dataBaixa)}</p>
                        } /> */}

                    <Column
                        header="Data prevista de pagamento"
                        field="dataProgramado"
                        sortable body={item => <p>{this.util.formatarData(item.dataProgramado)}</p>
                        } />

                    {/* <Column
                        field="_dataCredito"
                        header="Data crédito"
                        sortable body={item => <p>{this.util.formatarData(item._dataCredito)}</p>
                        } /> */}

                    <Column
                        field="dataCadastro"
                        header="Data cadastro"
                        sortable body={item => <p>{this.util.formatarData(item.dataCadastro)}</p>
                        } />
                </DataTable>
            </main>
        </div>
    }
}