import Pesquisa from 'lirida-back-service/Servicos/Pesquisa/Pesquisa';
import Util_api from 'lirida-back-service/Servicos/Util/Util';
import Constante from './constante';

import { locale, addLocale } from 'primereact/api';

export default class Util {
    constante = new Constante();
    util_api = new Util_api(this.constante.token, this.constante.url_base);

    enviarEmail(pApiEmail) {
        this.util_api.enviarEmail(pApiEmail);
    }

    async buscarUrlBaseArquivo() {
        let retorno = await this.util_api.buscarUrlBaseArquivo();
        retorno = await retorno.json();
        return retorno;
    }

    async buscarUrlBaseFinanceiro() {
        let retorno = await this.util_api.buscarUrlBaseFinanceiro();
        retorno = await retorno.json();
        return retorno;
    }

    async buscarLocalStorage(pCampo) {
        let retorno = localStorage.getItem(pCampo);
        retorno = await JSON.parse(retorno);
        return retorno;
    }

    salvarLocalStorage(pCampo, pValor) {
        let valor = JSON.stringify(pValor);
        localStorage.setItem(pCampo, valor);
        console.log(pCampo + ' salvo localstorage com sucesso')
    }

    removerLocalStorage(pCampo) {
        let retorno = localStorage.removeItem(pCampo);
        return retorno;
    }

    formatarTelefone(value) {
        let r = value.replace(/\D/g, '');
        r = r.replace(/^0/, '');

        if (r.length > 11) {
            r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
        } else if (r.length > 7) {
            r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, '($1) $2-$3');
        } else if (r.length > 2) {
            r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
        } else if (value.trim() !== '') {
            r = r.replace(/^(\d*)/, '($1');
        }

        return r;
    }

    formatarData(pData) {
        if (!pData) {
            return ''
        }

        this.data = String(pData);
        this.data = this.data.split('T')[0];
        this.data = this.data.replace('-', '/').replace('-', '/');
        return this.data;
    }

    formatarDataComTimestamp(pData) {
        if (!pData) return;

        let data = pData;
        data = data.split("T")[0].split("-").join("/");
        return data;
    }

    formatarDataInput(pData) {
        if (String(pData).includes('/')) {
            this.data = String(pData).split('/').reverse().join('-');
        } else if (String(pData).includes('-')) {
            this.data = String(pData).split('-').reverse().join('/');
        } else {
            this.data = pData;
        }

        return this.data;
    }

    formatarCPFCNPJ(pTexto) {
        let t = pTexto;

        if (t.length > 15) {
            return t.replace(/\D/g, '')
                .replace(/(\d{2})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})/, '$1/$2')
                .replace(/(\d{4})(\d{1,2})/, '$1-$2')
                .replace(/(-\d{2})\d+?$/, '$1')
        }

        if (t.length <= 15) {
            return t.replace(/\D/g, '')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        }
    }

    formatarCPF(pTexto) {
        return pTexto.replace(/\D/g, '')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    }


    formatarCNPJ(pTexto) {
        return pTexto.replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1/$2')
            .replace(/(\d{4})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1')
    }



    formatarValor(value) {
        if (value) {
            this.valorFormatado = Number(value).toLocaleString(
                'pt-BR',
                { style: 'currency', currency: 'BRL' }
            );
        } else {
            this.valorFormatado = Number(0).toLocaleString(
                'pt-BR',
                { style: 'currency', currency: 'BRL' }
            );
        }
        return this.valorFormatado;
    };

    formatarCEP(pTexto) {
        this.texto = pTexto;
        return this.texto.replace(/\D/g, '')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(-\d{3})\d+?$/, '$1')
    }

    async validarDocumento(pDocumento) {
        this.retorno = await this.util_api.validarDocumento(pDocumento);
        this.retorno = await this.retorno.json();
        return this.retorno;
    }

    retornaPesquisa(pCampo, pConteudo, pOperacao, pTipo) {
        let pesquisa = new Pesquisa();

        pesquisa.campo = pCampo;
        pesquisa.conteudo = pConteudo;
        pesquisa.operacao = pOperacao;
        pesquisa.tipo = pTipo;

        return pesquisa;
    }

    async listarLista(pSeqUsuario, pID) {
        this.retorno = await this.util_api.listarLista(pSeqUsuario, pID);
        this.retorno = this.retorno.json();
        return this.retorno;
    }

    async listarBanco(pConteudo) {
        this.retorno = await this.util_api.listarBanco(pConteudo);
        this.retorno = this.retorno.json();
        return this.retorno;
    }

    buscarParametros() {
        this.listaParametrosSplitados = window.location.search.replace('?', '').split('&');
        this.parametros = {};

        for (let i = 0; i < this.listaParametrosSplitados.length; i++) {
            this.listaChaveValor = this.listaParametrosSplitados[i].split('=');
            this.parametros[this.listaChaveValor[0]] = this.listaChaveValor[1];
        }

        return this.parametros;
    }

    async buscarUsuarioPorSeq(pSeqUsuario) {
        this.usuario = await this.util_api.buscarUsuarioPorSeq(pSeqUsuario);
        return this.usuario;
    }

    async pegarBase64(pArquivo) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.readAsDataURL(pArquivo);
            reader.onload = () => {
                let base64 = reader.result;
                base64 = base64.split(",")[1];
                resolve(base64);
            };
            reader.onerror = error => reject(error);
        })
    }

    validarEmail(pEmail) {
        let emailRegex = /\S+@\S+\.\S+/;

        return emailRegex.test(pEmail);
    }

    validarTelefone(pTelefone) {
        let telefoneRegex = /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/;

        return telefoneRegex.test(pTelefone);
    }

    async enviarEmail(pSeqEmail, pEmail, pNome, pSenha) {
        await this.util_api.enviarEmail(
            {
                seqEmail: pSeqEmail,
                email: pEmail,
                parametro: {
                    nome: pNome,
                    email: pEmail,
                    senha: pSenha
                }
            }
        )
    }

    criarObjetoPesquisa(pCampo, pConteudo, pOperacao, pTipo) {
        this.pesquisa = new Pesquisa()

        this.pesquisa.campo = pCampo;
        this.pesquisa.conteudo = pConteudo;
        this.pesquisa.operacao = pOperacao;
        this.pesquisa.tipo = pTipo;

        return this.pesquisa
    }

    formatarDataBanco(pData) {
        let data = pData.toLocaleDateString();
        data = `${data.split('/').join('-')}T00:00:00`;
        console.log("DATA PARA O BANCO")
        console.log(data)
        return data
    }


    formatarDataCalendar(pData) {
        let data = String(pData)
        data = `${data.split('T')[0].split('-').reverse().join('-')}T03:00:00.000Z`;
        return new Date(data)
    }

    somarData(pData) {
        let data = pData;
        console.log(data)
        data = data.split('T');
        data = data[0].split('-').reverse();
        let novaData = new Date(data)
        console.log(novaData)
        novaData.setDate(novaData.getDate() + 10)
        novaData = novaData.toLocaleDateString()
        novaData = novaData.split('/').join('-');
        novaData = novaData + 'T00:00:00'
        return novaData
    }

    somarDataSecundario(pData, pQntdDias) {
        let data = pData;
        console.log(data)
        data = data.split('T');
        data = data[0].split('-').reverse();
        let novaData = new Date(data)
        console.log(novaData)
        novaData.setDate(novaData.getDate() + pQntdDias)
        novaData = novaData.toLocaleDateString()
        novaData = novaData.split('/').join('-');
        novaData = novaData + 'T00:00:00'
        return novaData
    }

    traduzirCalendar() {
        addLocale('br', {
            firstDayOfWeek: 1,
            dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
            dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
            dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
            monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
            monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
            today: 'Hoje',
            clear: 'Limpar'
        });
        locale('br');
    }

    buscarParametrosUrl() {
        if (!window.location.search) {
            return null;
        }

        let query = window.location.search.slice(1);
        let partes = query.split("&");
        let data = {};
        partes.forEach(function (parte) {
            let chaveValor = parte.split("=");
            let chave = chaveValor[0];
            let valor = chaveValor[1];
            data[chave] = valor;
        });

        return data;
    }
}