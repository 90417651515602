import { Component } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import HeaderAdm from "./HeaderAdm";

//SERVICES E OBJETOS
import Documento from "lirida-back-service/Servicos/Documento/Documento";
import DocumentoItem from 'lirida-back-service/Servicos/Documento/DocumentoItem';
import DocumentoService from 'lirida-back-service/Servicos/Documento/DocumentoService';
import DocumentoRelacionamento from "lirida-back-service/Servicos/DocumentoRelacionamento/DocumentoRelacionamento"
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import CepService from "lirida-back-service/Servicos/Cep/CepService";
import DepartamentoService from "lirida-back-service/Servicos/Departamento/DepartamentoService";
import Constante from "../../Util/constante";
import Util from '../../Util/util';


class DocumentoProcesso {
    _tipoDocumentoProcessoNome
}


export default class ProjetoAdm extends Component {

    util = new Util()
    constante = new Constante()
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base)
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base)
    departamentoService = new DepartamentoService(this.constante.token, this.constante.url_base)

    state = {

        pessoaUsuario: new Pessoa(),
        documento: new Documento(),
        documentoItem: new DocumentoItem(),
        documentoRelacionamentoEmpresa: new DocumentoRelacionamento(),

        //LISTAS
        listaItem: [],
        listaItensAdicionados: [],
        listaDocumento: [],
        listaVwContadorDocumento: [],
        listaDepartamento: [],

        //FILTROS
        filtroPesquisa: {
            nome: '',
            dataCadastro_inicial: '',
            dataCadastro_final: '',
            dataProgramada_inicial: '',
            dataProgramada_final: '',
            documentoProcesso: null,
        },

    }


    componentDidMount() {
        this.iniciar()
    }

    async iniciar() {

        this.util.traduzirCalendar();
        this.pessoaUsuario = this.state.pessoaUsuario
        this.pessoaUsuario = await this.util.buscarLocalStorage('PESSOA_USUARIO');

        if (!this?.pessoaUsuario?._seqPessoa) {
            window.history.back();
            return
        } else if (this.pessoaUsuario?.seqPessoaPerfil !== this.constante.seqPessoaPerfilAdministrador) {
            window.history.back();
            return
        }

        //listando Departamento
        this.retornoDepartamento = await this.departamentoService.listar(this.constante.seqUsuario)
        this.departamento = this.retornoDepartamento.objeto

        // listando ContadorDocumentoItem
        this.retornoVwContadorDocumento = await this.documentoService.listarContadorDocumento(this.constante.seqUsuario, this.constante.seqTipoDocumentoProjeto);
        this.listaVwContadorDocumento = this.retornoVwContadorDocumento.objeto

        this.setState({
            pessoaUsuario: this.pessoaUsuario,
            listaVwContadorDocumento: this.listaVwContadorDocumento,
            listaDepartamento: this.departamento,
        })
    }


    async listar(pPessoa) {
        this.pessoaUsuario = pPessoa

        if (!pPessoa) {

            this.pessoaUsuario = this.state.pessoaUsuario
        }

        this.listaPesquisa = [

            this.util.criarObjetoPesquisa(
                "usuario.seq_usuario",
                this.constante.seqUsuario,
                "",
                "TEXTO"
            ),
            this.util.criarObjetoPesquisa(
                "documento.seq_tipo_documento",
                this.constante.seqTipoDocumentoProjeto,
                "AND",
                "TEXTO"
            ),


            this.util.criarObjetoPesquisa(
                "documento.seq_tipo_documento_processo",
                this.state.filtroPesquisa?.documentoProcesso?._seqTipoDocumentoProcesso,
                "AND",
                "TEXTO"
            )
        ]

        if (this.state.filtroPesquisa?.nome) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'documento.tags0',
                    this.state.filtroPesquisa.nome,
                    'AND',
                    'TEXTO'
                )
            )
        }

        if (this.state.filtroPesquisa?.dataCadastro_inicial) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'documento.data_cadastro',
                    this.util.formatarDataBanco(this.state.filtroPesquisa.dataCadastro_inicial),
                    'AND',
                    'DATA_INICIAL'
                )
            )
        }


        if (this.state.filtroPesquisa?.dataCadastro_final) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'documento.data_cadastro',
                    this.util.formatarDataBanco(this.state.filtroPesquisa.dataCadastro_final),
                    'AND',
                    'DATA_FINAL'
                )
            )
        }

        if (this.state.filtroPesquisa?.dataProgramada_inicial) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'documento.data_programada',
                    this.util.formatarDataBanco(this.state.filtroPesquisa.dataProgramada_inicial),
                    'AND',
                    'DATA_INICIAL'
                )
            )
        }


        if (this.state.filtroPesquisa?.dataProgramada_final) {
            this.listaPesquisa.push(
                this.util.criarObjetoPesquisa(
                    'documento.data_programada',
                    this.util.formatarDataBanco(this.state.filtroPesquisa.dataProgramada_final),
                    'AND',
                    'DATA_FINAL'
                )
            )
        }

        this.retornoListaPequisa = await this.documentoService.listarComFiltro(this.listaPesquisa)
        this.listaDocumento = this.retornoListaPequisa.objeto

        this.setState({
            listaDocumento: this.listaDocumento,
            salvarCarregando: false
        })

    }

    render() {
        return <>

            <HeaderAdm />
            <main style={{ width: '95%', margin: '20px auto' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                        <div>
                            <h1 style={{ fontSize: 22 }}>Projetos</h1>
                            <h2 style={{ fontSize: 20, color: 'gray' }}>Projetos</h2>
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, }}>
                        {/* <Button label="Novo Projeto" className="p-button-raised" onClick={() => this.setState({ vModalNovo: true })} /> */}
                    </div>
                </div>


                {/* FILTROS */}
                <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr ', gap: 20 }}>
                        <div>
                            <label>Projeto</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    filtroPesquisa: {
                                        ...this.state.filtroPesquisa, nome: pTexto.target.value
                                    }
                                })}
                                placeholder="Nome"
                                style={{ width: '100%', height: 40 }} />
                        </div>

                        <div>
                            <label>Data Cadastro Inicial</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    filtroPesquisa: {
                                        ...this.state.filtroPesquisa, dataCadastro_inicial: pTexto.target.value
                                    }
                                })}
                                showButtonBar
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy"
                                style={{ width: '100%', height: 40 }} />
                        </div>

                        <div>
                            <label>Data Cadastro Final</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    filtroPesquisa: {
                                        ...this.state.filtroPesquisa, dataCadastro_final: pTexto.target.value
                                    }
                                })}
                                showButtonBar
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy"
                                style={{ width: '100%', height: 40 }} />
                        </div>

                        <div>
                            <label>Data Programada Inicial</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    filtroPesquisa: {
                                        ...this.state.filtroPesquisa, dataProgramada_inicial: pTexto.target.value
                                    }
                                })}
                                showButtonBar
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy"
                                style={{ width: '100%', height: 40 }} />
                        </div>

                        <div>
                            <label>Data Programada Final</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    filtroPesquisa: {
                                        ...this.state.filtroPesquisa, dataProgramada_final: pTexto.target.value
                                    }
                                })}
                                showButtonBar
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy"
                                style={{ width: '100%', height: 40 }} />
                        </div>

                        <div>
                            <label>Processos</label><br />
                            <Dropdown
                                onChange={item => this.setState({
                                    filtroPesquisa: {
                                        ...this.state.filtroPesquisa, documentoProcesso: item.value
                                    }
                                })}
                                placeholder='Todos'
                                options={[{ ...new DocumentoProcesso(), _tipoDocumentoProcessoNome: 'Todos' }, ...this.state.listaVwContadorDocumento]}
                                optionLabel="_tipoDocumentoProcessoNome"
                                value={this.state.filtroPesquisa.documentoProcesso}
                                style={{ width: '100%', height: 40 }} />
                        </div>

                        <div>
                            <label>Financeiro</label><br />
                            <Dropdown
                                // onChange={item => this.setState({
                                //     filtroPesquisa: {
                                //         ...this.state.filtroPesquisa, status: item.value
                                //     }
                                // })}
                                placeholder='Todos'
                                options={[{ label: "Todos", value: "" }, { label: "Pago", value: "S" }, { label: "Não pago", value: "N" }]}
                                value={this.state.filtroPesquisa.status}
                                style={{ width: '100%', height: 40 }}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'right', marginTop: 20 }}>
                            <Button loading={this.state.salvarCarregando} label="Buscar" className="p-button-raised" onClick={() => this.listar()} />
                        </div>
                    </div>
                </div>

                {/* TABELA */}
                <div style={{ display: 'flex', gridTemplateColumns: '1fr', marginTop: 20, gap: 10, }}>
                    <DataTable
                        selectionMode="single"
                        showGridlines removableSort
                        paginator
                        responsiveLayout="scroll"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50]}
                        style={{ width: "100%" }}
                        value={this.listaDocumento}
                        onSelectionChange={pEvento => window.open("/administrativo/detalhe_projeto_adm?id=" + pEvento.value._seqDocumento)}>

                        <Column
                            header="Código"
                            field="_codigo"
                            sortable />

                        <Column
                            header="Projeto"
                            field="tags0"
                            sortable />

                        <Column
                            header="Email"
                            field="_pessoaEmail"
                            sortable />

                        <Column
                            header="Valor"
                            field="valorTotal"
                            sortable
                            body={item => <p>{this.util.formatarValor(item.valorTotal)}</p>} />

                        <Column
                            header="Data de cadastro"
                            field="dataCadastro"
                            sortable
                            body={item => <p>{this.util.formatarData(item.dataCadastro)}</p>} />

                        <Column
                            header="Data de inicio do projeto"
                            field="dataInicio"
                            sortable
                            body={item => <p>{this.util.formatarData(item.dataInicio)}</p>} />

                        <Column
                            header="Data de final do projeto"
                            field="dataFinal"
                            sortable
                            body={item => <p>{this.util.formatarData(item.dataFinal)}</p>} />

                        <Column
                            header="Data prevista para pagamento"
                            field="dataProgramada"
                            sortable
                            body={item => <p>{this.util.formatarData(item.dataProgramada)}</p>} />

                        <Column
                            header="Processo"
                            field="_tipoDocumentoProcessoNome"
                            sortable />

                    </DataTable>
                </div>
            </main >
        </>
    }
}