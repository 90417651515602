import { Component } from "react";
import { Navigate } from "react-router-dom";
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';

import LogoPreta from '../../assets/images/logoPreta.png'


import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Constante from "../../Util/constante";
import Util from "../../Util/util";

export default class HeaderAdm extends Component {

    constante = new Constante()
    util = new Util()

    state = {
        liberarAcesso: {
            login: false,
            perfilAdm: false,
            financeiro_adm: false,
            projeto_adm: false,
            clienteAdm: false,
            profissionalAdm: false,
            empresa: false
        },
        dropdownFinanceiroVisivel: false,
        dropdownOrdemDeServicoVisivel: false,

        urlBase: '',
        pessoaUsuario: new Pessoa()
    }


    componentDidMount() {
        this.iniciar()
    }

    async iniciar() {
        const urlBase = await this.util.buscarUrlBaseArquivo();
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        this.setState({
            urlBase: urlBase,
            pessoaUsuario: pessoaUsuario,
        })
    }

    navegar(pTela) {
        const url_path = window.location.pathname.split("/")[2];
        console.log(pTela, url_path)
        if (pTela === url_path) return;
        const liberarAcesso = this.state.liberarAcesso;
        liberarAcesso[pTela] = true;
        this.setState({ liberarAcesso: liberarAcesso });
    }

    sair() {
        this.util.removerLocalStorage('PESSOA_USUARIO');
        this.navegar('login');
    }

    render() {
        return <>
            {/* NAVEGAÇÃO */}
            {this.state.liberarAcesso.login && <Navigate to="/login" />}
            {this.state.liberarAcesso.empresa && <Navigate to="/administrativo/empresa" />}
            {this.state.liberarAcesso.projeto_adm && <Navigate to="/administrativo/projeto_adm" />}
            {this.state.liberarAcesso.financeiro_adm && <Navigate to="/administrativo/financeiro_adm" />}
            {this.state.liberarAcesso.perfilAdm && <Navigate to="/administrativo/perfilAdm" />}


            {/* COMPONENTE */}
            <header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'var(--primary-color)', padding: 10 }}>
                <img src={LogoPreta} alt="logo" style={{ height: 50 }} />

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <nav style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 5 }}>

                        <Button
                            label="Empresas"
                            onClick={() => this.navegar("empresa")} />

                        <Button
                            label="Projetos"
                            onClick={() => this.navegar("projeto_adm")} />

                        <Button
                            label="Financeiro"
                            onClick={() => this.navegar("financeiro_adm")} />

                        {/* <div style={{ position: 'relative' }}>
                            <Button
                                label="Financeiro"
                                onClick={() => this.setState({ dropdownFinanceiroVisivel: !this.state.dropdownFinanceiroVisivel })}
                                className="p-button-link"
                                style={{ color: 'white' }} />

                            {this.state.dropdownFinanceiroVisivel &&
                                <div style={{ width: 200, position: 'absolute', padding: 5, backgroundColor: "white", boxShadow: "0 0 10px rgba(0,0,0,0.5)", zIndex: 999 }}>
                                    <Button
                                        label="Contas à receber"
                                        style={{ textAlign: 'left' }}
                                        onClick={() => window.location.href = "/administrativo/financeiro-contas-receber"}
                                        className="p-button-link" />
                                    <Button
                                        label="Contas à pagar"
                                        style={{ textAlign: 'left' }}
                                        onClick={() => window.location.href = "/administrativo/financeiro-contas-pagar"}
                                        className="p-button-link" />
                                </div>
                            }

                        </div> */}


                        <Button
                            style={{ padding: 0 }}
                            label={
                                <Avatar label={String(this.state.pessoaUsuario.nome).charAt(0)} image={(this.state.urlBase && this.state.pessoaUsuario.foto) ? this.state.urlBase + this.state.pessoaUsuario.foto : null} shape="circle" style={{ objectFit: 'contain' }}></Avatar>
                            } onClick={() => this.navegar("perfilAdm")} />

                        <Button
                            label="Sair"
                            icon={<i className="pi pi-sign-out"></i>}
                            iconPos="right" style={{ gap: 5 }}
                            onClick={() => this.sair()} />
                    </nav>
                </div>
            </header>
        </>
    }
}