import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

//PRIME REACT
import "primereact/resources/themes/saga-green/theme.css";  //theme
import "primereact/resources/primereact.min.css";           //core css
import 'primeicons/primeicons.css';                         //icons

//COMPONENTES
import HeaderHotsite from './pages/Components/HeaderHotsite';
import FooterHotsite from './pages/Components/FooterHotsite';

//HOTSITE
import Hotsite from './pages/hotsite';

// AUTENTICAÇÃO
import LoginPg from './pages/Autenticação/login';
import CadastroPg from './pages/Autenticação/cadastro';
import RecuperarSenhaPg from './pages/Autenticação/recuperarSenha';

//EMPRESA
import ProjetoEmpresaPg from './pages/Empresa/ProjetoEmpresa';
import DetalheProjeto from './pages/Empresa/DetelheProjeto';
import PerfilPg from './pages/Empresa/perfil';
import MensalidadePg from './pages/Empresa/mensalidade';
import CobrancaPg from './pages/Empresa/Cobranca';
import FinanceiroPg from './pages/Empresa/financeiro';

// ADMINISTRATIVO
import PerfilAdmPg from './pages/Administrativo/perfilAdm';
import Empresa from './pages/Administrativo/empresas';
import EmpresaDetalhe from './pages/Administrativo/EmpresaDetalhe';
import ProjetoAdm from './pages/Administrativo/ProjetoAdm';
import DetalheProjetoAdm from './pages/Administrativo/DetalheProjetoAdm';
import FinanceiroAdm from './pages/Administrativo/FinanceiroAdm';
import PoliticaPrivacidade from './pages/PoliticaDePrivacidade';
import TermoUso from './pages/TermoDeUso';
import CandidatoDetalhe from './pages/Empresa/CandidatoDetalhe';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>

      <Route path="/*" element={<>
        <HeaderHotsite />
        <Routes>
          <Route path="/" element={<Hotsite />} />
          <Route path="/login" element={<LoginPg />} />
          <Route path="/cadastro" element={<CadastroPg />} />
          <Route path="/recuperar-senha" element={<RecuperarSenhaPg />} />
          <Route path="/politica_de_privacidade" element={<PoliticaPrivacidade />} />
          <Route path="//termos_de_uso" element={<TermoUso />} />
        </Routes>
        <FooterHotsite />
      </>} />

      {/* ROTAS empresa */}
      <Route path="/empresa/*" element={
        <Routes>
          <Route path="/projeto" element={<ProjetoEmpresaPg />} />
          <Route path="/detalhe_projeto" element={<DetalheProjeto />} />
          <Route path="/perfilEmpresa" element={<PerfilPg />} />
          <Route path="/mensalidade" element={<MensalidadePg />} />
          <Route path="/financeiro" element={<FinanceiroPg />} />
          <Route path="/cobranca" element={<CobrancaPg />} />
          <Route path="/candidato_detalhe" element={<CandidatoDetalhe />} />
        </Routes>
      } />

      {/* ROTAS ADMINISTRATIVO */}
      <Route path="/administrativo/*" element={
        <Routes>
          <Route path="/projeto_adm" element={<ProjetoAdm />} />
          <Route path="/detalhe_projeto_adm" element={<DetalheProjetoAdm />} />
          <Route path="/perfilAdm" element={<PerfilAdmPg />} />
          <Route path="/empresa" element={<Empresa />} />
          <Route path="/empresaDetalhe" element={<EmpresaDetalhe />} />
          <Route path="/financeiro_adm" element={<FinanceiroAdm />} />
        </Routes>
      } />

    </Routes>
  </BrowserRouter>
);

reportWebVitals();
