import { Component } from "react";
import FooterHotsite from "./Components/FooterHotsite";
import HeaderHotsite from "./Components/HeaderHotsite";

export default class PoliticaPrivacidade extends Component {
    render() {
        return <>
            <HeaderHotsite />

            <main className="h-main-container" style={{ padding: '90px 30px 30px 30px', }}>
                <section>
                    <h1>Política de privacidade</h1><br />

                    <p>
                        Todas as suas informações pessoais recolhidas, serão usadas para o ajudar a tornar a sua visita no nosso site o mais produtiva e agradável possível. A garantia da confidencialidade dos dados pessoais dos utilizadores do nosso site é importante para a Onax. Todas as informações pessoais relativas a membros, assinantes, clientes ou visitantes que usem a Onax serão tratadas em concordância com a Lei da Proteção de Dados Pessoais em vigor. A informação pessoal recolhida pode incluir o seu nome, e-mail, número de telefone e/ou telemóvel e/ou outros. O uso da Onax pressupõe a aceitação deste acordo de privacidade. A equipa da Onax reserva-se ao direito de alterar este acordo sem aviso prévio. Deste modo, recomendamos que consulte a nossa política de privacidade com regularidade de forma a estar sempre atualizado.
                    </p><br />

                    <h2>Ligações a sites de terceiros</h2><br />

                    <p>
                        A Onax possui ligações para outros sites, os quais, a nosso ver, podem conter informações / ferramentas úteis para os nossos visitantes. A nossa política de privacidade não é aplicada a sites de terceiros, pelo que, caso visite outro site a partir do nosso deverá ler a politica de privacidade do mesmo. Não nos responsabilizamos pela política de privacidade ou conteúdo presente nesses mesmos sites.
                    </p>
                </section>
            </main>

            <FooterHotsite />
        </>
    }
}