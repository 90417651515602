import { Component, createRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Messages } from 'primereact/messages';
import logo from '../../assets/images/logoPreta.png';


import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import Constante from "../../Util/constante";
import Util from "../../Util/util";

export default class RecuperarSenhaPg extends Component {

    mensagem = createRef();
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    state = {
        email: '',
        carregando: false,
        inputError: {
            email: false,
        }
    }

    async recuperarSenha() {
        this.setState({ carregando: true })
        let pessoa = new Pessoa();
        let listaPesquisa = [];
        let inputError = this.state.inputError;
        let inputsOK = true

        this.mensagem.current.clear();

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });

        if (!this.state.email) {
            console.log("sem email");
            inputError.email = true;
            this.setState({ carregando: false });
        };

        if (!this.util.validarEmail(this.state.email)) {
            console.log("validar email");

            inputError.email = true;
            this.setState({ carregando: false });
        };

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                inputsOK = false;
            };
        });

        this.setState({ inputError: inputError });

        if (!inputsOK) {
            return;
        }

        let pesquisa = new Pesquisa()
        pesquisa.campo = "pessoa.seq_usuario";
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = ""
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);


        pesquisa = new Pesquisa()
        pesquisa.campo = "pessoa.email_plataforma_lirida";
        pesquisa.conteudo = this.state.email;
        pesquisa.operacao = "AND"
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa()
        pesquisa.campo = "pessoa.seq_pessoa_perfil";
        pesquisa.conteudo = this.constante.seqPessoaPerfilEmpresa;
        pesquisa.operacao = "AND"
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa)

        let retorno = await this.pessoaService.listarComFiltro(listaPesquisa)
        pessoa = retorno.objeto[0];

        if (!pessoa) {
            this.setState({ inputError: inputError, carregando: false, });
            this.mensagem.current.show([
                { severity: 'error', summary: 'Credênciais inválidas', sticky: true },
            ]);
            return
        }

        if (this.state.email !== pessoa?.emailPlataformaLirida) {
            this.setState({ inputError: inputError, carregando: false, })

        } else {

            await this.util.enviarEmail(this.constante.seqEmailAcesso, pessoa.emailPlataformaLirida, pessoa.nome, pessoa.senha)

            this.mensagem.current.show([
                { severity: 'success', summary: 'Enviamos as credênciais de acesso para o seu email!', sticky: true },
            ]);

            setTimeout(() => { window.location.href = "/login" }, 3000);
            this.setState({ carregando: false });
        }
    }

    render() {
        return <main style={{ width: "95%", padding: '110px 0 50px 0' }}>
            <div style={{ display: 'flex' }}>

                <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={logo} style={{ width: 400, height: 400, borderRadius: 200, objectFit: "contain" }} />
                </div>

                <div style={{ width: '50%', display: 'flex', flexDirection: "column", justifyContent: 'center', }}>
                    <Messages ref={this.mensagem} />
                    <h1>Recuperar-senha</h1>

                    <form onSubmit={e => { e.preventDefault(); this.recuperarSenha(e.target) }}
                        style={{ display: 'flex', flexDirection: 'column', gap: 10, marginTop: 20 }}>

                        <div>
                            <label>Email</label><br />
                            <InputText
                                onChange={pEvento => this.setState({ email: pEvento.target.value })}
                                type="text"
                                style={{ width: '100%', borderColor: this.state.inputError.email && 'red' }} />
                            <small style={{ color: this.state.inputError.email ? 'red' : 'transparent' }}>Email inválido</small>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 20 }}>
                            <Button
                                style={{ padding: 0 }}
                                type="button"
                                label="Voltar à tela de Login"
                                className="p-button-link"
                                onClick={() => window.location.href = "/login"} />
                            <Button
                                style={{ marginTop: 20 }}
                                loading={this.state.carregando}
                                type="submit"
                                label="Enviar" />
                        </div>
                    </form>
                </div>
            </div>
        </main>
    }
}