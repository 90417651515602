import { Component, createRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Steps } from 'primereact/steps';
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast"
import HeaderEmpresa from "./headerEmpresa";

// SERVICES / OBJETOS
import Documento from "lirida-back-service/Servicos/Documento/Documento";
import DocumentoItem from 'lirida-back-service/Servicos/Documento/DocumentoItem';
import DocumentoService from 'lirida-back-service/Servicos/Documento/DocumentoService';
import DocumentoRelacionamento from "lirida-back-service/Servicos/DocumentoRelacionamento/DocumentoRelacionamento"
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import CepService from "lirida-back-service/Servicos/Cep/CepService";
import DepartamentoService from "lirida-back-service/Servicos/Departamento/DepartamentoService";
import Constante from "../../Util/constante";
import Util from '../../Util/util';
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";


class DocumentoProcesso {
    _tipoDocumentoProcessoNome
}


export default class ProjetoEmpresaPg extends Component {
    toast = createRef()
    util = new Util()
    constante = new Constante()
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base)
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base)
    cepService = new CepService(this.constante.token, this.constante.url_base);
    departamentoService = new DepartamentoService(this.constante.token, this.constante.url_base)

    state = {

        pessoaUsuario: new Pessoa(),
        documento: new Documento(),
        documentoItem: new DocumentoItem(),
        documentoRelacionamentoEmpresa: new DocumentoRelacionamento(),
        vModalNovo: false,
        indexStep: 0,

        //LISTAS
        listaDocumento: [],
        listaVwContadorDocumento: [],
        listaDepartamento: [],

        liberarAcesso: {
            detalheDocumento: false,
        },

        //FILTROS
        filtroPesquisa: {
            nome: '',
            dataCadastro_inicial: '',
            dataCadastro_final: '',
            dataProgramada_inicial: '',
            dataProgramada_final: '',
            documentoProcesso: null,
        },

        //INPUTS ERROR
        inputError: {
            nomeProjeto: false,
            dataProgramada: false,
            dataInicio: false,
            dataFinal: false,
            observacao: false,
            cepEmpresa: false,
            logradouroEmpresa: false,
            numeroEndEmpresa: false,
            bairroEmpresa: false,
            cidadaEmpresa: false,
            estadoEmpresa: false,
            ufEmpresa: false,
        },
    }

    componentDidMount() {
        this.iniciar()
    }

    async iniciar() {

        this.util.traduzirCalendar();
        this.pessoaUsuario = this.state.pessoaUsuario
        this.pessoaUsuario = await this.util.buscarLocalStorage('PESSOA_USUARIO');

        if (!this?.pessoaUsuario?._seqPessoa) {
            window.history.back();
            return

        } else if (this.pessoaUsuario?.seqPessoaPerfil !== this.constante.seqPessoaPerfilEmpresa) {
            window.history.back();
            return
        }

        //listando Departamento
        this.retornoDepartamento = await this.departamentoService.listar(this.constante.seqUsuario)
        this.departamento = this.retornoDepartamento.objeto

        // listando ContadorDocumentoItem
        this.retornoVwContadorDocumento = await this.documentoService.listarContadorDocumento(this.constante.seqUsuario, this.constante.seqTipoDocumentoProjeto);
        this.listaVwContadorDocumento = this.retornoVwContadorDocumento.objeto

        this.setState({
            pessoaUsuario: this.pessoaUsuario,
            listaVwContadorDocumento: this.listaVwContadorDocumento,
            listaDepartamento: this.departamento,
        })
    }

    async listar(pPessoa) {
        this.pessoaUsuario = pPessoa
        let listaPesquisa = []

        if (!pPessoa) {

            this.pessoaUsuario = this.state.pessoaUsuario
        }

        let pesquisa = new Pesquisa()
        pesquisa.campo = "usuario.seq_usuario";
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = "";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa()
        pesquisa.campo = "documento.seq_tipo_documento";
        pesquisa.conteudo = this.constante.seqTipoDocumentoProjeto;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa()
        pesquisa.campo = "documento.seq_usuario_vendedor";
        pesquisa.conteudo = this.pessoaUsuario._seqPessoa;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa()
        pesquisa.campo = "documento.seq_tipo_documento_processo";
        pesquisa.conteudo = this.state.filtroPesquisa?.documentoProcesso?._seqTipoDocumentoProcesso;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);


        if (this.state.filtroPesquisa?.nome) {
            let pesquisa = new Pesquisa()
            pesquisa.campo = "documento.tags0";
            pesquisa.conteudo = this.state.filtroPesquisa.nome;
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'TEXTO';
            listaPesquisa.push(pesquisa);

        }

        if (this.state.filtroPesquisa?.dataCadastro_inicial) {
            let pesquisa = new Pesquisa()
            pesquisa.campo = 'documento.data_cadastro';
            pesquisa.conteudo = this.util.formatarDataBanco(this.state.filtroPesquisa.dataCadastro_inicial);
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_INICIAL';
            listaPesquisa.push(pesquisa);
        }


        if (this.state.filtroPesquisa?.dataCadastro_final) {
            let pesquisa = new Pesquisa()
            pesquisa.campo = 'documento.data_cadastro';
            pesquisa.conteudo = this.util.formatarDataBanco(this.state.filtroPesquisa.dataCadastro_final);
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_FINAL';
            listaPesquisa.push(pesquisa);
        }

        if (this.state.filtroPesquisa?.dataProgramada_inicial) {
            let pesquisa = new Pesquisa()
            pesquisa.campo = 'documento.data_programada';
            pesquisa.conteudo = this.util.formatarDataBanco(this.state.filtroPesquisa.dataCadastro_inicial);
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_INICIAL';
            listaPesquisa.push(pesquisa);
        }


        if (this.state.filtroPesquisa?.dataProgramada_final) {
            let pesquisa = new Pesquisa()
            pesquisa.campo = 'documento.data_programada';
            pesquisa.conteudo = this.util.formatarDataBanco(this.state.filtroPesquisa.dataCadastro_final);
            pesquisa.operacao = 'AND';
            pesquisa.tipo = 'DATA_FINAL';
            listaPesquisa.push(pesquisa);
        }

        this.retornoListaPequisa = await this.documentoService.listarComFiltro(listaPesquisa)
        this.listaDocumento = this.retornoListaPequisa.objeto

        this.setState({
            listaDocumento: this.listaDocumento,
            salvarCarregando: false
        })

    }

    async salvar() {
        this.pessoaUsuario = this.state.pessoaUsuario
        this.retorno = this.validarInputsSteps();
        if (this.retorno) {

            //SALVAR DOCUMENTO
            this.documento = this.state.documento
            this.documento.seqUsuario = this.constante.seqUsuario;
            this.documento.seqPessoa = this.pessoaUsuario._seqPessoa;
            this.documento.seqUsuarioVendedor = this.pessoaUsuario._seqPessoa;
            this.documento.seqTipoDocumento = this.constante.seqTipoDocumentoProjeto;
            this.documento.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoNaoFinalizadas;
            this.documento.dataProgramada = await this.util.formatarDataBanco(this.documento.dataProgramada);
            this.documento.dataInicio = await this.util.formatarDataBanco(this.documento.dataInicio);
            this.documento.dataFinal = await this.util.formatarDataBanco(this.documento.dataFinal);
            this.documento.status = 0;

            this.retornoDocumento = await this.documentoService.salvar(this.documento)
            this.documento = this.retornoDocumento.objeto


            //SALVAR RELACIONAMENTO
            this.documentoRelacionamentoEmpresa = this.state.documentoRelacionamentoEmpresa;
            this.documentoRelacionamentoEmpresa.seqUsuario = this.constante.seqUsuario;
            this.documentoRelacionamentoEmpresa.situacao = 'ATIVO';
            this.documentoRelacionamentoEmpresa.seqDocumento = this.documento._seqDocumento;
            this.documentoRelacionamentoEmpresa.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoEmpresaResponsavel;
            this.documentoRelacionamentoEmpresa.seqPessoaRelacionada = this.documento.seqUsuarioVendedor;

            this.retornoRelacionamentoEmpresa = await this.documentoService.salvarRelacionamento(this.documentoRelacionamentoEmpresa);
            this.documentoRelacionamentoEmpresa = this.retornoRelacionamentoEmpresa;

            this.setState({
                documento: this.documento,
                documentoRelacionamentoEmpresa: this.documentoRelacionamentoEmpresa,
                salvarCarregando: false,
                vModalNovo: false,
            })
            this.listar()
            this.resetarStep()
            window.open("/empresa/detalhe_projeto?id=" + this.documento._seqDocumento);
        }
    }

    async buscarCep(pCep) {
        const documento = this.state.documento;

        const retorno = await this.cepService.buscarCep(pCep);

        documento.tags2 = retorno.logradouro;
        documento.tags4 = retorno.complemento;
        documento.tags5 = retorno.bairro;
        documento.tags6 = retorno.localidade;
        documento.tags7 = retorno.estado;
        documento.tags8 = retorno.uf;

        this.setState({ documento: documento });
    }

    validarInputsSteps() {

        let inputError = this.state.inputError;
        let inputsOK = true;

        Object.keys(inputError).forEach(pKey => {
            inputError[pKey] = false;
        });


        if (!this.state.documento.tags0) {
            inputError.nomeProjeto = true;
        }
        if (!this.state.documento.dataProgramada) {
            inputError.dataProgramada = true;
        }
        if (!this.state.documento.dataInicio) {
            inputError.dataInicio = true;
        }
        if (!this.state.documento.dataFinal) {
            inputError.dataFinal = true;
        }
        if (!this.state.documento.observacao) {
            inputError.observacao = true;
        }

        if (!this.state.documento.tags1) {
            inputError.cepEmpresa = true;
        }

        if (!this.state.documento.tags2) {
            inputError.logradouroEmpresa = true;
        }

        if (!this.state.documento.tags3) {
            inputError.numeroEndEmpresa = true;
        }

        if (!this.state.documento.tags5) {
            inputError.bairroEmpresa = true;
        }

        if (!this.state.documento.tags6) {
            inputError.cidadaEmpresa = true;
        }

        if (!this.state.documento.tags7) {
            inputError.estadoEmpresa = true;
        }

        if (!this.state.documento.tags8) {
            inputError.ufEmpresa = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey]) {
                inputsOK = false

            }
        })

        this.setState({ inputError: inputError })

        if (!inputsOK) {
            this.toast.current?.show([
                { severity: 'error', summary: 'Favor verifique se todos os dados foram preenchidos!', life: 3000 },
            ]);
            return

        } else {

            return inputsOK
        }
    }

    resetarStep() {
        let inputError = this.state.inputError;
        let listaKey = Object.keys(inputError);
        listaKey.forEach(key => inputError[key] = false);
        this.setState({ documento: new Documento(), indexStep: 0, vModalNovo: false })
    }

    limparFiltros() {
        this.setState({
            filtroPesquisa: {
                nome: '',
                dataCadastro_inicial: '',
                dataCadastro_final: '',
                dataProgramada_inicial: '',
                dataProgramada_final: '',
                documentoProcesso: null,
                salvarCarregando: false,
            },

        })
    }


    render() {
        return <>
            <Toast ref={this.toast} />

            {/* NOVO PROJETO */}
            <Dialog
                header="Projeto"
                visible={this.state.vModalNovo}
                style={{ width: 1000 }}
                onHide={() => this.resetarStep()}
            >

                <Steps
                    model={[{ label: "Empresa" }, { label: "Local da Empresa" }]}
                    activeIndex={this.state.indexStep} />

                <form
                    onSubmit={e => { e.preventDefault(); this.salvar() }}
                    style={{ padding: 20, display: "flex", flexDirection: "column", gap: 10, }}>


                    {this.state.indexStep === 0 &&
                        <>
                            <div>
                                <div style={{ marginTop: 5, display: "grid", gridTemplateColumns: "1fr 1fr", gap: 20 }}>
                                    <div>
                                        <label>Nome do Projeto</label><br />
                                        <InputText
                                            onChange={pTexto => this.setState({
                                                documento: {
                                                    ...this.state.documento, tags0: pTexto.target.value
                                                }
                                            })}
                                            placeholder="Nome do Projeto"
                                            value={this.state.documento.tags0 || ''}
                                            style={{ width: '100%', height: 40, borderColor: this.state.inputError.nomeProjeto && 'var(--red-600)' }} />
                                        {this.state.inputError.nomeProjeto && <small style={{ color: 'var(--red-600)' }}>Nome inválido</small>}
                                    </div>

                                    <div>
                                        <label>Data Pagamento</label><br />
                                        <Calendar
                                            onChange={pTexto => this.setState({
                                                documento: {
                                                    ...this.state.documento, dataProgramada: pTexto.target.value
                                                }
                                            })}
                                            showButtonBar
                                            dateFormat="dd/mm/yy"
                                            placeholder="dd/mm/yyyy"
                                            value={this.state.documento.dataProgramada || ''}
                                            style={{ width: '100%', height: 40, borderColor: this.state.inputError.dataProgramada && 'var(--red-600)' }}
                                            inputStyle={{ width: '100%', height: 40, borderColor: this.state.inputError.dataProgramada && 'var(--red-600)' }} />
                                        {this.state.inputError.dataProgramada && <small style={{ color: 'var(--red-600)' }}>Data invalida</small>}

                                    </div>

                                    <div>
                                        <label>Data Inicial</label><br />
                                        <Calendar
                                            onChange={pTexto => this.setState({
                                                documento: {
                                                    ...this.state.documento, dataInicio: pTexto.target.value
                                                }
                                            })}
                                            showButtonBar
                                            dateFormat="dd/mm/yy"
                                            placeholder="dd/mm/yyyy"
                                            value={this.state.documento.dataInicio || ''}
                                            style={{ width: '100%', height: 40, borderColor: this.state.inputError.dataInicio && 'var(--red-600)' }}
                                            inputStyle={{ width: '100%', height: 40, borderColor: this.state.inputError.dataInicio && 'var(--red-600)' }} />
                                        {this.state.inputError.dataInicio && <small style={{ color: 'var(--red-600)' }}>Data invalida</small>}

                                    </div>

                                    <div>
                                        <label>Data Final</label><br />
                                        <Calendar
                                            onChange={pTexto => this.setState({
                                                documento: {
                                                    ...this.state.documento, dataFinal: pTexto.target.value
                                                }
                                            })}
                                            showButtonBar
                                            dateFormat="dd/mm/yy"
                                            placeholder="dd/mm/yyyy"
                                            value={this.state.documento.dataFinal || ''}
                                            style={{ width: '100%', height: 40, borderColor: this.state.inputError.dataFinal && 'var(--red-600)' }}
                                            inputStyle={{ width: '100%', height: 40, borderColor: this.state.inputError.dataFinal && 'var(--red-600)' }} />
                                        {this.state.inputError.dataFinal && <small style={{ color: 'var(--red-600)' }}>Data invalida</small>}

                                    </div>
                                </div>

                                <div style={{ marginTop: 15 }}>
                                    <label>Descrição</label><br />
                                    <InputTextarea
                                        onChange={pTexto => this.setState({
                                            documento: {
                                                ...this.state.documento, observacao: pTexto.target.value
                                            }
                                        })}
                                        rows={5}
                                        cols={50}
                                        autoResize
                                        placeholder='descrição do projeto'
                                        value={this.state.documento.observacao || ''}
                                        style={{ width: '100%', height: 40, borderColor: this.state.inputError.observacao && 'var(--red-600)' }} />
                                    {this.state.inputError.observacao && <small style={{ color: 'var(--red-600)' }}>Obsevação invalida</small>}

                                </div>
                            </div>
                            <div style={{ margintop: 10, display: 'flex', justifyContent: 'right', width: '100%' }}>
                                <Button
                                    label="Próximo"
                                    type='button'
                                    className="p-button-raised" onClick={() => this.setState({ indexStep: 1 })} />

                            </div>
                        </>}

                    {this.state.indexStep === 1 &&
                        <>
                            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 20 }}>

                                <div>
                                    <label>CEP</label><br />
                                    <div className="p-inputgroup">
                                        <InputText
                                            type="tel"
                                            onChange={e => this.setState({
                                                documento: {
                                                    ...this.state.documento,
                                                    tags1: this.util.formatarCEP(e.target.value),
                                                }
                                            })}
                                            value={this.state.documento?.tags1 || ''}
                                            style={{ width: '50%', height: 40, borderColor: this.state.inputError.cepEmpresa && 'var(--red-600)' }} />
                                        <Button
                                            icon="pi pi-search"
                                            type="button"
                                            onClick={() => this.buscarCep(this.state.documento.tags1)} />
                                    </div>
                                    {this.state.inputError.cepEmpresa && <small style={{ color: 'var(--red-600)' }}>Cep inválido</small>}
                                </div>

                                <div>
                                    <label>Logradouro</label><br />
                                    <InputText
                                        value={this.state.documento?.tags2 || ''}
                                        onChange={e => this.setState({
                                            documento: {
                                                ...this.state.documento,
                                                tags2: e.target.value,
                                            }
                                        })}
                                        style={{ width: '100%', height: 40, borderColor: this.state.inputError.logradouroEmpresa && 'var(--red-600)' }} />
                                    {this.state.inputError.logradouroEmpresa && <small style={{ color: 'var(--red-600)' }}>Logradouro inválido</small>}

                                </div>

                                <div>
                                    <label>Número</label><br />
                                    <InputText
                                        value={this.state.documento?.tags3 || ''}
                                        onChange={e => this.setState({
                                            documento: {
                                                ...this.state.documento,
                                                tags3: e.target.value,
                                            }
                                        })}
                                        style={{ width: '100%', height: 40, borderColor: this.state.inputError.numeroEndEmpresa && 'var(--red-600)' }} />
                                    {this.state.inputError.numeroEndEmpresa && <small style={{ color: 'var(--red-600)' }}>Numero inválido</small>}

                                </div>

                                <div>
                                    <label>Complemento</label><br />
                                    <InputText
                                        value={this.state.documento?.tags4 || ''}
                                        onChange={e => this.setState({
                                            documento: {
                                                ...this.state.documento,
                                                tags4: e.target.value,
                                            }
                                        })}
                                        style={{ width: '100%', height: 40 }} />
                                </div>


                                <div>
                                    <label>Bairro</label><br />
                                    <InputText
                                        value={this.state.documento?.tags5 || ''}
                                        onChange={e => this.setState({
                                            documento: {
                                                ...this.state.documento,
                                                tags5: e.target.value,
                                            }
                                        })}
                                        style={{ width: '100%', height: 40, borderColor: this.state.inputError.bairroEmpresa && 'var(--red-600)' }} />
                                    {this.state.inputError.bairroEmpresa && <small style={{ color: 'var(--red-600)' }}>Bairro inválido</small>}

                                </div>

                                <div>
                                    <label>Cidade</label><br />
                                    <InputText
                                        value={this.state.documento?.tags6 || ''}
                                        onChange={e => this.setState({
                                            documento: {
                                                ...this.state.documento,
                                                tags6: e.target.value,
                                            }
                                        })}
                                        style={{ width: '100%', height: 40, borderColor: this.state.inputError.cidadaEmpresa && 'var(--red-600)' }} />
                                    {this.state.inputError.cidadaEmpresa && <small style={{ color: 'var(--red-600)' }}>Cidade inválido</small>}

                                </div>
                                <div>
                                    <label>Estado</label><br />
                                    <InputText
                                        value={this.state.documento?.tags7 || ''}
                                        onChange={e => this.setState({
                                            documento: {
                                                ...this.state.documento,
                                                tags7: e.target.value,
                                            }
                                        })}
                                        style={{ width: '100%', height: 40, borderColor: this.state.inputError.estadoEmpresa && 'var(--red-600)' }} />
                                    {this.state.inputError.estadoEmpresa && <small style={{ color: 'var(--red-600)' }}>Estado inválido</small>}

                                </div>
                                <div>
                                    <label>UF</label><br />
                                    <InputText
                                        value={this.state.documento?.tags8 || ''}
                                        onChange={e => this.setState({
                                            documento: {
                                                ...this.state.documento,
                                                tags8: e.target.value,
                                            }
                                        })}
                                        style={{ width: '100%', height: 40, borderColor: this.state.inputError.ufEmpresa && 'var(--red-600)' }} />
                                    {this.state.inputError.ufEmpresa && <small style={{ color: 'var(--red-600)' }}>Uf inválido</small>}

                                </div>

                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>

                                <Button
                                    label="Voltar"
                                    type='button'
                                    className="p-button-raised"
                                    onClick={() => this.setState({ indexStep: 0 })}
                                />

                                <Button
                                    label="Salvar"
                                    type='submit'
                                    loading={this.state.salvarCarregando}
                                    className="p-button-raised" />
                            </div>
                        </>}

                </form>
            </Dialog>

            <HeaderEmpresa />
            <main style={{ width: '95%', margin: '20px auto' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                        <div>
                            <h1 style={{ fontSize: 22 }}>Projetos</h1>
                            <h2 style={{ fontSize: 20, color: 'gray' }}>Projetos</h2>
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, }}>
                        <Button label="Novo Projeto" className="p-button-raised" onClick={() => this.setState({ vModalNovo: true })} />
                    </div>
                </div>

                {/* FILTROS */}
                <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr ', gap: 20 }}>
                        <div>
                            <label>Projeto</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    filtroPesquisa: {
                                        ...this.state.filtroPesquisa, nome: pTexto.target.value
                                    }
                                })}
                                placeholder="Nome"
                                style={{ width: '100%', height: 40 }}
                            />
                        </div>

                        <div>
                            <label>Data Cadastro Inicial</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    filtroPesquisa: {
                                        ...this.state.filtroPesquisa, dataCadastro_inicial: pTexto.target.value
                                    }
                                })}
                                showButtonBar
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy"
                                style={{ width: '100%', height: 40 }}
                            />
                        </div>

                        <div>
                            <label>Data Cadastro Final</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    filtroPesquisa: {
                                        ...this.state.filtroPesquisa, dataCadastro_final: pTexto.target.value
                                    }
                                })}
                                showButtonBar
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy"
                                style={{ width: '100%', height: 40 }}
                            />
                        </div>

                        <div>
                            <label>Data Programada Inicial</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    filtroPesquisa: {
                                        ...this.state.filtroPesquisa, dataProgramada_inicial: pTexto.target.value
                                    }
                                })}
                                showButtonBar
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy"
                                style={{ width: '100%', height: 40 }}
                            />
                        </div>

                        <div>
                            <label>Data Programada Final</label><br />
                            <Calendar
                                onChange={pTexto => this.setState({
                                    filtroPesquisa: {
                                        ...this.state.filtroPesquisa, dataProgramada_final: pTexto.target.value
                                    }
                                })}
                                showButtonBar
                                dateFormat="dd/mm/yy"
                                placeholder="dd/mm/yyyy"
                                style={{ width: '100%', height: 40 }}
                            />
                        </div>

                        <div>
                            <label>Processos</label><br />
                            <Dropdown
                                onChange={item => this.setState({
                                    filtroPesquisa: {
                                        ...this.state.filtroPesquisa, documentoProcesso: item.value
                                    }
                                })}
                                placeholder='Todos'
                                options={[{ ...new DocumentoProcesso(), _tipoDocumentoProcessoNome: 'Todos' }, ...this.state.listaVwContadorDocumento]}
                                optionLabel="_tipoDocumentoProcessoNome"
                                value={this.state.filtroPesquisa.documentoProcesso}
                                style={{ width: '100%', height: 40 }}
                            />
                        </div>

                        <div>
                            <label>Financeiro</label><br />
                            <Dropdown
                                // onChange={item => this.setState({
                                //     filtroPesquisa: {
                                //         ...this.state.filtroPesquisa, status: item.value
                                //     }
                                // })}
                                placeholder='Todos'
                                options={[{ label: "Todos", value: "" }, { label: "Pago", value: "S" }, { label: "Não pago", value: "N" }]}
                                value={this.state.filtroPesquisa.status}
                                style={{ width: '100%', height: 40 }}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'right', marginTop: 20, gap: 10 }}>
                            <Button loading={this.state.salvarCarregando} label="Buscar" className="p-button-raised" onClick={() => this.listar()} />
                            <Button loading={this.state.salvarCarregando} label="Limpar Filtros" className="p-button-raised" onClick={() => this.limparFiltros()} />
                        </div>
                    </div>
                </div>

                {/* TABELA */}
                <div style={{ display: 'flex', gridTemplateColumns: '1fr', marginTop: 20, gap: 10, }}>
                    <DataTable
                        selectionMode="single"
                        showGridlines removableSort
                        paginator
                        responsiveLayout="scroll"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50]}
                        style={{ width: "100%" }}
                        value={this.listaDocumento}
                        onSelectionChange={pEvento => window.open('/empresa/detalhe_projeto?id=' + pEvento.value._seqDocumento)}>
                        <Column
                            header="Código"
                            field="_codigo"
                            sortable />

                        <Column
                            header="Projeto"
                            field="tags0"
                            sortable />

                        <Column
                            header="Email"
                            field="_pessoaEmail"
                            sortable />

                        <Column
                            header="Valor"
                            field="valorTotal"
                            sortable
                            body={item => <p>{this.util.formatarValor(item.valorTotal)}</p>} />

                        <Column
                            header="Data de cadastro"
                            field="dataCadastro"
                            sortable
                            body={item => <p>{this.util.formatarData(item.dataCadastro)}</p>} />

                        <Column
                            header="Data de inicio do projeto"
                            field="dataInicio"
                            sortable
                            body={item => <p>{this.util.formatarData(item.dataInicio)}</p>} />

                        <Column
                            header="Data de final do projeto"
                            field="dataFinal"
                            sortable
                            body={item => <p>{this.util.formatarData(item.dataFinal)}</p>} />

                        <Column
                            header="Data prevista para pagamento"
                            field="dataProgramada"
                            sortable
                            body={item => <p>{this.util.formatarData(item.dataProgramada)}</p>} />

                        <Column
                            header="Processo"
                            field="_tipoDocumentoProcessoNome"
                            sortable />

                    </DataTable>
                </div>
            </main >
        </>
    }
}