export default class Constante {
    // /* DESENVOLVIMENTO */
    // url_base = 'http://192.168.1.20:8080/v1/api/';
    // seqPessoaAdministrador = "56105f10-8375-4953-bd35-7cb7f7418c36";
    // seqTipoRelacionamentoProposta = "c4a222c8-6dd6-4e4b-bb50-45f3c142637f";
    // seqTipoRelacionamentoCandidatoSelecionado = "dcde34e1-a4c6-4d2a-a5d9-a29e79e380b7"

    /* PRODUÇÃO */
    url_base = "https://api.lirida.com.br/v1/api/";
    seqPessoaAdministrador = "5558cfda-4b49-4d5b-b894-ea30e60806b0";
    seqTipoRelacionamentoProposta = "5629f94b-7d3f-493a-b0b9-cec48504610a"
    seqTipoRelacionamentoCandidatoSelecionado = "5ca12de6-bfe4-499d-aa5e-8e33c48175d6"

    token = '09461dae-1719-48e0-9a0e-ae2cef138d9b';
    seqUsuario = '281f7618-446d-4a56-a702-3be093ed3d3b'


    /* PERFIL PERFIL */
    seqPessoaPerfilMei = '0bc96dd7-c718-4476-a280-3deeb260e93d';
    seqPessoaPerfilEmpresa = '489fe1e3-0d2b-42b1-8275-6996e6d8469c';
    seqPessoaPerfilAdministrador = '1078c766-26d2-4bae-b2c5-b1beda10e1eb';

    //EMAIL
    seqEmailAcesso = "bbcf7dbf-b2ab-4dd6-9bfe-a28450f51d7b";

    //TIPO DOCUMENTO
    seqTipoDocumentoProjeto = "ffd61b55-3ce8-4a28-8b4d-db7779341fb2";

    //SEQCATEGORIAITEM
    seqCategoriaItem = "43f32e69-42e0-47dc-b8e6-88831d7d0950";

    // SEQITEM
    seqItem = "783605a0-1066-49ea-ba7b-cffbf20cef81";

    //SEQTABELAPRECO
    seqTabelaPrecoPrincipal = "f9d26576-0833-4c08-9562-aacebb2fd14a";

    //SEQTIPODEPARTAMENTOPROCESSO
    seqTipoDocumentoProcessoNaoFinalizadas = "33305e13-f704-4082-aa49-90f9002e0625";
    seqTipoDocumentoProcessoAnalise = "7ef81fee-d8bf-4fc6-828a-aece96127621";
    seqTipoDocumentoProcessoAguardandoAprovacao = "99673507-0def-4fc8-acd7-a5aafee16ab0";
    seqTipoDocumentoProcessoAprovado = "a08658c7-ea8a-49d2-b41d-44ce4756f9db";

    //SEQTIPORELACIONAMENTO
    seqTipoRelacionamentoEmpresaResponsavel = "cde1b1f5-f9d0-4bd8-9afd-34180eb7b51c";
    seqTipoRelacionamentoCandidato = "30f5a37c-d894-400e-a890-6aada8c94f0e";
    seqTipoRelacionamentoCandidatoAprovado = "ad2fe412-f236-4217-aa9d-cf6f17f7de45";
    seqTipoRelacionamentoCandidatoReprovado = "8b099c5a-1080-42dd-9eee-a9edf70778ef";
    


    //TIPO ENDERECO
    seqTipoEnderecoCobranca = "b4062983-6166-4c91-8319-a25287b505b2";
    seqTipoEnderecoEntrega = "7ffaf5b9-ad2e-4cf8-b30e-25a61fdcaf09";

    //seqContaFinanceira
    seqContaFinanceiraEntradas = "117b1797-e5c9-4c0b-9f14-fa95fb0202dd"
    seqContaFinanceiraReceitas = "45535d7c-2608-4a37-9aed-bac11d018e08"
    seqContaFinanceiroPagamentoFornecedor = "fec1a268-d4da-4e5e-9dbe-b3c6b90161f1"
    seqFormaPagamentoPix = '3db9d74e-dda2-414a-bd94-cec26d5ba59b'
    seqContaBancaria = '5973d61c-efce-4d3a-b0e4-85cb11588239'


    //ID 
    idListaContaBancaria = 'CB'
    idListaContaFinanceira = 'CF'
    idListaFormaPagamento = 'FP'
}