import { Component, createRef } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { TabView, TabPanel } from 'primereact/tabview';
import { Messages } from "primereact/messages";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import HeaderAdm from "./HeaderAdm";

// SERVICES E OBJETOS
import Documento from "lirida-back-service/Servicos/Documento/Documento";
import DocumentoItem from 'lirida-back-service/Servicos/Documento/DocumentoItem';
import DocumentoService from 'lirida-back-service/Servicos/Documento/DocumentoService';
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import CepService from "lirida-back-service/Servicos/Cep/CepService";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import DepartamentoService from "lirida-back-service/Servicos/Departamento/DepartamentoService";
import ItemService from "lirida-back-service/Servicos/Item/ItemService"
import TabelaPrecoService from "lirida-back-service/Servicos/TabelaPreco/TabelaPrecoService";
import FinanceiroService from 'lirida-back-service/Servicos/Financeiro/FinanceiroService';
import Util from '../../Util/util';
import Constante from "../../Util/constante";


export default class DetalheProjetoAdm extends Component {

    mensagens = createRef();
    util = new Util()
    constante = new Constante()
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base)
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base)
    cepService = new CepService(this.constante.token, this.constante.url_base);
    departamentoService = new DepartamentoService(this.constante.token, this.constante.url_base)
    itemService = new ItemService(this.constante.token, this.constante.url_base)
    financeiroService = new FinanceiroService(this.constante.token, this.constante.url_base);
    tabelaPrecoService = new TabelaPrecoService(this.constante.token, this.constante.url_base)


    state = {
        pessoaUsuario: new Pessoa(),
        documento: new Documento(),
        endereco: new Endereco(),
        documentoItem: new DocumentoItem(),
        item: null,
        itemPreco: '',
        quantidadePessoas: '',
        seqDocumentoItem: '',
        valorTotal: 0,

        //LISTAS
        listaItem: [],
        listaDocumentoItem: [],
        listaRelacionamentoDocumento: [],
        listaMovimentoFinanceiro: [],

        liberarAcesso: {
            documento: false,
        },

        //MODAL
        vModalDeletar: false,
        vModalDeletarItem: false,
        vModalSalvar: false,
        vModalAlertaSemItem: false,

        deletarCarregando: false,
        salvarCarregando: false,
    }

    componentDidMount() {
        this.iniciar()
    }

    async iniciar() {
        this.pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        this.url_baseArquivo = await this.util.buscarUrlBaseArquivo();

        if (!this.pessoaUsuario?._seqPessoa || this.pessoaUsuario?.seqPessoaPerfil !== this.constante.seqPessoaPerfilAdministrador) {
            window.history.back();
            return
        }

        this.parametro = this.util.buscarParametrosUrl();
        this.documento = await this.buscarDocumento(this.parametro?.id);
        this.listarItem();
        this.listaDocumentoItem = await this.listarDocumentoItem(this.documento);
        this.listaMovimentoFinanceiro = await this.listarMovimentoFianceiro(this.documento);
        this.listaRelacionamentoDocumento = await this.listarRelacionamentoDocumento(this.documento);

        this.setState({
            pessoaUsuario: this.pessoaUsuario,
            documento: this.documento,
            listaDocumentoItem: this.listaDocumentoItem,
            listaRelacionamentoDocumento: this.listaRelacionamentoDocumento,
            listaMovimentoFinanceiro: this.listaMovimentoFinanceiro,
        })
    }

    async buscarDocumento(pSeqDocumento) {
        this.listaPesquisa = [
            this.util.criarObjetoPesquisa(
                'documento.seq_usuario',
                this.constante.seqUsuario,
                '',
                'TEXTO'
            ),
            this.util.criarObjetoPesquisa(
                'documento.seq_documento',
                pSeqDocumento,
                'AND',
                'TEXTO',
            )
        ]

        this.retorno = await this.documentoService.listarComFiltro(this.listaPesquisa);
        return this.retorno.objeto[0]
    }

    async listarItem() {
        this.retornoListaItem = await this.tabelaPrecoService.listarItem(this.constante.seqTabelaPrecoPrincipal, "43f32e69-42e0-47dc-b8e6-88831d7d0950", "")
        this.listaItem = this.retornoListaItem.objeto
        this.setState({ listaItem: this.listaItem })
    }

    async listarDocumentoItem(pDocumento) {
        if (pDocumento) {
            this.documento = pDocumento
        } else {
            this.documento = this.state.documento;
        }
        this.retornoLista = await this.documentoService.listarDocumentoItem(this.documento);
        this.listaDocumentoItem = this.retornoLista.objeto;
        return this.listaDocumentoItem;
    }

    async listarMovimentoFianceiro(pDocumento) {
        if (pDocumento) {
            this.documento = pDocumento;
        } else {
            this.documento = this.state.documento
        }

        this.lista = [
            this.util.criarObjetoPesquisa(
                'pessoa.seq_usuario',
                this.constante.seqUsuario,
                '',
                'TEXTO'
            ),
            this.util.criarObjetoPesquisa(
                'movimento_financeiro.seq_documento',
                this.documento._seqDocumento,
                'AND',
                'TEXTO'
            )
        ]

        console.log("listapesquisa", this.lista);
        this.retorno = await this.financeiroService.listarComFiltro(this.lista);

        return this.retorno.objeto
    }

    async listarRelacionamentoDocumento(pDocumento) {
        this.retorno = await this.documentoService.listarRelacionamento(pDocumento);
        return this.retorno.objeto
    }

    render() {
        return <div>

            <HeaderAdm />
            <main style={{ width: '95%', margin: '20px auto' }}>
                <Messages ref={this.mensagens} />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                        <div>
                            <h1 style={{ fontSize: 22 }}>Detalhe do projeto </h1>
                            <h2 style={{ fontSize: 20, color: 'gray' }}>Detalhe do projeto</h2>
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, }}>
                        {this.state.salvarCheck ?
                            <Button
                                label="Salvo"
                                icon={<i className="pi pi-check"></i>}
                                className="p-button-success"
                                disabled />
                            :
                            <Button
                                loading={this.state.salvarCarregando}
                                label="Salvar"
                                className="p-button-raised"
                                onClick={() => this.salvar()}
                                disabled />
                        }

                        <div style={{ position: 'relative' }}>
                            <Button
                                disabled
                                label="Opções de envio"
                                className="p-button-raised"
                                onClick={() => this.setState({ vModalOpcoesEnvio: !this.state.vModalOpcoesEnvio })} />

                            {this.state.vModalOpcoesEnvio &&
                                <div style={{ width: 200, position: 'absolute', padding: 5, backgroundColor: "white", boxShadow: "0 0 10px rgba(0,0,0,0.5)", zIndex: 999 }}>
                                    <Button
                                        disabled
                                        label="Enviar para receber propostas"
                                        style={{ textAlign: 'left' }}
                                        onClick={() => this.setState({
                                            vModalSalvar: true,
                                            vModalOpcoesEnvio: false,
                                        })}
                                        className="p-button-link" />
                                </div>
                            }
                        </div>
                    </div>
                </div>

                {/* PROJETO */}
                <div><br />
                    <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                        <Button style={{ width: 30, height: 30 }} type="button" icon="pi pi-book" className="p-button-rounded p-button-sucessul p-button-outlined" aria-label="Home" />
                        <h3>Projeto</h3>
                    </div>

                    <div style={{ display: "grid", gridTemplateColumns: "1fr", gap: 20, border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 20, borderRadius: 3 }}>

                            <div>
                                <label>Nome do Projeto</label><br />
                                <InputText
                                    onChange={pTexto => this.setState({
                                        documento: {
                                            ...this.state.documento, tags0: pTexto.target.value
                                        }
                                    })}
                                    disabled
                                    placeholder="nome do projeto"
                                    value={this.state.documento.tags0 || ''}
                                    style={{ width: '100%', height: 40, }} />
                            </div>

                            <div>
                                <label>Data Pagamento</label><br />
                                <Calendar
                                    onChange={pTexto => this.setState({
                                        documento: {
                                            ...this.state.documento, dataProgramada: pTexto.target.value
                                        }
                                    })}
                                    disabled
                                    value={this.util.formatarDataCalendar(this.state.documento.dataProgramada) || ''}
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    placeholder="dd/mm/yyyy"
                                    style={{ width: '100%', height: 40, }} />
                            </div>

                            <div>
                                <label>Data Inicial</label><br />
                                <Calendar
                                    onChange={pTexto => this.setState({
                                        documento: {
                                            ...this.state.documento, dataInicio: pTexto.target.value
                                        }
                                    })}
                                    disabled
                                    value={this.util.formatarDataCalendar(this.state.documento.dataInicio) || ''}
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    placeholder="dd/mm/yyyy"
                                    style={{ width: '100%', height: 40, }} />
                            </div>

                            <div>
                                <label>Data Final</label><br />
                                <Calendar
                                    onChange={pTexto => this.setState({
                                        documento: {
                                            ...this.state.documento, dataFinal: pTexto.target.value
                                        }
                                    })}
                                    disabled
                                    value={this.util.formatarDataCalendar(this.state.documento.dataFinal) || ''}
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    placeholder="dd/mm/yyyy"
                                    style={{ width: '100%', height: 40, }} />
                            </div>

                        </div>

                        <div >
                            <label>Descrição</label><br />
                            <InputTextarea
                                onChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, observacao: pTexto.target.value
                                    }
                                })}
                                disabled
                                rows={5}
                                cols={50}
                                autoResize
                                placeholder='descrição do projeto'
                                value={this.state.documento.observacao || ''}
                                style={{ width: '100%', height: 40, }} />
                        </div>
                    </div>
                </div>

                {/* LOCAL DE SERVIÇO */}
                <div><br />
                    <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                        <Button style={{ width: 30, height: 30 }} type="button" icon="pi pi-building" className="p-button-rounded p-button-sucessul p-button-outlined" aria-label="Home" />
                        <h3>Local de serviço</h3>
                    </div>

                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: 20, border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>
                        <div>
                            <label>CEP</label><br />
                            <div className="p-inputgroup">
                                <InputText
                                    type="tel"
                                    onChange={e => this.setState({
                                        documento: {
                                            ...this.state.documento,
                                            tags1: this.util.formatarCEP(e.target.value),
                                        }
                                    })}
                                    disabled
                                    value={this.state.documento?.tags1 || ''}
                                    style={{ width: '50%', height: 40 }} />
                                <Button
                                    disabled
                                    icon="pi pi-search"
                                    type="button"
                                    onClick={() => this.buscarCep(this.state.documento.tags1)} />
                            </div>
                        </div>
                        <div>
                            <label>Logradouro</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, tags2: pTexto.target.value
                                    }
                                })}
                                disabled
                                value={this.state.documento?.tags2 || ''}
                                style={{ width: '100%', height: 40 }} />
                        </div>

                        <div>
                            <label>Número</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, tags3: pTexto.target.value
                                    }
                                })}
                                disabled
                                value={this.state.documento?.tags3 || ''}
                                style={{ width: '100%', height: 40 }} />
                        </div>
                        <div>
                            <label>Complemento</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, tags4: pTexto.target.value
                                    }
                                })}
                                disabled
                                value={this.state.documento?.tags4 || ''}
                                style={{ width: '100%', height: 40 }} />
                        </div>


                        <div>
                            <label>Bairro</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, tags5: pTexto.target.value
                                    }
                                })}
                                disabled
                                value={this.state.documento?.tags5 || ''}
                                style={{ width: '100%', height: 40 }} />
                        </div>

                        <div>
                            <label>Cidade</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, tags6: pTexto.target.value
                                    }
                                })}
                                disabled
                                value={this.state.documento?.tags6 || ''}
                                style={{ width: '100%', height: 40 }} />
                        </div>
                        <div>
                            <label>Estado</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, tags7: pTexto.target.value
                                    }
                                })}
                                disabled
                                value={this.state.documento?.tags7 || ''}
                                style={{ width: '100%', height: 40 }} />
                        </div>
                        <div>
                            <label>UF</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, tags8: pTexto.target.value
                                    }
                                })}
                                disabled
                                value={this.state.documento?.tags8 || ''}
                                style={{ width: '100%', height: 40 }} />
                        </div>
                    </div>
                </div>

                <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>
                    <TabView style={{ marginTop: 10 }}>

                        <TabPanel header="Serviços">
                            <h2 style={{ fontSize: 22.5 }}>Itens</h2>
                            <div style={{ marginTop: 5, display: "grid", gridTemplateColumns: "1fr ", gap: 20, alignItems: "center" }}>
                                <div style={{ display: "flex", alignItens: "center", gap: 15, }}>
                                    <Dropdown
                                        disabled
                                        onChange={e => this.setState({ item: e.value })}
                                        value={this.state.item}
                                        options={this.state.listaItem}
                                        optionLabel="_itemNome"
                                        placeholder='Selecione'
                                        style={{ width: '100%', height: 40 }} />
                                </div>

                                <div style={{ display: "flex", alignItens: "center", gap: 15 }}>
                                    <label>Quantidade</label>
                                    <InputText
                                        onChange={pTexto => this.setState({ quantidadePessoas: pTexto.target.value })}
                                        value={this.state.quantidadePessoas}
                                        disabled
                                        placeholder='Quantidade'
                                        style={{ width: '50%', height: 40 }} />

                                    <label>Valor Unitário</label>
                                    <InputText
                                        onChange={pTexto => this.setState({ itemPreco: pTexto.target.value })}
                                        disabled
                                        value={this.state.itemPreco}
                                        placeholder='Valor'
                                        style={{ width: '50%', height: 40 }} />

                                </div>

                                <div style={{ display: "flex", justifyContent: "flex-end" }}>

                                    <Button
                                        disabled
                                        type="button"
                                        style={{ borderRadius: 5 }}
                                        onClick={() => this.salvarItem()}
                                    >Adicionar </Button>
                                </div>

                            </div>

                            <DataTable
                                style={{ marginTop: 20 }}
                                showGridlines
                                removableSort
                                paginator
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={5} rowsPerPageOptions={[10, 20, 50]}
                                value={this.state.listaDocumentoItem}>

                                <Column
                                    header="CNAES"
                                    field="_itemNome"
                                    sortable />

                                <Column
                                    header="Quantidade"
                                    field="quantidade"
                                    sortable />

                                <Column
                                    header="Preço Unitário "
                                    body={item => this.util.formatarValor(item.precoUnitario)}
                                    sortable />

                                <Column
                                    header="Preço Total"
                                    body={item => this.util.formatarValor(item.precoTabela)}
                                    sortable />

                                <Column
                                    header="Opção"
                                    field="_seqDocumentoItem"
                                    alignHeader="center"
                                    body={item => <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                                        <Button
                                            onClick={() => this.setState({
                                                vModalDeletarItem: true,
                                                seqDocumentoItem: item._seqDocumentoItem
                                            })}
                                            disabled
                                            icon="pi pi-trash"
                                            className="p-button-rounded p-button-danger"
                                            aria-label="Filter" />
                                    </div>} />
                            </DataTable>
                            <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
                                <h2>Valor total</h2>
                                <h2>{this.util.formatarValor(this.state.documento.valorTotal)}</h2>
                            </div>
                        </TabPanel>

                        <TabPanel
                            header="Fatura"
                            disabled={this.state.documento.tags9 ? false : true}
                        >
                            <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>
                                <h2 style={{ fontSize: 22.5 }}>Fatura</h2>
                                <DataTable
                                    // selectionMode="single"
                                    showGridlines removableSort
                                    paginator
                                    responsiveLayout="scroll"
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                    rows={10}
                                    rowsPerPageOptions={[10, 20, 50]}
                                    value={this.state.listaMovimentoFinanceiro}
                                // onSelectionChange={item => this.irParaCrossPag(item.value)}
                                >
                                    <Column field="_pesNome" header="Cliente" />
                                    <Column field="valorProgramado" header="Valor" body={item => `${this.util.formatarValor(item.valorProgramado)}`} />
                                    <Column field="dataVencimentoPagamento" header="Data de vencimento" body={item => `${this.util.formatarData(item.dataVencimentoPagamento)}`} />
                                    <Column field="_dataBaixa" header="Data baixa" body={item => `${this.util.formatarData(item._dataBaixa)}`} />
                                    <Column field="_fpNome" header="Forma de pagamento" />
                                    <Column field="qtdeBaixa" header="Status" body={item => item.qtdeBaixa > 0 ? 'Pago' : 'Aguardando pagamento'} />
                                    <Column field="qtdeBaixa" header="Visulizar" body={pMovimentoFinanceiro => <div>
                                        <Button
                                            disabled={pMovimentoFinanceiro.qtdeBaixa > 0}
                                            onClick={() => this.irParaImpressaoPg(pMovimentoFinanceiro)}
                                            icon="pi pi-eye"
                                            className="p-button-rounded p-button-help"
                                            aria-label="Filter" >

                                        </Button>
                                    </div>} />
                                </DataTable>
                            </div>
                        </TabPanel>

                        <TabPanel header="Propostas">
                            <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>
                                <h2 style={{ fontSize: 22.5 }}>Propostas</h2>
                                {/* <DataTable selectionMode="single" showGridlines value={this.state.listaPropostas}>
                                <Column
                                    field="Foto"
                                    header="Foto líder"
                                    body={item => <img style={{ width: 70, height: 70, borderRadius: 500, objectFit: "initial" }}
                                        src={
                                            this.state.url_baseArquivo +
                                            item._pessoaFoto} />
                                    }
                                />
                                <Column
                                    field="_pessoaNome"
                                    header="Nome" />
                                <Column
                                    field="_pessoaEmail"
                                    header="Email" />

                                <Column
                                    header="status"
                                    body={item => item.status === 2 ? (
                                        <p style={{ color: "black" }}>Analise</p>
                                    ) : item.status === 3 ? (
                                        <p style={{ color: "green" }}>Aprovado</p>
                                    ) : item.status === 4 ? (
                                        <p style={{ color: "red" }}>Reprovado</p>
                                    ) : null} />

                                <Column
                                    header="Visulizar"
                                    body={item => <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
                                        <Button
                                            onClick={() => this.listarDocumentoItemProposta(item)}
                                            icon="pi pi-eye"
                                            className="p-button-rounded p-button-help"
                                            aria-label="Filter" >

                                        </Button>

                                        <Button
                                            disabled={item.status !== 3}
                                            onClick={() => this.setState({
                                                vModalEnviarCliente: true,
                                                documentoPropostaCliente: item
                                            })}
                                            icon="pi pi-send"
                                            className="p-button-rounded p-button-help"
                                            aria-label="Filter" />

                                    </div>} />

                            </DataTable> */}
                            </div>
                        </TabPanel>

                        {/* <TabPanel header="Relacionamento">
                            <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>
                                <h2 style={{ fontSize: 22.5 }}>Relacionamento</h2>
                                <DataTable
                                    selectionMode="single"
                                    showGridlines removableSort
                                    paginator
                                    responsiveLayout="scroll"
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                    rows={10}
                                    rowsPerPageOptions={[10, 20, 50]}
                                    value={this.state.listaRelacionamentoDocumento}>
                                    <Column field="_pessoaRelacionadaNome" header="Nome" />
                                    <Column field="_tipoRelacionamentoNome" header="Tipo de relacionamento" />
                                    <Column field="dataCadastro" header="data de cadastro" body={item => <p>{this.util.formatarData(item.dataCadastro)}</p>} />
                                </DataTable>
                            </div>
                        </TabPanel> */}

                    </TabView>
                </div>
            </main >
        </div >
    }
}