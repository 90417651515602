import { Component } from "react";
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";


import Util from '../../Util/util';
import Constante from "../../Util/constante";
import DocumentoItemRelacionamento from "lirida-back-service/Servicos/DocumentoItemRelacionamento/DocumentoItemRelacionamento";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import HeaderEmpresa from "./headerEmpresa";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";

export default class CandidatoDetalhe extends Component {

    constante = new Constante()
    util = new Util()
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base)
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base)

    state = {
        pessoa: new Pessoa(),
        documentoItemRelacinamento: new DocumentoItemRelacionamento(),
        listaArquivo: [],
        listaServicos: [],
    }

    componentDidMount() {
        this.iniciar()
    }

    async iniciar() {
        let parametro = this.util.buscarParametros()

        let retorno = await this.pessoaService.buscarPorSeqPessoa(parametro.id, this.constante.seqUsuario)
        let pessoa = retorno.objeto

        let retornoArquivo = await this.pessoaService.listarArquivo(pessoa)
        let listaArquivo = retornoArquivo.objeto

        this.listarServicos(pessoa)

        this.setState({
            pessoa: pessoa,
            listaArquivo: listaArquivo
        })

    }

    async listarServicos(pPessoa) {

        let listaPesquisa = []

        let pesquisa = new Pesquisa();
        pesquisa.campo = "documento_item.seq_usuario";
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = "";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa)

        pesquisa = new Pesquisa();
        pesquisa.campo = "documento_item_relacionamento.seq_pessoa_relacionada";
        pesquisa.conteudo = pPessoa._seqPessoa;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa)

        pesquisa = new Pesquisa();
        pesquisa.campo = "documento_item_relacionamento.seq_tipo_relacionamento";
        pesquisa.conteudo = this.constante.seqTipoRelacionamentoCandidatoAprovado;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa)

        console.log(listaPesquisa)
        let retornoListaServicos = await this.documentoService.listarItemComFiltro(listaPesquisa)
        let listaServicos = retornoListaServicos.objeto

        console.log(listaServicos)

        this.setState({
            listaServicos: listaServicos,
        })
    }

    render() {
        return (
            <div>
                {/* TELA */}
                <HeaderEmpresa />

                <main style={{ display: "flex", justifyContent: "center" }}>
                    <section style={{ width: '95%', margin: '20px auto' }}>
                        {/* Detalhes */}

                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20 }}>
                            <div>
                                <label>Nome:</label>
                                <InputText disabled className="p-inputtext" style={{ width: "100%", borderWidth: '0 0 1px 0', fontSize: 20 }} value={this.state.pessoa.nome} />
                            </div>

                            <div>
                                <label>Email:</label>
                                <InputText disabled style={{ width: "100%", borderWidth: '0 0 1px 0', fontSize: 20 }} value={this.state.pessoa.emailPlataformaLirida} />
                            </div>

                            <div>
                                <label>Telefone:</label>
                                <InputText disabled style={{ width: "100%", borderWidth: '0 0 1px 0', fontSize: 20 }} value={this.state.pessoa.telefonePlataformaLirida} />
                            </div>

                        </div>

                        <br />
                        <h2>Endereço:</h2>
                        <br />

                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20 }}>

                            <div>
                                <label>Cep:</label>
                                <InputText disabled style={{ width: "100%", borderWidth: '0 0 1px 0', fontSize: 20 }} value={this.state.pessoa._endCep} />
                            </div>

                            <div>
                                <label>Rua:</label>
                                <InputText disabled style={{ width: "100%", borderWidth: '0 0 1px 0', fontSize: 20 }} value={this.state.pessoa._endLogradouro} />
                            </div>

                            <div>
                                <label>Cidade:</label>
                                <InputText disabled style={{ width: "100%", borderWidth: '0 0 1px 0', fontSize: 20 }} value={this.state.pessoa._endCidade} />
                            </div>

                            <div style={{ display: 'flex', gap: 20 }}>
                                <div style={{ width: '80%' }}>
                                    <label>Bairro:</label>
                                    <InputText disabled style={{ width: "100%", borderWidth: '0 0 1px 0', fontSize: 20 }} value={this.state.pessoa._endBairro} />
                                </div>

                                <div style={{ width: "20%" }}>
                                    <label>Numero:</label>
                                    <InputText disabled style={{ width: "100%", borderWidth: '0 0 1px 0', fontSize: 20 }} value={this.state.pessoa._endNumero} />
                                </div>
                            </div>
                        </div>
                        <br />
                        <TabView>
                            <TabPanel header="Certificados">
                                <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>
                                    <DataTable
                                        style={{ marginTop: 20 }}
                                        showGridlines
                                        removableSort
                                        paginator
                                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={5} rowsPerPageOptions={[10, 20, 50]}
                                        value={this.state.listaArquivo}>

                                        <Column
                                            header="Certificados"
                                            body={item => <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                                                <a href={this.state.url_baseArquivo + item.arquivo} target={"_blank"}>{item.arquivoOriginal}</a>
                                            </div>} />
                                    </DataTable>
                                </div>
                            </TabPanel>

                            <TabPanel header="Historico de serviços">
                                <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>
                                    <DataTable
                                        style={{ marginTop: 20 }}
                                        showGridlines
                                        removableSort
                                        paginator
                                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={5} rowsPerPageOptions={[10, 20, 50]}
                                        value={this.state.listaServicos}>

                                        <Column
                                            header="Empresa"
                                            field="_pessoaNome"
                                        />

                                        <Column
                                            header="Projeto"
                                            field="tags0"
                                        />

                                        <Column
                                            header="Vaga"
                                            field="_itemDescricaoVitrine"
                                        />

                                        <Column
                                            header="data inicio"
                                            body={item => this.util.formatarData(item.dataInicio)}
                                        />

                                        <Column
                                            header="data Final"
                                            body={item => this.util.formatarData(item.dataFinal)}
                                        />
                                        <Column
                                            header="Relevância"
                                            field="tags1"
                                            body={<div style={{ textAlign: "center" }}>
                                                <span className="pi pi-thumbs-up"></span></div>}
                                            sortable />
                                    </DataTable>
                                </div>
                            </TabPanel>
                        </TabView>
                    </section>
                </main>
            </div>
        )
    }
}