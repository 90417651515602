import { Button } from "primereact/button";
import { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import HeaderEmpresa from "./headerEmpresa";

export default class MensalidadePg extends Component {
    render() {
        return <div>
            <HeaderEmpresa />

            <main style={{ width: '95%', margin: '20px auto' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 20, margin: '20px 0' }}>
                        <div>
                            <h1 style={{ fontSize: 22, }}>Mensalidade</h1>
                            <h2 style={{ fontSize: 20, color: 'gray' }}>Mensalidade</h2>
                        </div>
                    </div>
                </div>

                <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, gap: 20, marginTop: 20 }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 20 }}>
                        <div>
                            <label>Label</label><br />
                            <Dropdown style={{ width: '100%', }} />
                        </div>
                        <div>
                            <label>Label</label><br />
                            <Dropdown style={{ width: '100%', }} />
                        </div>
                        <div>
                            <label>Label</label><br />
                            <Dropdown style={{ width: '100%', }} />
                        </div>
                        <div>
                            <label>Label</label><br />
                            <Dropdown style={{ width: '100%', }} />
                        </div>
                        <div>
                            <label>Label</label><br />
                            <Calendar style={{ width: '100%', }} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Label</label><br />
                            <Calendar style={{ width: '100%', }} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Label</label><br />
                            <Calendar style={{ width: '100%', }} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
                        </div>
                        <div>
                            <label>Label</label><br />
                            <Calendar style={{ width: '100%', }} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'right', marginTop: 20 }}>
                        <Button label="Buscar" className="p-button-raised" />
                    </div>
                </div>

                <DataTable style={{ marginTop: 20 }} selectionMode="single" showGridlines removableSort value={[{ campo1: 'dados1', campo2: 'dados2' }, { campo1: 'dados1', campo2: 'dados2' }]} onSelectionChange={e => this.selecionar(e.value)}>
                    <Column field="campo1" header="titulo1" sortable></Column>
                    <Column field="campo2" header="titulo2" sortable></Column>
                </DataTable>
            </main>
        </div>
    }
}