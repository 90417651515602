import { Component, createRef } from "react";
import { Avatar } from "primereact/avatar";
import { InputText } from "primereact/inputtext";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Util from "../../Util/util";
import Constante from "../../Util/constante";
import { Toast } from "primereact/toast";
import Load from "./Load";

export default class FormFotoPerfil extends Component {
    toast = createRef();
    util = new Util();
    constante = new Constante();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

    state = {
        urlBase: '',
        pessoaUsuario: new Pessoa(),
        base64: '',
        carregando: false,
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const urlBase = await this.util.buscarUrlBaseArquivo();
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");

        this.setState({
            pessoaUsuario: pessoaUsuario,
            urlBase: urlBase,
        });
    }

    async salvar(pPessoa) {
        this.setState({ carregando: true });

        let pessoaUsuario;

        if (pPessoa) {
            pessoaUsuario = pPessoa;
        } else {
            pessoaUsuario = this.state.pessoaUsuario;
        }

        const retornoPessoa = await this.pessoaService.salvar(pessoaUsuario);
        pessoaUsuario = retornoPessoa.objeto;

        this.util.salvarLocalStorage("PESSOA_USUARIO", pessoaUsuario);

        this.toast.current.show([
            { severity: 'success', summary: 'Informações armazenadas com sucesso!' },
        ]);

        this.setState({
            pessoaUsuario: pessoaUsuario,
            carregando: false,
        });

        if (this.props?.avancarFormulario) {
            this.props.avancarFormulario();
        }
    }


    async selecionarArquivo(pAquivo) {
        const retorno = await this.util.pegarBase64(pAquivo);
        let pessoaUsuario = this.state.pessoaUsuario;

        console.log(retorno)

        pessoaUsuario.foto = retorno;

        this.salvar(pessoaUsuario);

        this.setState({
            base64: retorno,
            pessoaUsuario: pessoaUsuario
        });
    }

    render() {
        return <>

            <Toast ref={this.toast} />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20, marginTop: 20 }}>

                {this.state.carregando ? <Load />
                    :
                    < Avatar
                        style={{ width: 200, height: 200 }}
                        label={String(this.state.pessoaUsuario.nome).charAt(0)}
                        shape="circle"
                        image={(this.state.urlBase && this.state.pessoaUsuario.foto && String(this.state.pessoaUsuario.foto).length < 1000) ? this.state.urlBase + this.state.pessoaUsuario.foto : undefined}
                    />
                }
                <InputText type="file" onChange={e => this.selecionarArquivo(e.target.files[0])} />

            </div>
        </>
    }
}