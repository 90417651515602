import CepService from "lirida-back-service/Servicos/Cep/CepService";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Component, createRef } from "react";
import Constante from "../../Util/constante";
import Util from "../../Util/util";

export default class FormEndereco extends Component {
    toast = createRef();
    util = new Util();
    constante = new Constante();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
    cepService = new CepService(this.constante.token, this.constante.url_base);

    state = {
        urlBase: '',
        pessoaUsuario: new Pessoa(),
        endereco: new Endereco(),

        carregando: false,
        inputEnderecoError: {
            cep: false,
            logradouro: false,
            numero: false,
            bairro: false,
            cidade: false,
            estado: false,
            uf: false,
        },
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        let endereco = this.state.endereco;

        // console.log(pessoaUsuario);

        const retorno = await this.pessoaService.listarEndereco(pessoaUsuario);
        const listaEndereco = retorno.objeto;
        console.log("==>")
        console.log(listaEndereco)


        for (let i = 0; i < listaEndereco.length; i++) {
            if (pessoaUsuario?.seqEnderecoPrincipal === listaEndereco[i]._seqEndereco) {
                console.log(listaEndereco[i])
                endereco = listaEndereco[i]
            }

        }
        console.log("**")

        console.log(endereco);

        this.setState({
            pessoaUsuario: pessoaUsuario,
            endereco: endereco,
        });
    }

    async buscarCEP(pCep) {
        const endereco = this.state.endereco;

        const retorno = await this.cepService.buscarCep(pCep);

        endereco.bairro = retorno.bairro;
        endereco.complemento = retorno.complemento;
        endereco.cidade = retorno.localidade;
        endereco.estado = retorno.estado;
        endereco.logradouro = retorno.logradouro;
        endereco.uf = retorno.uf;

        this.setState({ endereco: endereco });
    }

    validarInputsEndereco() {
        const inputEnderecoError = this.state.inputEnderecoError;
        const listaChaveInputEnderecoError = Object.keys(inputEnderecoError);

        for (let i = 0; i < listaChaveInputEnderecoError.length; i++) {
            inputEnderecoError[listaChaveInputEnderecoError[i]] = false;
        }

        if (!this.state.endereco.cep) {
            inputEnderecoError.cep = true;
        }

        if (!this.state.endereco.logradouro) {
            inputEnderecoError.logradouro = true;
        }

        if (!this.state.endereco.numero) {
            inputEnderecoError.numero = true;
        }

        if (!this.state.endereco.bairro) {
            inputEnderecoError.bairro = true;
        }

        if (!this.state.endereco.cidade) {
            inputEnderecoError.cidade = true;
        }

        if (!this.state.endereco.estado) {
            inputEnderecoError.estado = true;
        }

        if (!this.state.endereco.uf) {
            inputEnderecoError.uf = true;
        }

        let inputOK = true;

        for (let i = 0; i < listaChaveInputEnderecoError.length; i++) {
            if (inputEnderecoError[listaChaveInputEnderecoError[i]]) {
                inputOK = false;
            }
        }

        this.setState({ inputEnderecoError: inputEnderecoError });

        return inputOK;
    }

    async salvar() {
        this.setState({ carregando: true });

        let endereco = this.state.endereco;
        let pessoaUsuario = this.state.pessoaUsuario;

        if (!pessoaUsuario.seqEnderecoPrincipal) {
            endereco.seqUsuario = this.constante.seqUsuario;
            endereco.seqPessoa = pessoaUsuario._seqPessoa;
            endereco.seqTipoEndereco = this.constante.seqTipoEnderecoCobranca;

            const retornoEndereco = await this.pessoaService.salvarEndereco(endereco);
            endereco = retornoEndereco.objeto;

            pessoaUsuario.seqEnderecoPrincipal = endereco._seqEndereco;

            const retornoPessoaEndereco = await this.pessoaService.salvar(pessoaUsuario);
            pessoaUsuario = retornoPessoaEndereco.objeto;

            this.util.salvarLocalStorage("PESSOA_USUARIO", pessoaUsuario);

        } else {
            const retornoEndereco = await this.pessoaService.salvarEndereco(endereco);
            console.log(retornoEndereco);
        }

        this.toast.current.show([
            { severity: 'success', summary: 'Informações armazenadas com sucesso!' },
        ]);

        this.setState({
            pessoaUsuario: pessoaUsuario,
            carregando: false,
        });

        if (this.props?.avancarFormulario) {
            this.props.avancarFormulario();
        }
    }

    render() {
        return <div>
            <Toast ref={this.toast} />
            <form
                onSubmit={e => {
                    e.preventDefault();
                    const retorno = this.validarInputsEndereco();
                    if (retorno) this.salvar();
                }}
                style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 20 }}
            >
                <div>
                    <label>CEP</label><br />
                    <div className="p-inputgroup">
                        <InputText
                            type="tel"
                            onChange={e => this.setState({
                                endereco: {
                                    ...this.state.endereco,
                                    cep: this.util.formatarCEP(e.target.value),
                                }
                            })}
                            value={this.state.endereco.cep}
                            style={{ width: '100%', borderColor: this.state.inputEnderecoError.cep && 'var(--red-600)' }} />
                        <Button
                            icon="pi pi-search"
                            type="button"
                            onClick={() => this.buscarCEP(this.state.endereco.cep)} />
                    </div>
                    {this.state.inputEnderecoError.cep && <small style={{ color: 'var(--red-600)' }}>Cep inválido</small>}
                </div>
                <div>
                    <label>Logradouro</label><br />
                    <InputText
                        value={this.state.endereco.logradouro}
                        onChange={e => this.setState({
                            endereco: {
                                ...this.state.endereco,
                                logradouro: e.target.value,
                            }
                        })}
                        style={{ width: '100%', borderColor: this.state.inputEnderecoError.logradouro && 'var(--red-600)' }} />
                    {this.state.inputEnderecoError.logradouro && <small style={{ color: 'var(--red-600)' }}>Logradouro inválido</small>}
                </div>

                <div>
                    <label>Complemento</label><br />
                    <InputText
                        value={this.state.endereco.complemento}
                        onChange={e => this.setState({
                            endereco: {
                                ...this.state.endereco,
                                complemento: e.target.value,
                            }
                        })}
                        style={{ width: '100%' }} />
                </div>

                <div>
                    <label>Número</label><br />
                    <InputText
                        value={this.state.endereco.numero}
                        onChange={e => this.setState({
                            endereco: {
                                ...this.state.endereco,
                                numero: e.target.value,
                            }
                        })}
                        style={{ width: '100%', borderColor: this.state.inputEnderecoError.numero && 'var(--red-600)' }} />
                    {this.state.inputEnderecoError.numero && <small style={{ color: 'var(--red-600)' }}>Número inválido</small>}
                </div>
                <div>
                    <label>Bairro</label><br />
                    <InputText
                        value={this.state.endereco.bairro}
                        onChange={e => this.setState({
                            endereco: {
                                ...this.state.endereco,
                                bairro: e.target.value,
                            }
                        })}
                        style={{ width: '100%', borderColor: this.state.inputEnderecoError.bairro && 'var(--red-600)' }} />
                    {this.state.inputEnderecoError.bairro && <small style={{ color: 'var(--red-600)' }}>Bairro inválido</small>}
                </div>
                <div>
                    <label>Cidade</label><br />
                    <InputText
                        value={this.state.endereco.cidade}
                        onChange={e => this.setState({
                            endereco: {
                                ...this.state.endereco,
                                cidade: e.target.value,
                            }
                        })}
                        style={{ width: '100%', borderColor: this.state.inputEnderecoError.cidade && 'var(--red-600)' }} />
                    {this.state.inputEnderecoError.cidade && <small style={{ color: 'var(--red-600)' }}>Cidade inválido</small>}
                </div>
                <div>
                    <label>Estado</label><br />
                    <InputText
                        value={this.state.endereco.estado}
                        onChange={e => this.setState({
                            endereco: {
                                ...this.state.endereco,
                                estado: e.target.value,
                            }
                        })}
                        style={{ width: '100%', borderColor: this.state.inputEnderecoError.estado && 'var(--red-600)' }} />
                    {this.state.inputEnderecoError.estado && <small style={{ color: 'var(--red-600)' }}>Estado inválido</small>}
                </div>
                <div>
                    <label>UF</label><br />
                    <InputText
                        value={this.state.endereco.uf}
                        onChange={e => this.setState({
                            endereco: {
                                ...this.state.endereco,
                                uf: e.target.value,
                            }
                        })}
                        style={{ width: '100%', borderColor: this.state.inputEnderecoError.uf && 'var(--red-600)' }} />
                    {this.state.inputEnderecoError.uf && <small style={{ color: 'var(--red-600)' }}>UF inválido</small>}
                </div>

                <div style={{ display: 'flex', justifyContent: 'right' }}>
                    <Button loading={this.state.carregando} type="submit" label={this.props?.avancarFormulario ? "Próximo" : "Salvar"} />
                </div>
            </form>
        </div>
    }
}