import { Component, createRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';

import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import GoogleAPI from 'lirida-back-service/Servicos/Google/GoogleAPI';
import Constante from "../../Util/constante";
import Util from "../../Util/util";

export default class EmpresaDetalhe extends Component {
    constante = new Constante();
    util = new Util();
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base);
    googleApi = new GoogleAPI(this.constante.token, this.constante.url_base);
    toast = createRef();

    state = {

        pessoaUsuario: new Pessoa(),
        pessoa: new Pessoa(),
        endereco: new Endereco(),

        listaEndereco: [],
        urlBase: '',
        conteudo: "",
        pesquisaVisivel: false,
        carregando: false,

        inputError: {
            nome: false,
            tipoPessoa: false,
            documento: false,
            email: false,
            telefone: false,
            endereco: false,
        }
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        const urlBase = await this.util.buscarUrlBaseArquivo();
        const pessoa = this.props.pessoa;
        let endereco = this.state.endereco;

        const retornoListaEndereco = await this.pessoaService.listarEndereco(pessoa);
        const listaEndereco = retornoListaEndereco.objeto;

        listaEndereco.forEach(pEndereco => {
            if (pEndereco._seqEndereco === pessoa.seqEnderecoPrincipal) {
                endereco = pEndereco;
            }
        })

        this.setState({
            urlBase: urlBase,
            pessoaUsuario: pessoaUsuario,
            pessoa: pessoa,
            conteudo: pessoa.tags0,
            endereco: endereco
        });
    }

    async salvar() {
        this.setState({ carregando: true })
        const inputError = this.state.inputError;
        let pessoa = this.state.pessoa;
        let endereco = this.state.endereco;
        let inputOK = true;

        const retornoPessoaAdministradora = await this.pessoaService.buscarPorSeqPessoa(this.constante.seqPessoaAdministrador)
        let pessoaAdministradora = retornoPessoaAdministradora.objeto

        Object.keys(inputError).forEach(pKey => inputError[pKey] = false);

        if (!pessoa.nome) {
            inputError.nome = true;
        }

        if (!pessoa.documento) {
            inputError.documento = true;
        }

        if (pessoa.tipoDocumento === "CNPJ" && pessoa.documento.length !== 18) {
            inputError.documento = true;
        }

        if (!pessoa.emailPlataformaLirida) {
            inputError.email = true;
        }

        if (!pessoa.telefonePlataformaLirida) {
            inputError.telefone = true;
        }

        if (!pessoa.tags0) {
            inputError.endereco = true;
        }

        Object.keys(inputError).forEach(pKey => {
            if (inputError[pKey] === true) {
                inputOK = false;
            }
        });

        if (!inputOK) {
            this.setState({ inputError: inputError });
            return;
        }

        this.setState({ carregando: true });

        pessoa.seqUsuario = this.constante.seqUsuario;
        pessoa.tipoDocumento = "CNPJ";
        pessoa.seqPessoaPerfil = this.constante.seqPessoaPerfilEmpresa;
        pessoa.taxaBoleto = pessoaAdministradora.taxaBoleto;
        pessoa.taxaCredito = pessoaAdministradora.taxaCredito;
        pessoa.taxaPix = pessoaAdministradora.taxaPix;
        pessoa.tipoTaxaBoleto = pessoaAdministradora.tipoTaxaBoleto
        pessoa.tipoTaxaCredito = pessoaAdministradora.tipoTaxaCredito
        pessoa.tipoTaxaPix = pessoaAdministradora.tipoTaxaPix

        const retornoPessoa = await this.pessoaService.salvar(pessoa);
        pessoa = retornoPessoa.objeto;

        endereco.seqUsuario = this.constante.seqUsuario;
        endereco.seqPessoa = pessoa._seqPessoa;

        const retornoEndereco = await this.pessoaService.salvarEndereco(endereco);
        endereco = retornoEndereco.objeto;

        pessoa.seqEnderecoPrincipal = endereco._seqEndereco;


        await this.pessoaService.salvar(pessoa);
        await this.util.enviarEmail(this.constante.seqEmailAcesso, pessoa.emailPlataformaLirida, pessoa.nome, pessoa.senha)

        this.toast.current.show([
            { severity: 'success', summary: 'Empresa cadastrada com sucesso!' },
        ]);

        this.setState({
            pessoa: pessoa,
            carregando: false,
        });

        this.props.listar();
    }

    async listarEndereco(pTexto) {
        if (pTexto.length < 3) return;

        const retornoListaEndereco = await this.googleApi.listarEndereco(pTexto);
        this.setState({ listaEndereco: retornoListaEndereco.predictions });
    }

    async buscarEndereco(pEndereco) {
        const retornoEndereco = await this.googleApi.buscarEndereco(pEndereco.place_id);
        const endereco = this.state.endereco;

        retornoEndereco.result.address_components.forEach(pEndereco => {
            if (pEndereco.types.includes("street_number")) {
                endereco.numero = pEndereco.long_name;
            }
            if (pEndereco.types.includes("route")) {
                endereco.logradouro = pEndereco.long_name;
            }
            if (pEndereco.types.includes("sublocality_level_1")) {
                endereco.bairro = pEndereco.long_name;
            }
            if (pEndereco.types.includes("administrative_area_level_2")) {
                endereco.cidade = pEndereco.long_name;
            }
            if (pEndereco.types.includes("administrative_area_level_1")) {
                endereco.estado = pEndereco.long_name;
                endereco.uf = pEndereco.short_name;
            }
            if (pEndereco.types.includes("country")) {
                endereco.pais = pEndereco.long_name;
            }
            if (pEndereco.types.includes("postal_code")) {
                endereco.cep = pEndereco.long_name;
            }
        });

        endereco.latitude = retornoEndereco.result.geometry.location.lat;
        endereco.longitude = retornoEndereco.result.geometry.location.lng;

        this.setState({
            endereco: endereco,
            pesquisaVisivel: false,
            listaEndereco: [],
            conteudo: pEndereco.description,
            pessoa: {
                ...this.state.pessoa,
                tags0: pEndereco.description,
                latitude: endereco.latitude,
                longitude: endereco.longitude,
            }
        });
    }

    render() {
        return <div>
            <Toast ref={this.toast} />

            <main style={{ width: '98%', margin: '0 auto', marginTop: 20 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '20px 10px', border: '1px solid lightgray', borderRadius: 5 }}>
                    <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                        <Button
                            label={<i className="pi pi-arrow-left" />}
                            onClick={() => this.props.setState({
                                pessoa: new Pessoa,
                                tela: "1"
                            })} />
                        <h1 style={{ fontSize: 22.5 }}>Detalhe da empresa</h1>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, }}>
                        <Button
                            loading={this.state.carregando}
                            label="Salvar"
                            onClick={() => this.salvar()} />
                    </div>
                </div>

                <div style={{ padding: '20px 10px', border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
                    <div style={{ width: 200, height: 200, backgroundColor: 'lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
                        {this.state.pessoa.foto ?
                            <img
                                src={this.state.urlBase + this.state.pessoa.foto}
                                style={{ width: 200, height: 200, objectFit: 'contain' }} />
                            :
                            <p>sem imagem</p>
                        }
                    </div>

                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", marginTop: 20, gap: 20 }}>
                        <div>
                            <label>Nome</label><br />
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.nome && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        nome: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoa.nome || ""} />
                            {this.state.inputError.nome && <small style={{ color: 'red' }}>Nome inválido</small>}
                        </div>
                        <div>
                            <label>CNPJ</label><br />
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.documento && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        documento: this.util.formatarCNPJ(pEvento.target.value)
                                    }
                                })}
                                value={this.state.pessoa.documento || ""} />
                            {this.state.inputError.documento && <small style={{ color: 'red' }}>Documento inválido</small>}
                        </div>

                        <div>
                            <label>Email</label><br />
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.email && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        emailPlataformaLirida: pEvento.target.value
                                    }
                                })}
                                value={this.state.pessoa.emailPlataformaLirida || ""} />
                            {this.state.inputError.email && <small style={{ color: 'red' }}>Email inválido</small>}
                        </div>
                        <div>
                            <label>Telefone</label><br />
                            <InputText
                                style={{ width: '100%', borderColor: this.state.inputError.telefone && 'red' }}
                                onChange={pEvento => this.setState({
                                    pessoa: {
                                        ...this.state.pessoa,
                                        telefonePlataformaLirida: this.util.formatarTelefone(pEvento.target.value)
                                    }
                                })}
                                value={this.state.pessoa.telefonePlataformaLirida || ""} />
                            {this.state.inputError.telefone && <small style={{ color: 'red' }}>Telefone inválido</small>}
                        </div>
                    </div>

                    <div style={{ padding: '20px 10px', border: '1px solid lightgray', borderRadius: 5, marginTop: 20 }}>
                        <div>
                            <label>Endereço completo</label><br />
                            <div>
                                <span className="p-input-icon-left" style={{ width: '100%' }}>
                                    {!this.state.pesquisaVisivel && this.state.endereco.logradouro ?
                                        <i className="pi pi-check" />
                                        :
                                        <i className="pi pi-search" />
                                    }
                                    <InputText
                                        style={{ width: '100%', borderColor: this.state.inputError.endereco && 'red' }}
                                        onChange={pEvento => {
                                            this.setState({
                                                conteudo: pEvento.target.value,
                                                pesquisaVisivel: true
                                            });
                                            this.listarEndereco(pEvento.target.value)
                                        }}
                                        value={this.state.conteudo || ""} />
                                </span>
                                {this.state.inputError.endereco && <small style={{ color: 'red' }}>Endereço inválido</small>}
                            </div>

                            {this.state.listaEndereco.map(pEndereco =>
                                <Button
                                    label={pEndereco.description}
                                    className="p-button-link"
                                    style={{ width: "100%", textAlign: 'left' }}
                                    onClick={() => this.buscarEndereco(pEndereco)} />
                            )}
                        </div>

                        <div style={{ marginTop: 20 }}>
                            <label>Complemento</label><br />
                            <InputText
                                onChange={pEvento => this.setState({
                                    endereco: {
                                        ...this.state.endereco,
                                        complemento: pEvento.target.value
                                    }
                                })}
                                value={this.state.endereco.complemento || ""} />
                        </div>
                    </div>
                </div>
            </main>
        </div>
    }
}