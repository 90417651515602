import { Component } from "react";
import { Navigate } from "react-router-dom";
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';

import LogoPreta from '../../assets/images/logoPreta.png'


import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Constante from "../../Util/constante";
import Util from "../../Util/util";

export default class HeaderEmpresa extends Component {

    constante = new Constante()
    util = new Util()

    state = {
        liberarAcesso: {
            login: false,
            projeto: false,
            detalheProjetoEmpresa: false,
            mensalidade: false,
            perfilEmpresa: false,
            financeiro: false,

        },
        dropdownFinanceiroVisivel: false,
        dropdownOrdemDeServicoVisivel: false,

        urlBase: '',
        pessoaUsuario: new Pessoa()
    }


    componentDidMount() {
        this.iniciar()
    }

    async iniciar() {
        const urlBase = await this.util.buscarUrlBaseArquivo();
        const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        this.setState({
            urlBase: urlBase,
            pessoaUsuario: pessoaUsuario,
        })
    }

    navegar(pTela) {
        const url_path = window.location.pathname.split("/")[2];
        console.log(pTela, url_path)
        if (pTela === url_path) return;
        const liberarAcesso = this.state.liberarAcesso;
        liberarAcesso[pTela] = true;
        this.setState({ liberarAcesso: liberarAcesso });
    }

    sair() {
        this.util.removerLocalStorage('PESSOA_USUARIO');
        this.navegar('login');
    }

    render() {
        return <>
            {this.state.liberarAcesso.projeto && <Navigate to="/empresa/projeto" />}
            {this.state.liberarAcesso.detalheProjetoEmpresa && <Navigate to="/empresa/detalhe_projeto" />}
            {this.state.liberarAcesso.mensalidade && <Navigate to="/empresa/mensalidade" />}
            {this.state.liberarAcesso.financeiro && <Navigate to="/empresa/financeiro" />}
            {this.state.liberarAcesso.perfilEmpresa && <Navigate to="/empresa/perfilEmpresa" />}
            {this.state.liberarAcesso.login && <Navigate to="/login" />}

            {/* COMPONENTE */}
            <header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'var(--primary-color)', padding: 10 }}>
                <img src={LogoPreta} alt="logo" style={{ height: 50 }} />

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                    <nav style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 5 }}>
                        <Button label="Projetos" onClick={() => this.navegar("projeto")} />
                        <Button label="Financeiro" onClick={() => this.navegar("financeiro")} />
                        <Button label="Mensalidade" onClick={() => this.navegar("mensalidade")} />
                        
                        <Button style={{ padding: 0 }} label={
                            <Avatar label={String(this.state.pessoaUsuario.nome).charAt(0)} image={(this.state.urlBase && this.state.pessoaUsuario.foto) ? this.state.urlBase + this.state.pessoaUsuario.foto : null} shape="circle" style={{ objectFit: 'contain' }}></Avatar>
                        } onClick={() => this.navegar("perfilEmpresa")} />
                        <Button label="Sair" icon={<i className="pi pi-sign-out"></i>} iconPos="right" style={{ gap: 5 }} onClick={() => this.sair()} />
                    </nav>
                </div>
            </header>
        </>
    }
}