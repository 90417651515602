import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import HeaderAdm from "../Administrativo/HeaderAdm";
import EmpresaDetalhe from "./EmpresaDetalhe";

import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import Constante from "../../Util/constante";
import Util from "../../Util/util";

export default class Empresa extends Component {
  constante = new Constante();
  util = new Util();
  pessoaService = new PessoaService(this.constante.token, this.constante.url_base);

  state = {
    urlBase: '',
    pessoaUsuario: new Pessoa(),
    pessoa: new Pessoa(),
    listaPessoa: [],
    conteudo: "",
    tela: "1",
  }

  componentDidMount() {
    this.iniciar();
  }

  async iniciar() {
    const pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
    const urlBase = await this.util.buscarUrlBaseArquivo();

    this.listar();

    this.setState({
      pessoaUsuario: pessoaUsuario,
      urlBase: urlBase,
    });
  }

  async listar() {
    const listaPesquisa = [];

    let pesquisa = new Pesquisa();
    pesquisa.campo = "pessoa.seq_usuario";
    pesquisa.conteudo = this.constante.seqUsuario;
    pesquisa.operacao = "";
    pesquisa.tipo = "TEXTO";

    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = "pessoa.seq_pessoa_perfil";
    pesquisa.conteudo = this.constante.seqPessoaPerfilEmpresa;
    pesquisa.operacao = "AND";
    pesquisa.tipo = "TEXTO";

    listaPesquisa.push(pesquisa);

    pesquisa = new Pesquisa();
    pesquisa.campo = "pessoa.nome";
    pesquisa.conteudo = this.state.conteudo;
    pesquisa.operacao = "AND";
    pesquisa.tipo = "TEXTO";

    listaPesquisa.push(pesquisa);

    const retornoListaPessoa = await this.pessoaService.listarComFiltro(listaPesquisa);
    const listaPessoa = retornoListaPessoa.objeto;

    // listaPessoa.forEach(async pPessoa => {
    //   pPessoa.seqPessoaPerfil = "1a8f0399-c55b-4cd9-adcf-3c9aa1df4d09"
    //   await this.pessoaService.salvar(pPessoa)
    //   console.log(pPessoa);
    // });

    this.setState({ listaPessoa: listaPessoa });
  }

  render() {
    return <div>
      <HeaderAdm />

      {this.state.tela === "2" ?
        <EmpresaDetalhe
          listar={() => this.listar()}
          setState={pState => this.setState(pState)}
          pessoa={this.state.pessoa} />
        :
        <main style={{ width: '98%', margin: '0 auto', marginTop: 20 }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '20px 10px', border: '1px solid lightgray', borderRadius: 5 }}>
            <div>
              <h1 style={{ fontSize: 22.5 }}>Empresa</h1>
              <h2 style={{ fontSize: 20, color: 'gray' }}>Empresa</h2>
            </div>


            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, }}>
              <InputText
                placeholder="Pesquisar"
                onChange={pEvento => this.setState({ conteudo: pEvento.target.value })}
                value={this.state.conteudo} />
              <Button
                label="Buscar"
                onClick={() => this.listar()}
                className="p-button-raised" />
              <Button
                label="Novo"
                onClick={() => this.setState({
                  tela: "2",
                })}
                className="p-button-raised" />
            </div>
          </div>

          <DataTable
            style={{ marginTop: 20 }}
            selectionMode="single"
            showGridlines
            removableSort
            value={this.state.listaPessoa}
            onSelectionChange={pEvento => {
              this.setState({
                pessoa: pEvento.value,
                tela: "2",
              })
            }}
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}>
            <Column header="Foto" body={pPessoa =>
              <div style={{ width: 100, height: 100, backgroundColor: "lightgray", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {pPessoa.foto ?
                  <img
                    src={this.state.urlBase + pPessoa.foto}
                    style={{ width: 100, height: 100, objectFit: 'contain' }} />
                  :
                  <p>sem imagem</p>
                }
              </div>
            }></Column>
            <Column field="nome" header="Nome" sortable></Column>
            <Column field="emailPlataformaLirida" header="Email" sortable></Column>
            <Column field="situacao" header="Situação" sortable></Column>
          </DataTable>
        </main>
      }
    </div>
  }
}