import { Component } from "react";
import footerLogo from '../../assets/images/logoBranca.png';

export default class FooterHotsite extends Component {
    render() {
        return <>
            <div className="h-footer">
                <footer className="h-footer-container">
                    <section>
                        <div>
                            <a href="/#sobre">Sobre</a>
                            <a href="/#funcionalidades">Funcionalidades</a>
                            <a href="/#depoimentos">Depoimentos</a>
                            <a href="/#plataformas">Plataformas</a>
                        </div>
                        <div>
                            <a href="#" onClick={pEvento => { pEvento.preventDefault(); window.location.href = "/politica_de_privacidade" }}>Política de privacidade</a>
                            <a href="#" onClick={pEvento => { pEvento.preventDefault(); window.location.href = "/termos_de_uso" }}>Termos de uso</a>
                        </div>
                        <div>
                            <a href="https://www.facebook.com/profile.php?id=100085493872487" target='_blank'>Facebook</a>
                            <a href="https://www.instagram.com/onax.projetos/" target="_blank">Instagram</a>
                        </div>
                    </section>
                    <img src={footerLogo} alt="logo rodapé" />
                </footer>
            </div>
        </>
    }
}