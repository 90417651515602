import { Component } from "react";
import Constante from "../../Util/constante";
import Util from "../../Util/util";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import FinanceiroService from "lirida-back-service/Servicos/Financeiro/FinanceiroService";
import Cobranca from "lirida-back-service/Servicos/Cobranca/Cobranca";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import Pessoa from "lirida-back-service/Servicos/Pessoa/Pessoa";
import Financeiro from "lirida-back-service/Servicos/Financeiro/Financeiro";
import QRCode from "react-qr-code";
import logo from '../../assets/images/logoPreta.png';
import PessoaService from "lirida-back-service/Servicos/Pessoa/PessoaService";
import DocumentoService from "lirida-back-service/Servicos/Documento/DocumentoService";
import Documento from "lirida-back-service/Servicos/Documento/Documento"

export default class CobrancaPg extends Component {

    constante = new Constante();
    util = new Util();
    financeiroService = new FinanceiroService(this.constante.token, this.constante.url_base);
    pessoService = new PessoaService(this.constante.token, this.constante.url_base);
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base);

    state = {
        pessoaUsuario: new Pessoa(),
        cobranca: new Cobranca(),
        financeiro: new Financeiro(),
        documento: new Documento(),
        listaCobrancaFinanceiro: [],
        valorCobranca: 0,
        listaPessoaRecebedor: []
    }

    componentDidMount() {
        this.iniciar();
    }

    async iniciar() {
        const parametros = this.util.buscarParametrosUrl();
        let retornoCobranca = await this.financeiroService.buscarCobrancaPorSeq(this.constante.seqUsuario, parametros.id)
        let cobranca = retornoCobranca.objeto;
        console.log("cobranca", cobranca);

        let retornoPessoaUsuario = await this.pessoService.buscarPorSeqPessoa(cobranca.seqPessoaPagador)
        let pessoaUsuario = retornoPessoaUsuario.objeto
        console.log("pessoa", pessoaUsuario);

        let retornoMovimentoFinanceiro = await this.financeiroService.listarFinanceiroPorSeqCobranca(cobranca._seqCobranca)
        let listaMovimentoFinanceiro = retornoMovimentoFinanceiro.objeto

        let retorno = await this.financeiroService.buscarPorSeq(this.constante.seqUsuario, listaMovimentoFinanceiro[0].seqMovimentoFinanceiro)
        let financeiro = retorno.objeto
        console.log("financeiro", financeiro);

        let listaPessoaRecebedor = await this.buscarDocumentoItem(financeiro)

        let retornoDocumento = await this.documentoService.buscar(this.constante.seqUsuario, financeiro.seqDocumento)
        let documento = retornoDocumento.objeto
        console.log("documento", documento);

        this.setState({
            pessoaUsuario: pessoaUsuario,
            cobranca: cobranca,
            financeiro: financeiro,
            listaPessoaRecebedor: listaPessoaRecebedor,
            documento: documento

        })

    }

    async buscarDocumentoItem(pFinanceiro) {
        let financeiro = pFinanceiro
        let listaPesquisa = [];

        let pesquisa = new Pesquisa();
        pesquisa.campo = "documento_item.seq_usuario";
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = "";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "documento_item.seq_documento";
        pesquisa.conteudo = financeiro.seqDocumento;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);

        pesquisa = new Pesquisa();
        pesquisa.campo = "documento_item_relacionamento.seq_tipo_relacionamento";
        pesquisa.conteudo = this.constante.seqTipoRelacionamentoCandidatoAprovado;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa);


        let listaDocumentoItemRelacionamento = await this.documentoService.listarItemComFiltro(listaPesquisa);
        return listaDocumentoItemRelacionamento.objeto

    }

    render() {
        return <div>

            <main style={{ width: "95%", margin: '0 auto', padding: 20 }}>
                <img
                    style={{ height: 50, objectFit: 'contain', }}
                    src={logo} />

                <div style={{ width: "100%", padding: '20px 10px', border: '1px solid lightgray', borderRadius: 5, position: 'relative', marginTop: 20 }}>
                    <QRCode
                        value={String(this.state?.cobranca?.codigoPix)}
                        size={100}
                        style={{ position: 'absolute', right: 10, top: 10, backgroundColor: 'white', }} />

                    <h1 style={{ fontSize: 20 }}>Detalhe da Cobrança</h1>

                    <h2 style={{ fontSize: 16, marginTop: 10 }}>Dados do pagador</h2>
                    <hr></hr>
                    <div style={{ width: "100%", display: "flex", gap: 20, marginTop: 10 }}>
                        <div>
                            <p style={{ fontWeight: '500', }}>Nome</p>
                            <p style={{ marginTop: 5, }}>{this.state?.pessoaUsuario?.nome}</p>
                        </div>
                        <div>
                            <p style={{ fontWeight: '500', }}>CNPJ</p>
                            <p style={{ marginTop: 5, }}>{this.state?.pessoaUsuario?.documento}</p>
                        </div>
                    </div>

                    <h2 style={{ fontSize: 16, marginTop: 10 }}>Dados da cobrança</h2>
                    <hr></hr>
                    <div style={{ width: "100%", display: "grid", gridTemplateColumns: '1fr 1.5fr 1fr 1fr', gap: 10, marginTop: 10 }}>
                        <div>
                            <p style={{ fontWeight: '500' }}>Data de geração</p>
                            <p style={{ marginTop: 5 }}>{this.util.formatarData(this.state?.cobranca?.dataCadastro)}</p>
                        </div>
                        <div>
                            <p style={{ fontWeight: '500' }}>Data de pagamento</p>
                            <p style={{ marginTop: 5 }}>{this.util.formatarData(this.state?.financeiro?.dataProgramado)}</p>
                        </div>

                        <div>
                            <p style={{ fontWeight: '500' }}>Valor do projeto</p>
                            <p style={{ marginTop: 5 }}>{this.util.formatarValor(this.state.documento.valorTotal)}</p>
                        </div>

                        <div>
                            <p style={{ fontWeight: '500' }}>Taxa transf. bancária</p>
                            <p style={{ marginTop: 5 }}>{this.util.formatarValor(this.state?.financeiro?._pessoaRecebedorTaxaPIX)}</p>
                        </div>
                        <div>
                            <p style={{ fontWeight: '500' }}>Valor total à pagar</p>
                            <p style={{ marginTop: 5 }}>{this.util.formatarValor(this.state?.cobranca?.valor)}</p>
                        </div>
                    </div>
                </div>

                <DataTable
                    showGridlines
                    style={{ width: "100%", marginTop: 20 }}
                    value={this.state.listaPessoaRecebedor}>
                    <Column
                        header="Nome"
                        body={(item) => <p>{item._pessoaRelacionadaNome}</p>} />
                    <Column
                        header="CNAE"
                        field=""
                        body={item => <p>{item._itemDescricaoVitrine}</p>} />
                    <Column
                        field="valor"
                        header="Valor"
                        body={item => <p style={{ textAlign: 'right' }}>{this.util.formatarValor(item.precoUnitario)}</p>} />
                </DataTable>
            </main >
        </div >
    }
}