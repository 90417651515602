import { Component, createRef } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { TabView, TabPanel } from 'primereact/tabview';
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Navigate } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";
import { Menu } from 'primereact/menu';
import HeaderEmpresa from "./headerEmpresa";

// SERVICES E OBJETOS
import Documento from "lirida-back-service/Servicos/Documento/Documento";
import DocumentoItem from 'lirida-back-service/Servicos/Documento/DocumentoItem';
import DocumentoService from 'lirida-back-service/Servicos/Documento/DocumentoService';
import Pessoa from 'lirida-back-service/Servicos/Pessoa/Pessoa';
import PessoaService from 'lirida-back-service/Servicos/Pessoa/PessoaService';
import CepService from "lirida-back-service/Servicos/Cep/CepService";
import Endereco from "lirida-back-service/Servicos/Endereco/Endereco";
import DepartamentoService from "lirida-back-service/Servicos/Departamento/DepartamentoService";
import ItemService from "lirida-back-service/Servicos/Item/ItemService"
import TabelaPrecoService from "lirida-back-service/Servicos/TabelaPreco/TabelaPrecoService";
import FinanceiroService from 'lirida-back-service/Servicos/Financeiro/FinanceiroService';
import DocumentoItemRelacionamento from "lirida-back-service/Servicos/DocumentoItemRelacionamento/DocumentoItemRelacionamento";
import Financeiro from "lirida-back-service/Servicos/Financeiro/Financeiro"
import Util from '../../Util/util';
import Constante from "../../Util/constante";
import Pesquisa from "lirida-back-service/Servicos/Pesquisa/Pesquisa";
import { Steps } from "primereact/steps";

export default class DetalheProjeto extends Component {
    menu = createRef();
    toast = createRef();
    mensagens = createRef();
    util = new Util()
    constante = new Constante()
    pessoaService = new PessoaService(this.constante.token, this.constante.url_base)
    documentoService = new DocumentoService(this.constante.token, this.constante.url_base)
    cepService = new CepService(this.constante.token, this.constante.url_base);
    departamentoService = new DepartamentoService(this.constante.token, this.constante.url_base)
    itemService = new ItemService(this.constante.token, this.constante.url_base)
    financeiroService = new FinanceiroService(this.constante.token, this.constante.url_base);
    tabelaPrecoService = new TabelaPrecoService(this.constante.token, this.constante.url_base)


    state = {
        pessoaUsuario: new Pessoa(),
        documento: new Documento(),
        endereco: new Endereco(),
        documentoItem: new DocumentoItem(),
        documentoItemRelacionamento: new DocumentoItemRelacionamento(),
        item: null,
        itemPreco: null,
        quantidadePessoas: null,
        valorTotal: 0,
        totalPessoas: 0,
        url_baseArquivo: '',
        seqDocumentoItem: '',
        avaliacao: '',
        indexStep: 0,

        //LISTAS
        listaItem: [],
        listaDocumentoItem: [],
        listaRelacionamentoDocumento: [],
        listaMovimentoFinanceiro: [],
        listaRelacionamentoDocumentoItem: [],
        listaArquivo: [],
        listaPessoasAprovadas: [],
        listaServicos: [],
        selecionados: [],
        listaCandidatoSelecionado: [],
        listaCandidatoAprovado: [],
        listaCandidatoReprovado: [],

        salvarCarregando: false,
        deletarCarregando: false,

        //MODAL
        vModalDeletar: false,
        vModalDeletarItem: false,
        vModalSalvar: false,
        vModalAlertaSemItem: false,
        vModalPropostas: false,
        vModalArquivos: false,
        vModalFecharVagas: false,
        vModalAprovarProposta: false,

        //INPUTS ERROR
        inputDadosError: {
            nomeProjeto: '',
            dataProgramada: '',
            dataInicio: '',
            dataFinal: '',
            observacao: '',
            cepEmpresa: false,
            logradouroEmpresa: false,
            numeroEmpresa: false,
            bairroEmpresa: false,
            cidadaEmpresa: false,
            estadoEmpresa: false,
            ufEmpresa: false,

            item: false,
            quantidade: false,
            preco: false,
            limiteProposta: false
        },


        itens1: [
            {
                label: 'Opções',
                items: [
                    {
                        label: 'Aprovar',
                        icon: 'pi pi-check-circle',
                        command: () => {
                            if (!this.state.selecionados.length) {
                                this.toast.current.show([
                                    {
                                        severity: 'warn', summary: 'Nenhum candidato selecionado',
                                        detail: 'Por favor, selecione um candidato para continuar a operação', sticky: false
                                    }])
                            } else {
                                this.aprovarCandidato()
                            }

                        }
                    },
                    {
                        label: 'Selecionar',
                        icon: ' pi pi-user-plus',
                        command: () => {
                            if (!this.state.selecionados.length) {
                                this.toast.current.show([
                                    {
                                        severity: 'warn', summary: 'Nenhum candidato selecionado',
                                        detail: 'Por favor, selecione um candidato para continuar a operação', sticky: false
                                    }])

                            } else {
                                this.selecionarCandidato()
                            }
                        }
                    },
                    {
                        label: 'Reprovar',
                        icon: ' pi pi-times-circle',
                        command: () => {
                            if (!this.state.selecionados.length) {
                                this.toast.current.show([
                                    {
                                        severity: 'warn', summary: 'Nenhum candidato selecionado',
                                        detail: 'Por favor, selecione um candidato para continuar a operação', sticky: false
                                    }])
                            } else {
                                this.reprovarCandidato()
                            }
                        }
                    },

                ]
            },
        ],


        itens2: [
            {
                label: 'Opções',
                items: [
                    {
                        label: 'Aprovar',
                        icon: 'pi pi-check-circle',
                        command: () => {
                            if (!this.state.selecionados.length) {
                                this.toast.current.show([
                                    {
                                        severity: 'warn', summary: 'Nenhum candidato selecionado',
                                        detail: 'Por favor, selecione um candidato para continuar a operação', sticky: false
                                    }])
                            } else {
                                this.aprovarCandidato()
                            }

                        }
                    },
                    {
                        label: 'Reprovar',
                        icon: 'pi pi-times-circle',
                        command: () => {
                            if (!this.state.selecionados.length) {
                                this.toast.current.show([
                                    {
                                        severity: 'warn', summary: 'Nenhum candidato selecionado',
                                        detail: 'Por favor, selecione um candidato para continuar a operação', sticky: false
                                    }])
                            } else {
                                this.reprovarCandidato()
                            }
                        }
                    },
                ]
            },
        ],


        steps: [{
            label: 'Candidatos',
            command: (event) => {
                this.setState({ indexStep: 0 })
                this.listarRelacionamentoDocumentoItem(this.state.documentoItem)

            }
        },
        {
            label: 'Selecionados',
            command: (event) => {
                this.setState({ indexStep: 1 })
                this.listarRelacionamentoSelecionado(this.state.documentoItem)

            }
        },
        {
            label: 'Aprovados',
            command: (event) => {
                this.setState({ indexStep: 2 })
                this.listarRelacionamentoAprovado(this.state.documentoItem)

            }
        },
        {
            label: 'Reprovados',
            command: (event) => {
                this.setState({ indexStep: 3 })
                this.listarRelacionamentoReprovado(this.state.documentoItem)
            }
        }
        ]
    }
    componentDidMount() {
        this.iniciar()
    }

    async iniciar() {
        this.util.traduzirCalendar();
        this.pessoaUsuario = await this.util.buscarLocalStorage("PESSOA_USUARIO");
        this.url_baseArquivo = await this.util.buscarUrlBaseArquivo();
        this.parametro = this.util.buscarParametrosUrl();

        if (!this.pessoaUsuario?._seqPessoa || this.pessoaUsuario?.seqPessoaPerfil !== this.constante.seqPessoaPerfilEmpresa) {
            window.history.back();
            return
        }

        this.documento = await this.buscarDocumento(this.parametro?.id);
        console.log(this.documento);
        this.listarItem()
        this.listaDocumentoItem = await this.listarDocumentoItem(this.documento);
        this.listaRelacionamentoDocumento = await this.listarRelacionamentoDocumento(this.documento);
        this.listaMovimentoFinanceiro = await this.listarMovimentoFianceiro(this.documento);

        this.setState({
            pessoaUsuario: this.pessoaUsuario,
            url_baseArquivo: this.url_baseArquivo,
            documento: this.documento,
            listaDocumentoItem: this.listaDocumentoItem,
            listaRelacionamentoDocumento: this.listaRelacionamentoDocumento,
            listaMovimentoFinanceiro: this.listaMovimentoFinanceiro
        })
    }

    //LISTAR
    async buscarDocumento(pSeqDocumento) {
        let listaPesquisa = []

        let pesquisa = new Pesquisa();
        pesquisa.campo = "documento.seq_usuario";
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = "";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa)

        pesquisa = new Pesquisa();
        pesquisa.campo = "documento.seq_documento";
        pesquisa.conteudo = pSeqDocumento;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa)

        this.retorno = await this.documentoService.listarComFiltro(listaPesquisa);
        return this.retorno.objeto[0]
    }

    async buscarCep(pCep) {
        this.setState({ salvarCarregando: true })
        const documento = this.state.documento;
        const retorno = await this.cepService.buscarCep(pCep);

        documento.tags2 = retorno.logradouro;
        documento.tags4 = retorno.complemento;
        documento.tags5 = retorno.bairro;
        documento.tags6 = retorno.localidade;
        documento.tags7 = retorno.estado;
        documento.tags8 = retorno.uf;

        this.setState({ documento: documento, salvarCarregando: false });
    }

    async listarItem() {
        this.retornoListaItem = await this.tabelaPrecoService.listarItem(this.constante.seqTabelaPrecoPrincipal, this.constante.seqCategoriaItem, "", "")
        this.listaItem = this.retornoListaItem.objeto
        this.setState({ listaItem: this.listaItem })
    }

    async listarDocumentoItem(pDocumento) {

        if (pDocumento) {
            this.documento = pDocumento
        } else {
            this.documento = this.state.documento;
        }

        this.retornoLista = await this.documentoService.listarDocumentoItem(this.documento);
        this.listaDocumentoItem = this.retornoLista.objeto;
        return this.listaDocumentoItem;
    }

    async listarRelacionamentoDocumento(pDocumento) {
        this.retorno = await this.documentoService.listarRelacionamento(pDocumento);
        return this.retorno.objeto
    }

    async listarMovimentoFianceiro(pDocumento) {
        let listaPesquisa = [];

        if (pDocumento) {
            this.documento = pDocumento;
        } else {
            this.documento = this.state.documento
        }

        let pesquisa = new Pesquisa();
        pesquisa.campo = "pessoa.seq_usuario";
        pesquisa.conteudo = this.constante.seqUsuario;
        pesquisa.operacao = "";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa)

        pesquisa = new Pesquisa();
        pesquisa.campo = "movimento_financeiro.seq_documento";
        pesquisa.conteudo = this.documento._seqDocumento;
        pesquisa.operacao = "AND";
        pesquisa.tipo = "TEXTO";
        listaPesquisa.push(pesquisa)

        this.retorno = await this.financeiroService.listarComFiltro(listaPesquisa);

        return this.retorno.objeto
    }

    //LISTAR CANDIDATO 
    async listarRelacionamentoDocumentoItem(item) {
        let listaProposta = []
        let documentoItem = item
        let retornoRelacionamentoItem = await this.documentoService.listarRelacionamentoItem(documentoItem)
        let listaRelacionamentoDocumentoItem = retornoRelacionamentoItem.objeto

        for (let i = 0; i < listaRelacionamentoDocumentoItem.length; i++) {

            if (listaRelacionamentoDocumentoItem[i].seqTipoRelacionamento === this.constante.seqTipoRelacionamentoProposta) {

                listaProposta.push(listaRelacionamentoDocumentoItem[i])

            }
            this.setState({
                listaRelacionamentoDocumentoItem: listaProposta,
                documentoItem: documentoItem,
            })

        }
    }

    //LISTAR CANDIDATO SELECIONADO
    async listarRelacionamentoSelecionado(item) {
        let listaCandidatoSelecionado = []

        let documentoItem = item
        let retornoRelacionamentoItem = await this.documentoService.listarRelacionamentoItem(documentoItem)
        let listaRelacionamentoDocumentoItem = retornoRelacionamentoItem.objeto

        for (let i = 0; i < listaRelacionamentoDocumentoItem.length; i++) {

            if (listaRelacionamentoDocumentoItem[i].seqTipoRelacionamento === this.constante.seqTipoRelacionamentoCandidatoSelecionado) {

                listaCandidatoSelecionado.push(listaRelacionamentoDocumentoItem[i])

            }
        }
        this.setState({
            listaCandidatoSelecionado: listaCandidatoSelecionado,
            documentoItem: documentoItem
        })

    }

    //LISTAR CANDIDATO APROVADO
    async listarRelacionamentoAprovado(item) {
        let listaCandidatoAprovado = []

        let documentoItem = item
        let retornoRelacionamentoItem = await this.documentoService.listarRelacionamentoItem(documentoItem)
        let listaRelacionamentoDocumentoItem = retornoRelacionamentoItem.objeto

        for (let i = 0; i < listaRelacionamentoDocumentoItem.length; i++) {

            if (listaRelacionamentoDocumentoItem[i].seqTipoRelacionamento === this.constante.seqTipoRelacionamentoCandidatoAprovado) {

                listaCandidatoAprovado.push(listaRelacionamentoDocumentoItem[i])

            }
            this.setState({
                listaCandidatoAprovado: listaCandidatoAprovado,
                documentoItem: documentoItem
            })
        }


    }

    //LISTAR CANDIDATO REPROVADO
    async listarRelacionamentoReprovado(item) {
        let listaCandidatoReprovado = []

        let documentoItem = item
        let retornoRelacionamentoItem = await this.documentoService.listarRelacionamentoItem(documentoItem)
        let listaRelacionamentoDocumentoItem = retornoRelacionamentoItem.objeto

        for (let i = 0; i < listaRelacionamentoDocumentoItem.length; i++) {

            if (listaRelacionamentoDocumentoItem[i].seqTipoRelacionamento === this.constante.seqTipoRelacionamentoCandidatoReprovado) {

                listaCandidatoReprovado.push(listaRelacionamentoDocumentoItem[i])

            }
            this.setState({ listaCandidatoReprovado: listaCandidatoReprovado, documentoItem: documentoItem })
        }


    }

    async listarArquivo(item) {
        this.setState({ vModalArquivos: true })
        let listaPesquisa = [];

        this.documentoItemRelacionamento = item
        this.retornoPessoa = await this.pessoaService.buscarPorSeqPessoa(this.documentoItemRelacionamento.seqPessoaRelacionada, this.constante.seqUsuario)
        this.pessoa = this.retornoPessoa.objeto

        this.retornoarquivo = await this.pessoaService.listarArquivo(this.pessoa)
        this.listaArquivo = this.retornoarquivo.objeto

        //listando historico de serviços

        // let pesquisa = new Pesquisa();
        // pesquisa.campo = "documento_item.seq_usuario";
        // pesquisa.conteudo = this.constante.seqUsuario;
        // pesquisa.operacao = "";
        // pesquisa.tipo = "TEXTO";
        // listaPesquisa.push(pesquisa)

        // pesquisa = new Pesquisa();
        // pesquisa.campo = "documento_item_relacionamento.seq_pessoa_relacionada";
        // pesquisa.conteudo = this.pessoa._seqPessoa;
        // pesquisa.operacao = "AND";
        // pesquisa.tipo = "TEXTO";
        // listaPesquisa.push(pesquisa)

        // pesquisa = new Pesquisa();
        // pesquisa.campo = "documento_item_relacionamento.seq_tipo_relacionamento";
        // pesquisa.conteudo = this.constante.seqTipoRelacionamentoCandidatoAprovado;
        // pesquisa.operacao = "AND";
        // pesquisa.tipo = "TEXTO";
        // listaPesquisa.push(pesquisa)

        this.lista = [
            this.util.criarObjetoPesquisa(
                'documento_item.seq_usuario',
                this.constante.seqUsuario,
                '',
                'TEXTO'
            ),
            this.util.criarObjetoPesquisa(
                "documento_item_relacionamento.seq_pessoa_relacionada",
                this.pessoa._seqPessoa,
                "AND",
                "TEXTO"
            ),

            this.util.criarObjetoPesquisa(
                'documento_item_relacionamento.seq_tipo_relacionamento',
                this.constante.seqTipoRelacionamentoCandidatoAprovado,
                'AND',
                'TEXTO'
            )
        ]
        console.log(this.lista);
        this.retornoListaServicos = await this.documentoService.listarItemComFiltro(this.lista)
        this.listaServicos = this.retornoListaServicos.objeto
        console.log(this.listaServicos);

        this.setState({ listaArquivo: this.listaArquivo, listaServicos: this.listaServicos, documentoItemRelacionamento: this.documentoItemRelacionamento })
    }


    //SALVAR
    async salvar() {
        this.retorno = this.validarInputs();
        if (this.retorno) {

            this.setState({ salvarCarregando: true })
            this.documento = this.state.documento
            this.retornoDocumento = await this.documentoService.salvar(this.documento)

            this.mensagens.current.show([
                { severity: 'success', summary: 'Projeto atualizado com sucesso!' }]);

            this.setState({
                salvarCarregando: false,
                documento: this.retornoDocumento.objeto
            })
        }
    }

    async salvarItem() {
        this.retorno = this.validarInputItens()

        if (this.retorno) {
            this.setState({ salvarCarregando: true })

            this.documento = this.state.documento;
            this.documentoItem = this.state.documentoItem;
            this.itemPreco = this.state.itemPreco;
            this.quantidadePessoas = this.state.quantidadePessoas

            this.documentoItem.seqUsuario = this.constante.seqUsuario;
            this.documentoItem.seqDocumento = this.documento._seqDocumento;
            this.documentoItem.seqTipoDocumentoProcesso = this.documento.seqTipoDocumentoProcesso;
            this.documentoItem.status = this.documento.status
            this.documentoItem.seqItem = this.state.item.seqItem;
            this.documentoItem.dataProgramada = this.documento.dataProgramada;
            this.documentoItem.dataInicio = this.documento.dataInicio
            this.documentoItem.dataFinal = this.documento.dataFinal
            this.documentoItem.tagn1 = this.documento._codigo;
            this.documentoItem.tagn2 = 0;
            this.documentoItem.tagn3 = 0;
            this.documentoItem.tags0 = this.documento.tags0;
            this.retornoDocumentoItem = await this.documentoService.salvarItem(this.documentoItem);

            this.listaDocumentoItem = await this.listarDocumentoItem(this.documento);

            this.valorTotal = 0;

            for (let i = 0; i < this.listaDocumentoItem.length; i++) {
                this.valorTotal = this.valorTotal + this.listaDocumentoItem[i].valorTotal;
                console.log(this.valorTotal)
            }

            this.documento.valorTotal = this.valorTotal;
            this.retornoDocumento = await this.documentoService.salvar(this.documento);
            this.documento = this.retornoDocumento.objeto

            this.setState({
                documentoItem: new DocumentoItem(),
                listaDocumentoItem: this.listaDocumentoItem,
                documento: this.documento,
                quantidadePessoas: null,
                itemPreco: null,
                item: null,
                salvarCarregando: false
            })

            await this.listarDocumentoItem(this.documento);
        }
    }

    async gerarMovimentoFinanceiro(item) {

        this.listaDocumentoItem = this.state.listaDocumentoItem
        this.documento = this.state.documento
        this.documentoItem = this.state.documentoItem

        for (const documentoItem of this.listaDocumentoItem) {
            this.retornoListaRelacionamentoItem = await this.documentoService.listarRelacionamentoItem(documentoItem)
            this.listaRelacionamentoItem = this.retornoListaRelacionamentoItem.objeto

            for (let i = 0; i < this.listaRelacionamentoItem.length; i++) {

                if (this.listaRelacionamentoItem[i].seqTipoRelacionamento !== this.constante.seqTipoRelacionamentoCandidatoAprovado) {

                    let listaItemRelacionamento = this.listaRelacionamentoItem[i]
                    listaItemRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoCandidatoReprovado
                    listaItemRelacionamento.tags2 = "Reprovado"
                    let retorno = await this.documentoService.salvarRelacionamentoItem(listaItemRelacionamento)
                    listaItemRelacionamento = retorno.objeto
                }

                if (this.listaRelacionamentoItem[i].seqTipoRelacionamento === this.constante.seqTipoRelacionamentoCandidatoAprovado) {

                    this.financeiro = new Financeiro()
                    this.financeiro.seqUsuario = this.constante.seqUsuario;
                    this.financeiro.seqContaBancaria = this.constante.seqContaBancaria
                    this.financeiro.seqContaFinanceira = this.constante.seqContaFinanceiroPagamentoFornecedor
                    this.financeiro.seqPessoa = this.listaRelacionamentoItem[i].seqPessoaRelacionada
                    this.financeiro.valorProgramado = documentoItem.precoUnitario
                    this.financeiro.dataProgramado = documentoItem.dataProgramada
                    this.financeiro.seqFormaPagamento = this.constante.seqFormaPagamentoPix
                    this.financeiro.seqDocumento = documentoItem.seqDocumento
                    this.financeiro.seqDocumentoItem = documentoItem._seqDocumentoItem
                    this.financeiro.seqPessoaPagador = this.state.pessoaUsuario._seqPessoa
                    this.financeiro.seqPessoaRecebedor = this.listaRelacionamentoItem[i].seqPessoaRelacionada
                    this.financeiro.seqItem = documentoItem.seqItem
                    this.financeiro.seqTipoDocumento = this.constante.seqTipoDocumentoProjeto

                    this.financeiro.tags0 = documentoItem.tags0
                    this.financeiro.tags1 = this.state.pessoaUsuario.nome
                    this.financeiro.tags2 = ` ${this.state.documento.tags2}, ${this.state.documento.tags3} - ${this.state.documento.tags5}, ${this.state.documento.tags6} - ${this.state.documento.tags8}; ${this.state.documento.dataProgramada}`
                    this.financeiro.tags3 = `${this.state.documento.dataInicio};${this.state.documento.dataFinal}`
                    this.financeiro.tags4 = documentoItem._itemNomeVitrine
                    this.financeiro.tags5 = this.listaRelacionamentoItem[i]._pessoaRelacionadaNome

                    this.retornoFinancerio = await this.financeiroService.salvar(this.financeiro)
                    this.financeiro = this.retornoFinancerio.objeto
                    console.log("financeiro", this.financeiro);

                    this.documento.status = 1
                    this.documento.seqTipodocumentoProcesso = this.constante.seqTipoDocumentoProcessoAprovado
                    await this.documentoService.salvar(this.documento)

                    this.documentoItem.status = 1
                    this.documentoItem.seqTipodocumentoProcesso = this.constante.seqTipoDocumentoProcessoAprovado
                    await this.documentoService.salvarItem(this.documentoItem)


                }
            }
        }

        this.toast.current.show([
            { severity: 'success', summary: 'Vaga fechada com sucesso, foi gerado um financeiro!' },
        ]);

        this.setState({ listaDocumentoItem: this.listaDocumentoItem })
    }


    //ATUALIZAR
    pegarValorTotalItem(pvalor) {
        this.documentoItem = this.state.documentoItem
        this.documentoItem.precoUnitario = pvalor;
        this.documentoItem.valorTotal = this.documentoItem?.quantidade * this.documentoItem.precoUnitario
        this.setState({ documentoItem: this.documentoItem })
    }

    async enviarProjeto() {
        this.retorno = this.validarInputs();
        if (this.retorno) {
            this.documento = this.state.documento
            this.documentoItem = this.state.documentoItem
            this.setState({ salvarCarregando: true });

            if (this.listaDocumentoItem.length > 0) {
                this.documento.tags9 = 'S';
                this.documento.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoAnalise;
                this.documentoItem.seqTipoDocumentoProcesso = this.constante.seqTipoDocumentoProcessoAnalise;
                this.documentoItem.tagn3 = 0

            } else if (!this.listaDocumentoItem.length) {

                this.setState({ vModalAlertaSemItem: true, salvarCarregando: false })
                return;
            }

            this.retornoDocumento = await this.documentoService.salvar(this.documento);
            this.documento = this.retornoDocumento.objeto;

            this.retornoDocumentoItem = await this.documentoService.salvarItem(this.documentoItem)

            this.setState({
                salvarCarregando: false,
                documento: this.documento,
            });

            this.mensagens.current.show([
                { severity: 'success', summary: 'Projeto enviado com sucesso' },
            ]);
        }
    }

    // async aprovarProposta() {
    //     this.documentoItem = this.state.documentoItem
    //     this.listaSelecionados = this.state.selecionados

    //     if (this.listaSelecionados.length > this.documentoItem.quantidade) {
    //         this.toast.current.show([
    //             { severity: 'error', summary: 'Candidatos selecionados ultrapassaram a quantidade de vagas.' },
    //         ]);
    //         return;
    //     }

    //     if (this.listaSelecionados.length < this.documentoItem.quantidade) {
    //         this.toast.current.show([
    //             { severity: 'error', summary: 'Candidatos selecionados são inferior ao numero de vagas.' },
    //         ]);
    //         return;
    //     }

    //     for (let i = 0; i < this.listaSelecionados.length; i++) {

    //         console.log("lista aprovados", this.listaSelecionados[i]);
    //         this.documentoItemRelacionamento = this.listaSelecionados[i]
    //         this.documentoItemRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoCandidatoAprovado;
    //         this.documentoItemRelacionamento.tags2 = "Aprovado"
    //         this.retornoDocumentoItemRelacionamento = this.documentoService.salvarRelacionamentoItem(this.documentoItemRelacionamento)
    //         this.documentoItemRelacionamento = this.retornoDocumentoItemRelacionamento.objeto;

    //         this.documentoItem.tags1 = this.listaSelecionados.length;
    //         this.documentoItem.tags2 = "S"

    //         this.retornoDocumentoItem = await this.documentoService.salvarItem(this.documentoItem);
    //         this.documentoItem = this.retornoDocumentoItem.objeto

    //         console.log("salvou");
    //     }

    //     this.listaRelacionamentoItem = this.state.listaRelacionamentoDocumentoItem

    //     for (let i = 0; i < this.listaRelacionamentoItem.length; i++) {

    //         if (this.listaRelacionamentoItem[i].seqTipoRelacionamento !== this.constante.seqTipoRelacionamentoCandidatoAprovado) {

    //             console.log("lista Reprovado", this.listaRelacionamentoItem[i]);
    //             this.documentoItemRelacionamento = this.listaRelacionamentoItem[i]
    //             this.documentoItemRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoCandidatoReprovado;
    //             this.documentoItemRelacionamento.tags2 = "Reprovado"
    //             this.retornoDocumentoItemRelacionamento = this.documentoService.salvarRelacionamentoItem(this.documentoItemRelacionamento)
    //             this.documentoItemRelacionamento = this.retornoDocumentoItemRelacionamento.objeto;
    //         }
    //     }
    //     this.listarRelacionamentoDocumentoItem(this.documentoItem)
    //     this.setState({
    //         selecionados: [],
    //         documentoItem: this.documentoItem
    //     })

    // }

    async avaliacao(pParametro) {
        this.setState({ salvarCarregando: true })
        this.documentoItemRelacionamento = this.state.documentoItemRelacionamento
        this.documentoItemRelacionamento.tags3 = pParametro

        this.retornoDocumentoItemRelacionamento = await this.documentoService.salvarRelacionamentoItem(this.documentoItemRelacionamento)

        this.toast.current.show([
            { severity: 'success', summary: 'Profissional avaliado com sucesso!!' },
        ]);
        this.setState({ salvarCarregando: false })


    }

    validarInputItens() {
        this.inputDadosError = this.state.inputDadosError;
        this.listaKey = Object.keys(this.inputDadosError);

        for (let i = 0; i < this.listaKey.length; i++) {
            this.inputDadosError[this.listaKey[i]] = false
        }

        if (!this.state.item) {
            this.inputDadosError.item = true;
        }

        if (!this.state.documentoItem.quantidade) {
            this.inputDadosError.quantidade = true;
        }

        if (!this.state.documentoItem.precoUnitario) {
            this.inputDadosError.preco = true;
        }

        if (!this.state.documentoItem.tagn0) {
            this.inputDadosError.limiteProposta = true;
        }

        this.inputsOk = true;

        for (let i = 0; i < this.listaKey.length; i++) {
            if (this.inputDadosError[this.listaKey[i]]) {
                this.inputsOk = false
            }
        }

        this.setState({
            inputDadosError: this.inputDadosError
        })
        return this.inputsOk

    }

    validarInputs() {
        this.inputDadosError = this.state.inputDadosError;
        this.listaKey = Object.keys(this.inputDadosError);

        for (let i = 0; i < this.listaKey.length; i++) {
            this.inputDadosError[this.listaKey[i]] = false
        }
        if (!this.state.documento.tags0) {
            this.inputDadosError.nomeProjeto = true;
        }
        if (!this.state.documento.dataProgramada) {
            this.inputDadosError.dataProgramada = true;
        }
        if (!this.state.documento.dataInicio) {
            this.inputDadosError.dataInicio = true;
        }
        if (!this.state.documento.dataFinal) {
            this.inputDadosError.dataFinal = true;
        }
        if (!this.state.documento.observacao) {
            this.inputDadosError.observacao = true;
        }

        if (!this.state.documento.tags1) {
            this.inputDadosError.cepEmpresa = true;
        }

        if (!this.state.documento.tags2) {
            this.inputDadosError.logradouroEmpresa = true;
        }

        if (!this.state.documento.tags3) {
            this.inputDadosError.numeroEmpresa = true;
        }

        if (!this.state.documento.tags5) {
            this.inputDadosError.bairroEmpresa = true;
        }

        if (!this.state.documento.tags6) {
            this.inputDadosError.cidadaEmpresa = true;
        }

        if (!this.state.documento.tags7) {
            this.inputDadosError.estadoEmpresa = true;
        }

        if (!this.state.documento.tags8) {
            this.inputDadosError.ufEmpresa = true;
        }

        this.inputsOk = true;

        for (let i = 0; i < this.listaKey.length; i++) {
            if (this.inputDadosError[this.listaKey[i]]) {
                this.inputsOk = false
            }
        }

        this.setState({
            inputDadosError: this.inputDadosError
        })
        return this.inputsOk
    }

    async selecionarCandidato() {
        this.setState({ salvarCarregando: true })
        let listaSelecionados = this.state.selecionados
        let listaItemRelacionamento = []


        for (let i = 0; i < listaSelecionados.length; i++) {

            listaItemRelacionamento = listaSelecionados[i]
            listaItemRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoCandidatoSelecionado
            listaItemRelacionamento.tags2 = "Selecionado"
            let retorno = await this.documentoService.salvarRelacionamentoItem(listaItemRelacionamento)
            listaItemRelacionamento = retorno.objeto
        }

        this.listarRelacionamentoDocumentoItem(this.state.documentoItem)
        this.setState({
            selecionados: [],
            salvarCarregando: false
        })
    }

    async aprovarCandidato() {
        this.setState({ salvarCarregando: true })
        let documentoItem = this.state.documentoItem
        let listaSelecionados = this.state.selecionados
        let listaItemRelacionamento = []

        if (listaSelecionados.length > documentoItem.quantidade) {
            this.toast.current.show([
                {
                    severity: 'error', summary: 'Número excessívo de candidatos selecionado',
                    detail: 'Por favor, selecione um número de candidato correspondente a vaga', sticky: false
                }])
            this.setState({ salvarCarregando: false })
            return

        } else if (documentoItem.tagn3 >= documentoItem.quantidade) {

            this.toast.current.show([
                {
                    severity: 'error', summary: 'Vagas preenchidas',
                    detail: 'O número de cadidatos aprovados já foi preenchido', sticky: false
                }])
            this.setState({ salvarCarregando: false })
            return

        } else {


            for (let i = 0; i < listaSelecionados.length; i++) {

                listaItemRelacionamento = listaSelecionados[i]
                listaItemRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoCandidatoAprovado
                listaItemRelacionamento.tags2 = "Aprovado"
                let retorno = await this.documentoService.salvarRelacionamentoItem(listaItemRelacionamento)
                listaItemRelacionamento = retorno.objeto
            }

            documentoItem.tagn3 = documentoItem.tagn3 + listaSelecionados.length
            let retornoDocumentoItem = await this.documentoService.salvarItem(documentoItem)
            documentoItem = retornoDocumentoItem.objeto
            console.log("documento item", documentoItem.tagn3);
            console.log("tagn3", documentoItem.tagn3);

            this.listarRelacionamentoDocumentoItem(this.state.documentoItem)
            this.listarRelacionamentoSelecionado(this.state.documentoItem)
            this.setState({
                selecionados: [],
                salvarCarregando: false
            })
        }

    }


    async reprovarCandidato() {
        this.setState({ salvarCarregando: true })
        let listaSelecionados = this.state.selecionados
        let listaItemRelacionamento = []

        for (let i = 0; i < listaSelecionados.length; i++) {

            listaItemRelacionamento = listaSelecionados[i]
            listaItemRelacionamento.seqTipoRelacionamento = this.constante.seqTipoRelacionamentoCandidatoReprovado
            listaItemRelacionamento.tags2 = "Reprovado"
            let retorno = await this.documentoService.salvarRelacionamentoItem(listaItemRelacionamento)
            listaItemRelacionamento = retorno.objeto
        }
        this.listarRelacionamentoDocumentoItem(this.state.documentoItem)
        this.listarRelacionamentoSelecionado(this.state.documentoItem)
        this.setState({
            selecionados: [],
            salvarCarregando: false
        })

    }


    //DELETAR
    async deletarItem(pSeqDocumentoItem) {
        this.documento = this.state.documento
        this.retorno = await this.documentoService.listarDocumentoItem(this.documento);
        this.listaDocumentoItem = this.retorno.objeto;
        for (let i = 0; i < this.listaDocumentoItem.length; i++) {
            if (this.listaDocumentoItem[i]._seqDocumentoItem === pSeqDocumentoItem) {
                this.documentoItem = this.listaDocumentoItem[i]
            }
        }

        this.documento.valorTotal = this.documento.valorTotal - this.documentoItem.precoTabela;
        this.documentoService.deletarItem(this.documentoItem);
        this.retorno = await this.documentoService.salvar(this.documento);
        this.documento = this.retorno.objeto;
        this.listaDocumentoItem = await this.listarDocumentoItem(this.documento);
        this.setState({ documento: this.documento, listaDocumentoItem: this.listaDocumentoItem })
    }

    render() {
        return <div>
            <Toast ref={this.toast} />

            {/* DELETAR */}
            <Dialog header="Alerta" visible={this.state.vModalDeletarItem} style={{ width: 400 }} onHide={() => this.setState({ vModalDeletarItem: false, })}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>
                    <p>Deseja realmente deletar?</p>
                    <div style={{ display: 'flex', gap: 10, justifyContent: "right" }}>
                        <Button label="Sim" onClick={() => {
                            this.deletarItem(this.state.seqDocumentoItem);
                            this.setState({ vModalDeletarItem: false, });
                        }} />
                        <Button label="Não" onClick={() => this.setState({ vModalDeletarItem: false, })} />
                    </div>
                </div>
            </Dialog>

            {/* ENVIAR PROJETO */}
            <Dialog header="Alerta" visible={this.state.vModalSalvar} style={{ width: 400 }} onHide={() => this.setState({ vModalSalvar: false, })}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>
                    <p>Após o envio o projeto não poderá mais ser alterado.<br /><br /><strong> Deseja realmente enviar o projeto?</strong></p>
                    <div style={{ display: 'flex', gap: 10, justifyContent: "right" }}>
                        <Button label="Sim" onClick={() => {
                            this.enviarProjeto();
                            this.setState({ vModalSalvar: false, });
                        }} />
                        <Button label="Não" onClick={() => this.setState({ vModalSalvar: false, })} />
                    </div>
                </div>
            </Dialog>

            {/* APROVAR PROPOSTA */}
            <Dialog header="Alerta" visible={this.state.vModalAprovarProposta} style={{ width: 400 }} onHide={() => this.setState({ vModalAprovarProposta: false, })}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>
                    <p>Apoś aprovar, não poderá ser feita modificação na lista de aprovados<br /><br /><strong>Deseja realmente aprovar Profisionais?</strong></p>
                    <div style={{ display: 'flex', gap: 10, justifyContent: "right" }}>
                        <Button label="Sim" onClick={() => {
                            this.aprovarProposta();
                            this.setState({ vModalAprovarProposta: false, });
                        }} />
                        <Button label="Não" onClick={() => this.setState({ vModalAprovarProposta: false, })} />
                    </div>
                </div>
            </Dialog>

            {/* FECHAR VAGAS */}
            <Dialog header="Alerta" visible={this.state.vModalFecharVagas} style={{ width: 400 }} onHide={() => this.setState({ vModalFecharVagas: false, })}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>
                    <p>Após fechamento das vagas será gerada uma cobrança referente ao pagamento dos candidatos aprovados.<br /><br /><strong> Deseja realmente fechar vagas?</strong></p>
                    <div style={{ display: 'flex', gap: 10, justifyContent: "right" }}>
                        <Button label="Sim" onClick={() => {
                            this.gerarMovimentoFinanceiro();
                            this.setState({ vModalFecharVagas: false, });
                        }} />
                        <Button label="Não" onClick={() => this.setState({ vModalFecharVagas: false, })} />
                    </div>
                </div>
            </Dialog>

            {/* ALERTA SEM ITENS */}
            <Dialog header="Alerta" visible={this.state.vModalAlertaSemItem} style={{ width: 400 }} onHide={() => this.setState({ vModalAlertaSemItem: false, })}>
                <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>
                    <p>Para enviar, o projeto deve conter os itens e a quantidade!!</p>
                    <div style={{ display: 'flex', gap: 10, justifyContent: "right" }}>
                        <Button label="Ok" onClick={() => this.setState({ vModalAlertaSemItem: false, })} />
                    </div>
                </div>
            </Dialog>

            {/* VISUALIZAR PROPOSTAS */}
            <Dialog header="Propostas" visible={this.state.vModalPropostas} style={{ width: 1200 }} onHide={() => this.setState({ vModalPropostas: false, indexStep: 0, selecionados: [] })}>
                <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                    <div style={{ display: "flex", flexDirection: "row", gap: 5 }} > <h4>Vaga:</h4> {this.state.documentoItem._itemNome}</div>
                    <div style={{ display: "flex", flexDirection: "row", gap: 5 }}> <h4>Quantidade:</h4>{this.state.documentoItem.quantidade}</div>
                    <div style={{ display: "flex", flexDirection: "row", gap: 5 }}> <h4>Limite de propostas:</h4>{this.state.documentoItem.tagn0}</div>
                </div>

                <Steps
                    style={{ marginTop: 10 }}
                    model={this.state.steps}
                    readOnly={false}
                    activeIndex={this.state.indexStep}
                />

                {this.state.indexStep === 0 && //CANDIDATOS
                    <>
                        <div style={{ display: "flex", flexDirection: 'column', gap: 20 }}>

                            <DataTable
                                style={{ marginTop: 20 }}
                                showGridlines
                                paginator
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                rows={10}
                                rowsPerPageOptions={[10, 20, 50]}
                                responsiveLayout='scroll'
                                removableSort
                                value={this.state.listaRelacionamentoDocumentoItem}
                                selection={this.state.selecionados}
                                onSelectionChange={item => this.setState({ selecionados: item.value })}
                                header={
                                   <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <Menu model={this.state.itens1} arial popup ref={el => this.menu = el} id="popup_menu" />
                                        <Button loading={this.state.salvarCarregando} label="Opções" icon="pi pi-bars" onClick={(event) => this.menu.toggle(event)} aria-controls="popup_menu" aria-haspopup />
                                    </div>
                                }>

                                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                                <Column
                                    header="Candidato"
                                    field="_pessoaRelacionadaNome"
                                    sortable />

                                <Column
                                    header="Email"
                                    field="tags0"
                                    sortable />

                                <Column
                                    header="Telefone"
                                    field="tags1"
                                    sortable />

                                <Column
                                    header="Relevância"
                                    field="tags1"
                                    body={<div style={{ textAlign: "center" }}>
                                        <span className="pi pi-thumbs-up"></span></div>}
                                    sortable />

                                <Column
                                    header="status"
                                    body={item => item.tags2 === "Analise" ? (
                                        <p style={{ color: "gray" }}>Analise</p>
                                    ) : item.tags2 === "Selecionado" ? (
                                        <p style={{ color: "blue" }}>Selecionado</p>
                                    ) : item.tags2 === "Aprovado" ? (
                                        <p style={{ color: "green" }}>Aprovado</p>
                                    ) : item.tags2 === "Reprovado" ? (
                                        <p style={{ color: "red" }}>Reprovado</p>
                                    ) : null} />

                                <Column
                                    header="Arquivos"
                                    field="_seqPessoa"
                                    alignHeader="center"
                                    body={item => <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                                        <Button
                                            onClick={() => window.open('candidato_detalhe?id=' + item.seqPessoaRelacionada)}
                                            icon="pi pi-eye"
                                            className="p-button-rounded p-button-sucess"
                                            aria-label="Filter" />
                                    </div>} />
                            </DataTable>

                        </div>
                    </>}

                {this.state.indexStep === 1 && //SELECIONADOS
                    <>
                        <DataTable
                            style={{ marginTop: 20 }}
                            showGridlines
                            paginator
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            responsiveLayout='scroll'
                            removableSort
                            value={this.state.listaCandidatoSelecionado}
                            selection={this.state.selecionados}
                            onSelectionChange={item => this.setState({ selecionados: item.value })}
                            header={
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Menu model={this.state.itens2} popup ref={el => this.menu = el} id="popup_menu" />
                                    <Button loading={this.state.salvarCarregando} label="Opções" icon="pi pi-bars" onClick={(event) => this.menu.toggle(event)} aria-controls="popup_menu" aria-haspopup />
                                </div>
                            }>

                            <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                            <Column
                                header="Candidato"
                                field="_pessoaRelacionadaNome"
                                sortable />

                            <Column
                                header="Email"
                                field="tags0"
                                sortable />

                            <Column
                                header="Telefone"
                                field="tags1"
                                sortable />

                            <Column
                                header="Relevância"
                                field="tags1"
                                body={<div style={{ textAlign: "center" }}>
                                    <span className="pi pi-thumbs-up"></span></div>}
                                sortable />

                            <Column
                                header="status"
                                body={item => item.tags2 === "Analise" ? (
                                    <p style={{ color: "gray" }}>Analise</p>
                                ) : item.tags2 === "Selecionado" ? (
                                    <p style={{ color: "blue" }}>Selecionado</p>
                                ) : item.tags2 === "Aprovado" ? (
                                    <p style={{ color: "green" }}>Aprovado</p>
                                ) : item.tags2 === "Reprovado" ? (
                                    <p style={{ color: "red" }}>Reprovado</p>
                                ) : null} />

                            <Column
                                header="Arquivos"
                                field="_seqPessoa"
                                alignHeader="center"
                                body={item => <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                                    <Button
                                        onClick={() => window.open('candidato_detalhe?id=' + item.seqPessoaRelacionada)}
                                        icon="pi pi-eye"
                                        className="p-button-rounded p-button-sucess"
                                        aria-label="Filter" />
                                </div>} />
                        </DataTable>


                    </>}

                {this.state.indexStep === 2 && //APROVADOS
                    <>

                        <DataTable
                            style={{ marginTop: 20 }}
                            showGridlines
                            paginator
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            responsiveLayout='scroll'
                            removableSort
                            value={this.state.listaCandidatoAprovado}
                            // selection={this.state.selecionados}
                            // onSelectionChange={item => this.setState({ selecionados: item.value })}
                            header={""}>

                            {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} /> */}
                            <Column
                                header="Candidato"
                                field="_pessoaRelacionadaNome"
                                sortable />

                            <Column
                                header="Email"
                                field="tags0"
                                sortable />

                            <Column
                                header="Telefone"
                                field="tags1"
                                sortable />

                            <Column
                                header="Relevância"
                                field="tags1"
                                body={<div style={{ textAlign: "center" }}>
                                    <span className="pi pi-thumbs-up"></span></div>}
                                sortable />

                            <Column
                                header="status"
                                body={item => item.tags2 === "Analise" ? (
                                    <p style={{ color: "gray" }}>Analise</p>
                                ) : item.tags2 === "Selecionado" ? (
                                    <p style={{ color: "blue" }}>Selecionado</p>
                                ) : item.tags2 === "Aprovado" ? (
                                    <p style={{ color: "green" }}>Aprovado</p>
                                ) : item.tags2 === "Reprovado" ? (
                                    <p style={{ color: "red" }}>Reprovado</p>
                                ) : null} />

                            <Column
                                header="Arquivos"
                                field="_seqPessoa"
                                alignHeader="center"
                                body={item => <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                                    <Button
                                        onClick={() => window.open('candidato_detalhe?id=' + item.seqPessoaRelacionada)}
                                        icon="pi pi-eye"
                                        className="p-button-rounded p-button-sucess"
                                        aria-label="Filter" />
                                </div>} />
                        </DataTable>

                    </>}

                {this.state.indexStep === 3 && //REPROVADOS
                    <>
                        <DataTable
                            style={{ marginTop: 20 }}
                            showGridlines
                            paginator
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            responsiveLayout='scroll'
                            removableSort
                            value={this.state.listaCandidatoReprovado}
                            // selection={this.state.selecionados}
                            // onSelectionChange={item => this.setState({ selecionados: item.value })}
                            header={''
                                // <Button label="aaaaaaa" onClick={() => this.selecionarCandidato()} />
                            }>

                            {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} /> */}
                            <Column
                                header="Candidato"
                                field="_pessoaRelacionadaNome"
                                sortable />

                            <Column
                                header="Email"
                                field="tags0"
                                sortable />

                            <Column
                                header="Telefone"
                                field="tags1"
                                sortable />

                            <Column
                                header="Relevância"
                                field="tags1"
                                body={<div style={{ textAlign: "center" }}>
                                    <span className="pi pi-thumbs-up"></span></div>}
                                sortable />

                            <Column
                                header="status"
                                body={item => item.tags2 === "Analise" ? (
                                    <p style={{ color: "gray" }}>Analise</p>
                                ) : item.tags2 === "Selecionado" ? (
                                    <p style={{ color: "blue" }}>Selecionado</p>
                                ) : item.tags2 === "Aprovado" ? (
                                    <p style={{ color: "green" }}>Aprovado</p>
                                ) : item.tags2 === "Reprovado" ? (
                                    <p style={{ color: "red" }}>Reprovado</p>
                                ) : null} />
                            <Column
                                header="Arquivos"
                                field="_seqPessoa"
                                alignHeader="center"
                                body={item => <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                                    <Button
                                        onClick={() => window.open('candidato_detalhe?id=' + item.seqPessoaRelacionada)}
                                        icon="pi pi-eye"
                                        className="p-button-rounded p-button-sucess"
                                        aria-label="Filter" />
                                </div>} />
                        </DataTable>

                        {/* <div style={{ margintop: 10, display: 'flex', justifyContent: 'left', width: '100%' }}>

                            <Button
                                onClick={() => this.setState({ indexStep: 2 })}
                                icon="pi pi-angle-double-left"
                                severity="success"
                                aria-label="Search" />

                        </div> */}
                    </>}
            </Dialog>


            {/* TELA */}
            <HeaderEmpresa />

            <main style={{ width: '95%', margin: '20px auto' }}>
                <Messages ref={this.mensagens} />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', padding: 10, borderRadius: 3 }}>
                    <div style={{ padding: 10 }}>
                        <h1 style={{ fontSize: 22 }}>Detalhe Projeto </h1>
                        <h2 style={{ fontSize: 20, color: 'gray' }}>Detalhe Projeto</h2>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10, }}>

                        <Button
                            loading={this.state.salvarCarregando}
                            label="Salvar"
                            className="p-button-raised"
                            onClick={() => this.salvar()}
                            disabled={this.state.documento.tags9} />

                        <div style={{ position: 'relative' }}>
                            <Button
                                // disabled={this.state.documento.tags9}
                                label="Opções de envio"
                                className="p-button-raised"
                                onClick={() => this.setState({ vModalOpcoesEnvio: !this.state.vModalOpcoesEnvio })} />

                            {this.state.vModalOpcoesEnvio &&
                                <div style={{ width: 200, position: 'absolute', padding: 5, backgroundColor: "white", boxShadow: "0 0 10px rgba(0,0,0,0.5)", zIndex: 999 }}>
                                    <Button
                                        disabled={this.state.documento.tags9}
                                        label="Enviar para receber propostas"
                                        style={{ textAlign: 'left' }}
                                        onClick={() => this.setState({
                                            vModalSalvar: true,
                                            vModalOpcoesEnvio: false,
                                        })}
                                        className="p-button-link" />

                                    <Button
                                        disabled={!this.state.documento.tags9}
                                        label="Fechar vagas"
                                        style={{ textAlign: 'left' }}
                                        onClick={() => this.setState({ vModalFecharVagas: true, vModalOpcoesEnvio: false, })}
                                        className="p-button-link"
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>

                {/* Projeto */}
                <div><br />
                    <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                        <Button style={{ width: 30, height: 30 }} type="button" icon="pi pi-book" className="p-button-rounded p-button-sucessul p-button-outlined" aria-label="Home" />
                        <h3>Projeto</h3>
                    </div>

                    <div style={{ display: "grid", gridTemplateColumns: "1fr", gap: 20, border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 20, borderRadius: 3 }}>

                            <div>
                                <label>Nome do Projeto</label><br />
                                <InputText
                                    onChange={pTexto => this.setState({
                                        documento: {
                                            ...this.state.documento, tags0: pTexto.target.value
                                        }
                                    })}
                                    disabled={this.state.documento.tags9}
                                    placeholder="nome do projeto"
                                    value={this.state.documento.tags0 || ''}
                                    style={{ width: '100%', height: 40, borderColor: this.state.inputDadosError.nomeProjeto && 'var(--red-600)' }} />
                                {this.state.inputDadosError.nomeProjeto && <small style={{ color: 'var(--red-600)' }}>Nome do projeto inválido</small>}
                            </div>

                            <div>
                                <label>Data Pagamento</label><br />
                                <Calendar
                                    onChange={pTexto => this.setState({
                                        documento: {
                                            ...this.state.documento, dataProgramada: this.util.formatarDataBanco(pTexto.target.value)
                                        }
                                    })}
                                    disabled={this.state.documento.tags9}
                                    value={this.util.formatarDataCalendar(this.state.documento.dataProgramada) || ''}
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    placeholder="dd/mm/yyyy"
                                    inputstyle={{ width: '100%', height: 40, borderColor: this.state.inputDadosError.dataProgramada && 'var(--red-600)' }}
                                    style={{ width: '100%', height: 40, borderColor: this.state.inputDadosError.dataProgramada && 'var(--red-600)' }} />
                                {this.state.inputDadosError.dataProgramada && <small style={{ color: 'var(--red-600)' }}>Data inválida</small>}

                            </div>

                            <div>
                                <label>Data Inicial</label><br />
                                <Calendar
                                    onChange={pTexto => this.setState({
                                        documento: {
                                            ...this.state.documento, dataInicio: this.util.formatarDataBanco(pTexto.target.value)
                                        }
                                    })}
                                    disabled={this.state.documento.tags9}
                                    value={this.util.formatarDataCalendar(this.state.documento.dataInicio) || ''}
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    placeholder="dd/mm/yyyy"
                                    inputstyle={{ width: '100%', height: 40, borderColor: this.state.inputDadosError.dataInicio && 'var(--red-600)' }}
                                    style={{ width: '100%', height: 40, borderColor: this.state.inputDadosError.dataInicio && 'var(--red-600)' }} />
                                {this.state.inputDadosError.dataInicio && <small style={{ color: 'var(--red-600)' }}>Data inválida</small>}

                            </div>

                            <div>
                                <label>Data Final</label><br />
                                <Calendar
                                    onChange={pTexto => this.setState({
                                        documento: {
                                            ...this.state.documento, dataFinal: this.util.formatarDataBanco(pTexto.target.value)
                                        }
                                    })}
                                    disabled={this.state.documento.tags9}
                                    value={this.util.formatarDataCalendar(this.state.documento.dataFinal) || ''}
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    placeholder="dd/mm/yyyy"
                                    inputstyle={{ width: '100%', height: 40, borderColor: this.state.inputDadosError.dataFinal && 'var(--red-600)' }}
                                    style={{ width: '100%', height: 40, borderColor: this.state.inputDadosError.dataFinal && 'var(--red-600)' }} />
                                {this.state.inputDadosError.dataFinal && <small style={{ color: 'var(--red-600)' }}>Data inválida</small>}

                            </div>

                        </div>

                        <div >
                            <label>Descrição</label><br />
                            <InputTextarea
                                onChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, observacao: pTexto.target.value
                                    }
                                })}
                                disabled={this.state.documento.tags9}
                                rows={5}
                                cols={50}
                                autoResize
                                placeholder='descrição do projeto'
                                value={this.state.documento.observacao || ''}
                                style={{ width: '100%', height: 40, borderColor: this.state.inputDadosError.observacao && 'var(--red-600)' }} />
                            {this.state.inputDadosError.observacao && <small style={{ color: 'var(--red-600)' }}>Descrição inválida</small>}

                        </div>
                    </div>
                </div>

                {/* LOCAL DE SERVIÇO */}
                <div><br />
                    <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                        <Button style={{ width: 30, height: 30 }} type="button" icon="pi pi-building" className="p-button-rounded p-button-sucessul p-button-outlined" aria-label="Home" />
                        <h3>Local de serviço</h3>
                    </div>

                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: 20, border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>
                        <div>
                            <label>CEP</label><br />
                            <div className="p-inputgroup">
                                <InputText
                                    type="tel"
                                    onChange={e => this.setState({
                                        documento: {
                                            ...this.state.documento,
                                            tags1: this.util.formatarCEP(e.target.value),
                                        }
                                    })}
                                    disabled={this.state.documento.tags9}
                                    value={this.state.documento?.tags1 || ''}
                                    style={{ width: '50%', height: 40, borderColor: this.state.inputDadosError.cepEmpresa && 'var(--red-600)' }} />
                                <Button
                                    disabled={this.state.documento.tags9}
                                    icon="pi pi-search"
                                    type="button"
                                    loading={this.state.salvarCarregando}
                                    onClick={() => this.buscarCep(this.state.documento.tags1)} />
                            </div>
                            {this.state.inputDadosError.cepEmpresa && <small style={{ color: 'var(--red-600)' }}>Cep inválido</small>}

                        </div>
                        <div>
                            <label>Logradouro</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, tags2: pTexto.target.value
                                    }
                                })}
                                disabled={this.state.documento.tags9}
                                value={this.state.documento?.tags2 || ''}
                                style={{ width: '100%', height: 40, borderColor: this.state.inputDadosError.logradouroEmpresa && 'var(--red-600)' }} />
                            {this.state.inputDadosError.logradouroEmpresa && <small style={{ color: 'var(--red-600)' }}>Logradouro inválido</small>}

                        </div>

                        <div>
                            <label>Número</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, tags3: pTexto.target.value
                                    }
                                })}
                                disabled={this.state.documento.tags9}
                                value={this.state.documento?.tags3 || ''}
                                style={{ width: '100%', height: 40, borderColor: this.state.inputDadosError.numeroEmpresa && 'var(--red-600)' }} />
                            {this.state.inputDadosError.numeroEmpresa && <small style={{ color: 'var(--red-600)' }}>Numero inválido</small>}

                        </div>
                        <div>
                            <label>Complemento</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, tags4: pTexto.target.value
                                    }
                                })}
                                disabled={this.state.documento.tags9}
                                value={this.state.documento?.tags4 || ''}
                                style={{ width: '100%', height: 40 }} />
                        </div>


                        <div>
                            <label>Bairro</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, tags5: pTexto.target.value
                                    }
                                })}
                                disabled={this.state.documento.tags9}
                                value={this.state.documento?.tags5 || ''}
                                style={{ width: '100%', height: 40, borderColor: this.state.inputDadosError.bairroEmpresa && 'var(--red-600)' }} />
                            {this.state.inputDadosError.bairroEmpresa && <small style={{ color: 'var(--red-600)' }}>Bairro inválido</small>}

                        </div>

                        <div>
                            <label>Cidade</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, tags6: pTexto.target.value
                                    }
                                })}
                                disabled={this.state.documento.tags9}
                                value={this.state.documento?.tags6 || ''}
                                style={{ width: '100%', height: 40, borderColor: this.state.inputDadosError.cidadaEmpresa && 'var(--red-600)' }} />
                            {this.state.inputDadosError.cidadaEmpresa && <small style={{ color: 'var(--red-600)' }}>Cidade inválido</small>}

                        </div>
                        <div>
                            <label>Estado</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, tags7: pTexto.target.value
                                    }
                                })}
                                disabled={this.state.documento.tags9}
                                value={this.state.documento?.tags7 || ''}
                                style={{ width: '100%', height: 40, borderColor: this.state.inputDadosError.estadoEmpresa && 'var(--red-600)' }} />
                            {this.state.inputDadosError.estadoEmpresa && <small style={{ color: 'var(--red-600)' }}>Estado inválido</small>}

                        </div>
                        <div>
                            <label>UF</label><br />
                            <InputText
                                onChange={pTexto => this.setState({
                                    documento: {
                                        ...this.state.documento, tags8: pTexto.target.value
                                    }
                                })}
                                disabled={this.state.documento.tags9}
                                value={this.state.documento?.tags8 || ''}
                                style={{ width: '100%', height: 40, borderColor: this.state.inputDadosError.ufEmpresa && 'var(--red-600)' }} />
                            {this.state.inputDadosError.ufEmpresa && <small style={{ color: 'var(--red-600)' }}>UF inválido</small>}

                        </div>
                    </div>
                </div>

                <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>
                    <TabView style={{ marginTop: 10 }}>

                        <TabPanel header="Serviços">
                            <h2 style={{ fontSize: 22.5 }}>Itens</h2>

                            <div style={{ marginTop: 5, display: "grid", gridTemplateColumns: "1fr ", gap: 20, alignItems: "center" }}>
                                <div>
                                    <Dropdown
                                        disabled={this.state.documento.tags9}
                                        onChange={e => this.setState({ item: e.value })}
                                        value={this.state.item}
                                        options={this.state.listaItem}
                                        optionLabel="_itemNome"
                                        placeholder='Selecione'
                                        inputstyle={{ width: '100%', height: 40, borderColor: this.state.inputDadosError.item && 'var(--red-600)' }}
                                        style={{ width: '100%', height: 40, borderColor: this.state.inputDadosError.item && 'var(--red-600)' }} />
                                    {this.state.inputDadosError.item && <small style={{ color: 'var(--red-600)' }}>Item não selecionado</small>}
                                </div>

                                <div style={{ display: "grid", alignItens: "center", gridTemplateColumns: "1fr 1fr 1fr 1fr ", gap: 5 }}>

                                    <div>
                                        <label>Quantidade</label>
                                        <InputText
                                            onChange={pTexto => this.setState({
                                                documentoItem: {
                                                    ...this.state.documentoItem, quantidade: pTexto.target.value
                                                }
                                            })}
                                            value={this.state.documentoItem.quantidade || ''}
                                            disabled={this.state.documento.tags9}
                                            placeholder='Quantidade'
                                            style={{ width: '100%', height: 40, borderColor: this.state.inputDadosError.quantidade && 'var(--red-600)' }} />
                                        {this.state.inputDadosError.quantidade && <small style={{ color: 'var(--red-600)' }}>Quantidade não selecionada</small>}
                                    </div>

                                    <div>
                                        <label>Preço unitário</label>
                                        <InputText
                                            onChange={pvalor => this.pegarValorTotalItem(pvalor.target.value)}
                                            disabled={this.state.documento.tags9}
                                            value={this.state.documentoItem.precoUnitario || ''}
                                            placeholder='Preço unitário R$'
                                            style={{ width: '100%', height: 40, borderColor: this.state.inputDadosError.preco && 'var(--red-600)' }} />
                                        {this.state.inputDadosError.preco && <small style={{ color: 'var(--red-600)' }}>Preço não selecionado</small>}
                                    </div>

                                    <div>
                                        <label>Limite de Propostas</label>
                                        <InputText
                                            onChange={pTexto => this.setState({
                                                documentoItem: {
                                                    ...this.state.documentoItem, tagn0: pTexto.target.value
                                                }
                                            })}
                                            value={this.state.documentoItem.tagn0 || ''}
                                            type="number"
                                            disabled={this.state.documento.tags9}
                                            placeholder='Limite de propostas'
                                            style={{ width: '100%', height: 40, borderColor: this.state.inputDadosError.limiteProposta && 'var(--red-600)' }} />
                                        {this.state.inputDadosError.limiteProposta && <small style={{ color: 'var(--red-600)' }}>Limite de proposta não selecionada</small>}

                                    </div>
                                    <div>
                                        <label>Distância</label>
                                        <InputText
                                            onChange={pTexto => this.setState({
                                                documento: {
                                                    ...this.state.documento, tagn0: pTexto.target.value
                                                }
                                            })}
                                            value={this.state.documento.tagn0 || ''}
                                            type="number"
                                            disabled={this.state.documento.tags9}
                                            placeholder='Distancia dos candidatos'
                                            style={{ width: '100%', height: 40 }} />
                                    </div>
                                </div>

                                <div style={{ display: "flex", justifyContent: "flex-end" }}>

                                    <Button
                                        disabled={this.state.documento.tags9}
                                        type="button"
                                        style={{ borderRadius: 5 }}
                                        loading={this.state.salvarCarregando}
                                        onClick={() => this.salvarItem()}
                                    >Adicionar </Button>
                                </div>

                            </div>

                            <DataTable
                                style={{ marginTop: 20 }}
                                showGridlines
                                removableSort
                                paginator
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={5} rowsPerPageOptions={[10, 20, 50]}
                                value={this.state.listaDocumentoItem}>

                                <Column
                                    header="Cnaes"
                                    field="_itemNome"
                                    sortable />

                                <Column
                                    header="Quantidade"
                                    field="quantidade"
                                    sortable />

                                <Column
                                    header="Preço Unitário "
                                    body={item => this.util.formatarValor(item.precoUnitario)}
                                    sortable />

                                <Column
                                    header="Preço Total"
                                    body={item => this.util.formatarValor(item.valorTotal)}
                                    sortable />

                                <Column
                                    header="Limite de propostas"
                                    body={item => item.tagn0}
                                    sortable />

                                <Column
                                    header="Opção"
                                    field="_seqDocumentoItem"
                                    alignHeader="center"
                                    body={item => <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                                        <Button
                                            onClick={() => this.setState({
                                                vModalDeletarItem: true,
                                                seqDocumentoItem: item._seqDocumentoItem
                                            })}
                                            disabled={this.state.documento.tags9}
                                            icon="pi pi-trash"
                                            className="p-button-rounded p-button-danger"
                                            aria-label="Filter" />
                                    </div>} />
                            </DataTable>
                            <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
                                <h2>Valor total</h2>
                                <h2>{this.util.formatarValor(this.state.documento.valorTotal)}</h2>
                            </div>
                        </TabPanel>


                        {/* <TabPanel
                            header="Fatura"
                            disabled={this.state.documento.tags9 ? false : true}
                        >
                            <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>
                                <h2 style={{ fontSize: 22.5 }}>Fatura</h2>
                                <DataTable
                                    // selectionMode="single"
                                    showGridlines removableSort
                                    paginator
                                    responsiveLayout="scroll"
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                    rows={10}
                                    rowsPerPageOptions={[10, 20, 50]}
                                    value={this.state.listaMovimentoFinanceiro}
                                // onSelectionChange={item => this.irParaCrossPag(item.value)}
                                >
                                    <Column field="_pesNome" header="Cliente" />
                                    <Column field="valorProgramado" header="Valor" body={item => `${this.util.formatarValor(item.valorProgramado)}`} />
                                    <Column field="dataVencimentoPagamento" header="Data de vencimento" body={item => `${this.util.formatarData(item.dataVencimentoPagamento)}`} />
                                    <Column field="_dataBaixa" header="Data baixa" body={item => `${this.util.formatarData(item._dataBaixa)}`} />
                                    <Column field="_fpNome" header="Forma de pagamento" />
                                    <Column field="qtdeBaixa" header="Status" body={item => item.qtdeBaixa > 0 ? 'Pago' : 'Aguardando pagamento'} />
                                    <Column field="qtdeBaixa" header="Visulizar" body={pMovimentoFinanceiro => <div>
                                        <Button
                                            disabled={pMovimentoFinanceiro.qtdeBaixa > 0}
                                            onClick={() => this.irParaImpressaoPg(pMovimentoFinanceiro)}
                                            icon="pi pi-eye"
                                            className="p-button-rounded p-button-help"
                                            aria-label="Filter" >

                                        </Button>
                                    </div>} />
                                </DataTable>
                            </div>
                        </TabPanel> */}

                        <TabPanel header="Propostas">

                            <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <h2 style={{ fontSize: 22.5 }}>Propostas</h2>

                                </div>
                                <DataTable
                                    value={this.state.listaDocumentoItem}>

                                    <Column
                                        header="Vagas"
                                        field="_itemNome"
                                        sortable />

                                    <Column
                                        header="Quantidade"
                                        field="quantidade"
                                        sortable />

                                    <Column
                                        header="Profisional aprovado"
                                        body={item => item.tagn3 ? item.tagn3 : 0}
                                        sortable />

                                    <Column
                                        header="Preço Unitário "
                                        body={item => this.util.formatarValor(item.precoUnitario)}
                                        sortable />

                                    <Column
                                        header="Preço Total"
                                        body={item => this.util.formatarValor(item.valorTotal)}
                                        sortable />

                                    <Column
                                        header="Visualizar profissionais"
                                        field="_seqDocumentoItem"
                                        alignHeader="center"
                                        body={item => <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                                            <Button
                                                onClick={() => { this.listarRelacionamentoDocumentoItem(item); this.setState({ vModalPropostas: true, documentoItem: item }) }}
                                                icon="pi pi-eye"
                                                className="p-button-rounded p-button-sucess"
                                                aria-label="Filter" />
                                        </div>} />

                                </DataTable>
                            </div>
                        </TabPanel>


                        {/* <TabPanel header="Relacionamento">
                            <div style={{ border: '1px solid lightgray', padding: 10, borderRadius: 3, marginTop: 20 }}>
                                <h2 style={{ fontSize: 22.5 }}>Relacionamento</h2>
                                <DataTable
                                    selectionMode="single"
                                    showGridlines removableSort
                                    paginator
                                    responsiveLayout="scroll"
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                    rows={10}
                                    rowsPerPageOptions={[10, 20, 50]}
                                    value={this.state.listaRelacionamentoDocumento}>
                                    <Column field="_pessoaRelacionadaNome" header="Nome" />
                                    <Column field="_tipoRelacionamentoNome" header="Tipo de relacionamento" />
                                    <Column field="dataCadastro" header="data de cadastro" body={item => <p>{this.util.formatarData(item.dataCadastro)}</p>} />
                                </DataTable>
                            </div>
                        </TabPanel> */}
                    </TabView>
                </div>
            </main >
        </div >
    }
}